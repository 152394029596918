import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';

export default function TrainingRecordAdd({training}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const [formTraining,setFormTraining] = useState(training);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState([]);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);

    const [fileList, setFileList] = useState([]);

    function addNewTraining(){
        dispatch({type:'create_new_tab',name:'Nové školenie',page:'TrainingRecordAdd',props:{training: null,type:'add'},mask:'skol005'})
      }
      function copyTraining(){
        dispatch({type:'create_new_tab',name:'Nové školenie',page:'TrainingRecordAdd',props:{training: formik.values,type:'copy'},mask:'skol005'})
      }

    const validate = values => {
        const errors = {};
        if(!values.training_id){
            errors.training_id = '*Povinné';
        }
        else{
            let my_employees = values.employees.map(employee => employee.id);
            for (var i = 0; i < my_employees.length; i++) {
                let card = state.training_cards.filter(emp => emp.id == my_employees[i]);
                if(card.length<1)errors.employees += 'Zamestnanec '+ state.employees.filter(emp => emp.id == my_employees[i])[0]?.name +' nemá založenú kartu!\n';
                else{                
                    let training = card[0].training_card.filter(card => card.employee_id == my_employees[i] && card.training_id==values.training_id);
                    if(training.length<1)errors.employees += 'Zamestnanec '+ state.employees.filter(emp => emp.id == my_employees[i])[0]?.name +' nemá evidované dané školenie!\n';
                }
            }
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: formTraining==null?{
            training_id: '',last_training_date: '',employees:[],description:'',}
            :formTraining,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value!="employees")formData.append(value, values[value] != null ? values[value] : "");
                else{
                    let my_employees = values.employees.map(employee => employee.id);
                    for (var i = 0; i < my_employees.length; i++) {
                        formData.append('employees[]', my_employees[i]);
                    }
                }
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            postRequest('/api/training_records', formData,true)
            .then((response) => {
                console.log(response);
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    //if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                    formik.resetForm();
                    setTrainingDepartment("");
                    setTrainings([]);
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
        },
    });

    function filterTrainings(departmentId){
        setTrainingDepartment(departmentId);
        setTrainings(state.training_types
        .filter(training_type => training_type.department.id === parseInt(departmentId)));
    }

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTraining()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTraining()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová škol. firma","TrainingRecordAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingRecordAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÉ ŠKOLENIE
                </h1>
                    <div>
                        <label
                            htmlFor="trainingDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie školenia
                        </label>
                        <select 
                        required
                        name='trainingDepartment'
                        onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </div>
                        <div>
                        <label
                            htmlFor="training_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov školenia
                        </label>
                        <select 
                        required
                        name="training_id"
                        onChange={formik.handleChange} value={formik.values.training_id} 
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber školenie</option>
                          {trainings.map((training) => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                            ))}
                        </select>
                        </div>
                      <div>
                        <label
                            htmlFor="last_training_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum školenia
                        </label>
                        <input
                            name='last_training_date'
                            type="date" required
                            onChange={formik.handleChange} value={formik.values.last_training_date}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="employees"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ZAMESTNANCI
                        </label>
                        <MultiSelect required value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                        options={state.employees.map((employee) => 
                            ({name:employee.name,id:employee.id})
                        )} 
                        optionLabel="name" 
                        filter placeholder="Vyber používateľov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formik.errors.employees ? <div className="whitespace-pre-line" style={{color:'red'}}>{formik.errors.employees}</div> : null}
                        </div>
                        <div>
                            <label className="block text-md my-2 font-semibold text-gray-800">POČET: {formik.values.employees.length}</label>
                        </div> 
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div> 
                        <div>
                        <label
                            htmlFor="files"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Protokol
                        </label>
                          <input
                          accept='image/*,application/pdf,.msg'
                          name='files'
                          ref={fileListRef}
                          onChange={handleFileChange}
                          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                          type='file'
                          multiple="multiple"
                          id='file'/>
                        </div>
                        <button type='submit' ref={submitRef}></button>
            </div>
            </div>
            </div> 
             </form>
            </div>
            </> 
            );

}