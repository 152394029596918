import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../api/ApiController';
import { confirmDialog } from 'primereact/confirmdialog';
import { SaveIcon, AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, StepForwardIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';

export default function EmployeeAdd({employee,type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,setVisible,setDialogText}= useContext(UtilContext);

    const [formEmployee,setFormEmployee] = useState(employee);
    const [formUser,setFormUser] = useState(employee);
    const [employeeType, setEmployeeType] = useState(type);
    const [employeeAccount, setEmployeeAccount] = useState((employee!=null && employee?.user!==null && employee?.user?.username!==null && state.employees.filter(employee => employee.id===employee?.id && employee?.id!==null)[0]?.user?.username!==null));
    const [accountEmail,setAccountEmail] = useState(employee?.email);

    const [insertCopyIcon,setInsertCopyIcon] = useState(!user.permissions.includes("add users"));
    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);
    const [closeTab,setCloseTab] = useState(false);

    const submitRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
        // Update the document title using the browser API
        if(employeeType=="edit"){
            setScrollIcons(false);
        }
    },[employeeType]);

    useEffect(() => {
        // Update the document title using the browser API
        //console.log(formEmployee);
        setEmployeeAccount((formEmployee?.user!==null && formEmployee?.user?.username!==null && state.employees.filter(employee => employee.id===formEmployee?.id && formEmployee?.id!==null)[0]?.user?.username!==null));
        if(!employeeAccount){
            let name = formEmployee?.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let split_name = name?.split(" ");
            if(split_name?.length > 1){
                userformik.setFieldValue("username",split_name[split_name.length-1][0]+split_name[split_name.length-2]);
            }
        }
    },[formEmployee]);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    /* validate form */
    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = '*Povinné';
        }
        if (!values.defined_id) {
            errors.defined_id = '*Povinné';
        }
        /*if (!values.email || values.email==="") {
            if(formEmployee?.user?.username && formEmployee?.user?.username?.length > 0){
                errors.email = '*Povinné - používateľ má pridelený účet';
            }
        }*/
        if (values.email && values.email!=="") {
            if(employeeType==="edit"){
                if(state.employees.filter(employee => employee.email === values.email && employee.id !== formEmployee.id).length > 0){
                    //errors.email = '*Email sa už používa';
                }
            }
            else{
                if(state.employees.filter(employee => employee.email === values.email).length > 0 && employeeType!=="edit"){
                    //errors.email = '*Email sa už používa';
                }
            }
            if (!isValidEmail(values.email)) {
                errors.email = '*Neplatný email';
            }
        }
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formEmployee==null?{
            id: '',name:'',description:'',defined_id:'',date_of_entry:'',date_of_departure:'',
            email:'',tel_number:'',state:'OK',department_id:''
        }:formEmployee,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            setDialogText("Ukladám...");
            setVisible(true);
            if(employeeType==="add"){
                postRequest('/api/employees', formData,true)
                .then((response) => {
                    getRequest("/api/employees").then((response) => {
                        dispatch({type:'load_employees',newEmployees:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setAccountEmail(response.data[response.data.length-1]?.email)
                        setEmployeeType("edit");
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
            else{
                postRequest(`/api/employees/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/employees").then((response) => {
                        dispatch({type:'load_employees',newEmployees:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        setAccountEmail(values?.email);
                        if(!employeeAccount){
                            let name = values?.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                            let split_name = name.split(" ");
                            if(split_name.length > 1){
                                userformik.setFieldValue("username",split_name[split_name.length-1][0]+split_name[split_name.length-2]);
                            }
                        }
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
        },
    });

    const userFormikValidate = values => {
        const errors = {};
        if(!accountEmail || accountEmail===""){
            errors.username = '*Zamestnanec nemá zadaný email';
        }
        if (values.username.length<5) {
            errors.username = '*Prihlasovacie meno je krátke';
        }
        if (!values.username) {
            errors.username = '*Povinné';
        }
        if (values.role=="") {
            errors.role = '*Povinné';
        }
        return errors;
    };

    function setUsernameForNotRegisterUser(){
        let name = formEmployee?.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        if(name){
            let split_name = name?.split(" ");
            if(split_name?.length > 1){
                console.log(split_name[split_name.length-1][0]+split_name[split_name.length-2]);
                return split_name[split_name.length-1][0]+split_name[split_name.length-2];
            }
        }
        return "";
    }

    const userformik = useFormik({
        initialValues: {
            username:formEmployee?.user?.username || setUsernameForNotRegisterUser(),role:formEmployee?.user?.role || ''
        },
        enableReinitialize:true,
        validate: userFormikValidate,
        onSubmit: values => {
            let formData2 = new FormData();
            for (let value in values) {
                formData2.append(value, values[value] != null ? values[value] : "");
            }
            formData2.append("employee_id",formEmployee.id);
            setDialogText("Ukladám...");
            setVisible(true);
            postRequest('/api/users', formData2,true)
            .then((response) => {
                employee.user = response.data;
                employee.user.role = response.data.roles[0]?.name;
                setFormEmployee(employee);
                getRequest("/api/employees").then((response) => {
                    dispatch({type:'load_employees',newEmployees:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    setEmployeeAccount((employee?.user!==null && employee?.user?.username!==null && state.employees.filter(employee => employee.id===employee?.id && employee?.id!==null)[0]?.user?.username!==null));
                    setVisible(false);
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Pozvánka bola odoslaná!"});
                });
            }).catch((reason) => {
                setVisible(false);
                alert("Daný úkon sa nepodaril!");
            })
            
        },
    });

    function addNewEmployee(){
        dispatch({type:'create_new_tab',name:'Nový zamestnanec',page:'EmployeeAdd',props:{employee: null,type:'add'},mask:'glo004'})
    }

    function copyEmployee(){
        dispatch({type:'create_new_tab',name:'Nový zamestnanec',page:'EmployeeAdd',props:{employee: formik.values,type:'copy'},mask:'glo004'})
    }

    function getIndex(employee_id) {
        return state.employees.findIndex(obj => obj.id === employee_id);
    }

    async function scrollToFirst(){
        let employee = state.employees[0];
        setFormEmployee(employee);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormEmployee(state.employees[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<=state.employees.length-1){
            setFormEmployee(state.employees[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.employees.length-1;
        let employee = state.employees[last];
        setFormEmployee(employee);
    }

    const acceptReset = () => {
        postRequest(`/api/users/reset_password/${formEmployee.user.id}`, null,true)
        .then((response) => {
            toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Heslo bolo resetované!" });
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
            toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Heslo sa nepodarilo resetovať!', life: 3000 });
        })
      }

      const acceptDelete = () => {
        deleteRequest(`/api/users/${formEmployee.user.id}`)
        .then((response) => {
            getRequest("/api/employees").then((response) => {
                setEmployeeAccount(0);
                dispatch({type:'load_employees',newEmployees:response.data});
                formEmployee.user = null;
                setFormEmployee(formEmployee);
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Účet bol úspešne vymazaný!" });
            });
        }).catch((reason) => {
            alert(reason);
            toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Účet sa nepodarilo vymazať!', life: 3000 });
        }) 
      }
    
      const rejectResetDelete = () => {
          toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }
    
    const confirmReset = () => {
        confirmDialog({
            message: 'Prajete si resetovať heslo tohto používateľa?',
            header: 'Potvrdenie resetovania',
            icon: 'pi pi-info-circle',
            rejectClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: rejectResetDelete,
            reject: acceptReset
        });
    };

    const confirmDelete = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento účet?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            rejectClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: rejectResetDelete,
            reject: acceptDelete
        });
    };

    return (
        <div>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewEmployee()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyEmployee()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="opacity-25"><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => openFavoriteDialog("Nový zamestnanec","EmployeeAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("EmployeeAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        
        <div className="relative flex flex-col justify-left overflow-x min-h-[calc(100vh-177px)]">
          <div className=" mb-2 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ZAMESTNANEC
                </h1>
                {employeeType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-1 mb-2">
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zamestnanca
                        </label>
                        <input
                            name='name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {formik.errors.name ? <div style={{color:'red'}}>{formik.errors.name}</div> : null}
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis zamestnanca
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {formik.errors.description ? <div style={{color:'red'}}>{formik.errors.description}</div> : null}
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="date_of_entry"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum príchodu
                        </label>
                        <input
                            name='date_of_entry'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.date_of_entry}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="date_of_departure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum odchodu
                        </label>
                        <input
                            name='date_of_departure'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.date_of_departure}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="defined_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Priradené ID
                        </label>
                        <input
                            name='defined_id'
                            type="number"
                            onChange={formik.handleChange} value={formik.values.defined_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {formik.errors.defined_id ? <div style={{color:'red'}}>{formik.errors.defined_id}</div> : null}
                        </div>
                        <div>
                        <label
                            htmlFor="department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select 
                        name='department_id'
                        onChange={formik.handleChange} value={formik.values.department_id}

                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                        </select>
                        {formik.errors.department_id ? <div style={{color:'red'}}>{formik.errors.department_id}</div> : null}
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-semibold text-gray-800"
                        >
                            E-mailová adresa
                        </label>
                        <input
                            name='email'
                            type="email"
                            onChange={formik.handleChange} value={formik.values.email}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {formik.errors.email ? <div style={{color:'red'}}>{formik.errors.email}</div> : null}
                        </div>
                        <div>
                        <label
                            htmlFor="tel_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Tel. číslo
                        </label>
                        <input
                            name='tel_number'
                            type="tel"
                            onChange={formik.handleChange} value={formik.values.tel_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {formik.errors.tel_number ? <div style={{color:'red'}}>{formik.errors.tel_number}</div> : null}
                        </div>
                    </div>
                </div>  
                <button type="submit" ref={submitRef} className='hidden'></button>
            </div>
            </form>  
            {employeeAccount ? ( <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-xl font-semibold text-left text-zinc-900">
                   Pridelený účet
                </h1>
                <div className="mt-6 md:grid grid-cols-2 gap-4 mb-2">
                    <div>
                      <label
                            htmlFor="username"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Pridelené používateľské meno:
                        </label>
                        <input
                            name='username'
                            type="text" readOnly={true}
                            value={formEmployee?.user?.username}
                            className="block bg-gray-50 w-full px-4 py-2 mt-2 mb-4 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <button className='w-full font-medium mb-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600' onClick={() => confirmReset()}>RESETOVANIE HESLA</button>
                        <button className='w-full font-medium mb-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-rose-900 rounded-md hover:bg-rose-700 focus:outline-none focus:bg-rose-600' onClick={() => confirmDelete() }>ZMAZAŤ ÚČET</button>
                        {/*<button className='w-full font-medium px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>RESETOVANIE HESLA</button>*/}                     
                    </div>
                    <div>
                      <label
                            htmlFor="role"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Pridelená rola:
                        </label>
                        <input
                            name='role'
                            type="text" readOnly={true}
                            value={formEmployee?.user?.role}
                            className="block bg-gray-50 w-full px-4 py-2 mt-2 mb-4 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                       </div>
                    <div>
                        
                    </div>   
                </div>  
            </div>):(<div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <form onSubmit={userformik.handleSubmit}>
                <h1 className="text-xl font-semibold text-left text-zinc-900">
                   Poslanie pozvánky
                </h1>
                <div className="mt-6 md:grid grid-cols-2 gap-4 mb-2">
                    <div>
                      <label
                            htmlFor="username"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Používateľské meno
                        </label>
                        <input
                            name='username'
                            type="text"
                            onChange={userformik.handleChange} value={userformik.values.username}
                            className="block w-full px-4 py-2 mt-2 mb-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {userformik.errors.username ? <div style={{color:'red'}}>{userformik.errors.username}</div> : null}
                      <button type="submit" className='w-full font-medium px-4 py-2 mb-4 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ODOSLAŤ POZVÁNKU</button>
                        </div>
                        <div>
                        <label
                            htmlFor="role"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Používateľská rola
                        </label>
                        <select 
                        name='role'
                        onChange={userformik.handleChange} value={userformik.values.role}
                        className='block w-full px-4 py-2 mt-2 mb-4 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber rolu</option>
                          {state.roles.map((role) => (
                            <option key={role.id} value={role.name}>{role.name}</option>
                          ))}
                        </select>
                        {userformik.errors.role ? <div style={{color:'red'}}>{userformik.errors.role}</div> : null}
                        </div>   
                </div>    
                </form>       
            </div>)}
            </div>
            <div>
            <input
                name='type'
                type="hidden"
                value={formik.values.type}
            />
            {/*<button type="submit" className='w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>*/}
            </div>
        </div>
        </div>
    );
}