import React,{ useContext, useEffect, useState,useRef } from "react";
import { useFormik } from 'formik';
import {authPostRequest} from './../../api/ApiController';
import AuthContext from '../../components/AuthContext';
import Background from '../../assets/img/eng_background.jpg';
import {ShowPasswordIcon,HidePasswordIcon} from './../svg/svgIcons';

export default function Login() {

    /* form and logged user values */
    const [user,setUser] = useState(null);
    const [submit,setSubmit] = useState(false);
    const {login,verify}= useContext(AuthContext);

    const [showLogin, setShowLogin] = useState(true);
    const [unverifyUser, setUnverifyUser] = useState(null);
    const [showVerifyOTP, setShowVerifyOTP] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);

    const [showPassword, setShowPassword] = useState("password");
    const [passwordStrengthStyle, setPasswordStrengthStyle] = useState("");

    const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
    const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
    const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
    const eightCharsOrMore= /.{8,}/g; // eight characters or more
    

    const [OTP, setOTP] = useState('');

    const usernameInput = useRef(null);

    useEffect(() => {
        if (usernameInput.current) {
            usernameInput.current.focus();
        }
    }, []);

    /* navigate after logged in */
    //const navigate = useNavigate();

    /* check submit form */
    useEffect(() => {

        if(submit){
            setSubmit(false);
            if(user){
                //console.log("USER",user);
                //navigate("/");
                verify(user);
            }
            else{
                alert("Nesprávne prihlasovacie údaje!");
            }
        }
    }, [user,verify,submit]);

    

    /* validate form */
    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = '*Povinné';
        }else if(!values.password){
            errors.password = '*Povinné';
        }
        return errors;
    };

    /* form for add customer */
    const formik = useFormik({
        initialValues: {
            username: '',password:''
        },
        validate,
        onSubmit: async (values) => {
            const logged_user = await login(values);
            if(logged_user.login_state==="otp"){
                setUnverifyUser(logged_user);
                setShowLogin(false);
                setShowVerifyOTP(true);
            }
            if(logged_user.login_state==="reset_password"){
                setUnverifyUser(logged_user);
                setShowLogin(false);
                setShowResetPassword(true);
            }
            else{
                setUser(logged_user);
                setSubmit(true);
            }
        }
    });

    const validateReset = values => {
        const errors = {};
        if (passwordStrength < 5) {
            errors.password = 'Heslo nie je dostatočne silné!';
        }
        if(values.password !== values.password_again){
            errors.password_again = 'Heslá sa nezhodujú!';
        }
        return errors;
    };

    const formikReset = useFormik({
        initialValues: {
            password: '',password_again:'',id: ''
        },
        validate: validateReset,
        onSubmit: (values) => {
            values.id = unverifyUser.id;
            authPostRequest('/api/auth/resetPassword', JSON.stringify(values))
            .then((response) => {
                console.log(response);
                if(response.status < 400){
                    //console.log("OTP VALID");
                    setUser(unverifyUser);
                    setSubmit(true);
                }
                else{
                    //console.log("OTP INVALID");
                    alert("Došlo k chybe");
                    //setUnverifyUser(null);
                    //setShowLogin(true);
                    //setShowVerifyOTP(false);
                }
            }).catch((reason) => {
                console.log(reason);
                alert("Došlo k chybe");
            })
        }
    });

    const passwordTracker = {
        uppercase: formikReset.values.password.match(atLeastOneUppercase),
        lowercase: formikReset.values.password.match(atLeastOneLowercase),
        number: formikReset.values.password.match(atLeastOneNumeric),
        specialChar: formikReset.values.password.match(atLeastOneSpecialChar),
        eightCharsOrGreater: formikReset.values.password.match(eightCharsOrMore),
    }

    const passwordStrength = Object.values(passwordTracker).filter(value => value).length;

    useEffect(() => {
        switch(passwordStrength){
            case 0: 
                setPasswordStrengthStyle("w-0");
                break;
            case 1: 
                setPasswordStrengthStyle("bg-red-500 w-1/5");
                break;
            case 2: 
                setPasswordStrengthStyle("bg-orange-500 w-2/5");
                break;
            case 3: 
                setPasswordStrengthStyle("bg-[#03a2cc] w-3/5");
                break;
            case 4: 
                setPasswordStrengthStyle("bg-[#03a2cc] w-4/5");
                break;
            case 5: 
                setPasswordStrengthStyle("bg-[#0ce052] w-full");
                break;
            default: 
                setPasswordStrengthStyle("w-0 h-1.5");
                break;
        }
    }, [passwordStrength]);

    

    const onChangeHandler = event => {
        setOTP(event.target.value);
     };

    const togglePassword =()=>{
        if(showPassword==="password")
        {
         setShowPassword("text")
         return;
        }
        setShowPassword("password")
    }

    const handleSave = event => {
        event.preventDefault();
        //values=formik.values;
        //console.log("VALUES",values)
        var new_values = { one_time_password: OTP, username: formik.values.username};
        console.log("VALUES",new_values)
        authPostRequest('/api/auth/2fa', JSON.stringify(new_values))
        .then((response) => {
            console.log(response);
            if(response.status < 400){
                //console.log("OTP VALID");
                setUser(unverifyUser);
                setSubmit(true);
            }
            else{
                //console.log("OTP INVALID");
                setUnverifyUser(null);
                setShowLogin(true);
                setShowVerifyOTP(false);
            }
        }).catch((reason) => {
            console.log(reason);
        })
    }
    
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-cover"
        style={{backgroundImage: "url(" + Background + ")", height:"400px"}}>
            <div 
            className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                {showLogin && <>
                <h1 className="text-3xl font-semibold text-center text-zinc-900 underline">
                   Prihlásenie
                </h1>
                <form onSubmit={formik.handleSubmit} className="mt-6">
                    <div className='mb-2'>
                        <label htmlFor="username" className="block text-sm font-semibold text-gray-800">Prihlasovacie meno</label>
                        <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="username" name="username" type="text" onChange={formik.handleChange} value={formik.values.username} ref={usernameInput}/>
                        {formik.errors.username ? <div style={{color:'red'}}>{formik.errors.username}</div> : null}
                    </div>
                    
                    <div className='mb-2'>
                        <label htmlFor="password" className="block text-sm font-semibold text-gray-800">Heslo</label>
                        <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="password" name="password" type="password" onChange={formik.handleChange} value={formik.values.password}/>
                        {formik.errors.password ? <div style={{color:'red'}}>{formik.errors.password}</div> : null}
                    </div>
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600">
                            Prihlásenie
                        </button>
                    </div>
                </form></>}
                {showVerifyOTP && <>
                <h1 className="text-3xl font-semibold text-center text-zinc-900 underline">
                   Overenie One-Time-Passport
                </h1>
                <form onSubmit={handleSave} className="mt-6">
                    <div className='mb-2'>
                        <label htmlFor="one_time_password" className="block text-sm font-semibold text-gray-800">OTP</label>
                        <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="one_time_password" name="one_time_password" type="number" onChange={onChangeHandler} value={OTP}/>
                    </div>
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600">
                            Overenie
                        </button>
                    </div>
                </form></>}
                {showResetPassword && <>
                <h1 className="text-3xl font-semibold text-center text-zinc-900 underline">
                   Zmena hesla
                </h1>
                <form onSubmit={formikReset.handleSubmit} className="mt-6">
                    <div className='mb-2'>
                        <label htmlFor="password" className="block text-sm font-semibold text-gray-800">Nové heslo:</label>
                        <div className="flex flex-row">
                        <input className="flex flex-col w-11/12 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-l-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" name="password" type={showPassword} value={formikReset.values.password} onChange={formikReset.handleChange} />
                        <button type="button" className="flex flex-col btn btn-outline-primary w-1/12 h-[42px] mt-2 align-middle items-center justify-center bg-white border rounded-r-md" onClick={togglePassword}>
                        { showPassword==="password"? <HidePasswordIcon/> : <ShowPasswordIcon/> }
                        </button>
                        </div>
                        <div className={`${passwordStrengthStyle} h-1.5 block rounded-md transition-[width] duration-200 content-none mt-2`}></div>
                        <div className="text-red-500">
                        {passwordStrength < 5 && '*Musí obsahovať: '}
                        {!passwordTracker.uppercase && 'veľké písmeno, '}
                        {!passwordTracker.lowercase && 'malé písmeno, '}
                        {!passwordTracker.specialChar && 'špeciálny znak, '}
                        {!passwordTracker.number && 'číslo, '}
                        {!passwordTracker.eightCharsOrGreater &&
                            '8 znakov a viac'}
                        </div>
                        <div className="text-[#0ce052]">{passwordStrength === 5 && 'Heslo spĺňa požiadavky'}</div>
                    </div>
                    <div className='mb-2'>
                        <label htmlFor="password_again" className="block text-sm font-semibold text-gray-800">Nové heslo znovu:</label>
                        <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" name="password_again" type="password" value={formikReset.values.password_again} onChange={formikReset.handleChange}/>
                        {formikReset.errors.password_again ? <div className="text-red-500 mt-1.5">{formikReset.errors.password_again}</div> : null}
                    </div>
                    <div className="mt-6">
                        <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600">
                            Zmeniť heslo!
                        </button>
                    </div>
                </form></>}
            </div>
        </div>
    );
}