import React, { useRef, useEffect, useState } from "react";
import classnames from "classnames";
import OverflowMenu from "./OverflowMenu";


export default function IntersectionObserverWrap({ children }) {
  const navRef = useRef(null);
  const overlayRef = useRef(null);
  const [visibilityMap, setVisibilityMap] = useState({});
  const handleIntersection = (entries) => {
    const updatedEntries = {};
    entries.forEach((entry) => {
      const targetid = entry.target.dataset.targetid;
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries
    }));
  };
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 1
    });

    // We are addting observers to child elements of the container div
    // with ref as navRef. Notice that we are adding observers
    // only if we have the data attribute observerid on the child elemeent
    Array.from(navRef.current.children).forEach((item) => {
      if (item.dataset.targetid) {
        observer.observe(item);
      }
    });
    return () => observer.disconnect();
  }, []);


  return (
    <div className="flex px-2 w-[90%]" ref={navRef}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          className: classnames(child.props.className, {
            ["order-none visible opacity-100"]: visibilityMap[child.props["data-targetid"]],
            ["order-[100] invisible pointer-events-none"]: !visibilityMap[child.props["data-targetid"]]
          }),
          onClick: child.onClick,
          ref: child.ref
        });
      })}
      <OverflowMenu
        visibilityMap={visibilityMap}
        className="order-[99] sticky -right-8 bg-[#333]">
        {children}
      </OverflowMenu>
    </div>
  );
}
