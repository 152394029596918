import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';

export default function CalibrationCompanyAdd({calibration_company, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const [trusteeDepartment, setTrusteeDepartment] = useState();
    const [trustees, setTrustees] = useState([]);
    const [userDepartment, setUserDepartment] = useState();
    const [users, setUsers] = useState([]);
    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [formCalibrationCompany,setformCalibrationCompany] = useState(calibration_company);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add calibration"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit calibration"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    


    const [fileList, setFileList] = useState([]);
    const fileListRef = useRef(null);

    function addNewCalibrationCompany(){
        dispatch({type:'create_new_tab',name:'Nová kalibračná firma',page:'CalibrationCompanyAdd',props:{calibration_company: null,type:'add'},mask:'kal007'})
      }
      function copyCalibrationCompany(){
        dispatch({type:'create_new_tab',name:'Nová kalibračná firma',page:'CalibrationCompanyAdd',props:{calibration_company: formik.values,type:'copy'},mask:'kal007'})
      }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formCalibrationCompany==null?{
            name: '',address: '',tel_number:'',fax:'',email:'',web_address:'',contact_person:'',description:'',}
            :formCalibrationCompany,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            if(formType=="add"){
                postRequest('/api/calibration_companies', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/calibration_companies").then((response) => {
                        dispatch({type:'load_calibration_companies',newCalibrationCompanies:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/calibration_companies/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/calibration_companies").then((response) => {
                        dispatch({type:'load_calibration_companies',newCalibrationCompanies:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(calibration_companies_id) {
        return state.calibration_companies.findIndex(obj => obj.id === calibration_companies_id);
    }

    async function scrollToFirst(){
        let my_calibration_company = state.calibration_companies[0];
        setformCalibrationCompany(my_calibration_company);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setformCalibrationCompany(state.calibration_companies[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.calibration_companies.length){
            setformCalibrationCompany(state.calibration_companies[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.calibration_companies.length-1;
        let my_calibration_company = state.calibration_companies[last];
        setformCalibrationCompany(my_calibration_company);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
          alert("Vyberte skupinu!");
          return;
        }
        else if(favoriteGroupValue=="nova"){
          if(favoriteValue==""){
            alert("Napíšte názov novez skupiny!");
            return;
          }
          else{
            //VYTVORIT SKUPINU AJ FAVORITA
            setFavoriteVisible(false);
            setVisibleLoading(true);
            let formData = new FormData();
            formData.append("name", favoriteValue);
            postRequest(`/api/favorite_groups`, formData,true)
            .then((response) => {
              let formData2 = new FormData();
              formData2.append("name", "Nová kalibrácia meradla");
              formData2.append("page", "GaugeCalibrationAdd");
              formData2.append("favorite_group_id", response.data.id);
              postRequest(`/api/favorites`, formData2,true)
              .then((response) => {
                  getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisibleLoading(false);
                });
              }).catch((reason) => {
                  setVisibleLoading(false);
              })
            }).catch((reason) => {
                setVisibleLoading(false);
            })
          }
        }
        else{
          //VYTVORIT FAVORITA a pridat do skupiny
          setFavoriteVisible(false);
          setVisibleLoading(true);
          let formData2 = new FormData();
          formData2.append("name", "Nová kalibrácia meradla");
          formData2.append("page", "GaugeCalibrationAdd");
          formData2.append("favorite_group_id", favoriteGroupValue);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
          })
        }
    }

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewCalibrationCompany()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyCalibrationCompany()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   KARTA KALIBRAČNEJ FIRMY
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV firmy
                        </label>
                        <input
                            name='name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="tel_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TELEFÓNNE ČÍSLO
                        </label>
                        <input
                            name='tel_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.tel_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-semibold text-gray-800"
                        >
                            E-MAIL
                        </label>
                        <input
                            name='email'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.email}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="contact_person"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            KONTAKTNÁ OSOBA
                        </label>
                        <input
                            name='contact_person'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.contact_person}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                    <div>
                        <label
                            htmlFor="address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ADRESA
                        </label>
                        <input
                            name='address'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="fax"
                            className="block text-md font-semibold text-gray-800"
                        >
                             FAX
                        </label>
                        <input
                            name='fax'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.fax}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="web_address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            WEB. ADRESA
                        </label>
                        <input
                            name='web_address'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.web_address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>  
                        <button className='hidden' type='submit' ref={submitRef}></button>    
            </div>
            </div>
            </div> 
             </form>
            <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
            </div>
            </> 
            );
} 
