import React, { useState,useContext,useEffect,useRef } from "react";
import './../assets/css/resizablediv.css'
import DockLayout from 'rc-dock'
import "rc-dock/dist/rc-dock.css";
import './../assets/css/dockpanel.css';
import { DatabaseContext } from "../redux/DatabaseStore";
import { ContextMenu } from 'primereact/contextmenu';
import FavoriteMenu from "./recursive_menu/FavoriteMenu";
import { Dialog } from 'primereact/dialog';
import { deleteRequest, getRequest, postRequest } from "../api/ApiController";
import { InfinitySpin } from  'react-loader-spinner';
import LastUsedMenu from "./recursive_menu/LastUsedMenu";
import { components } from "../components/TabPanelList";
import UtilContext from "../components/UtilContext";
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon} from "./svg/svgIcons";


const FavoriteContext = React.createContext();
const LastUsedContext = React.createContext();
const FastViewContext = React.createContext();


function HomePage() {

  const [state,dispatch] = useContext(DatabaseContext);
  const {openFavoriteDialog,setFastView} = useContext(UtilContext);
  const [favorite,setFavorite] = useState(null);
  const [visibleSearch,setVisibleSearch] = useState(false);
  const [visibleLoading,setVisibleLoading] = useState(false);
  const [newValue,setNewValue] = useState('');
  const [dialogHeader,setDialogHeader] = useState('');
  const [operation,setOperation] = useState('');

  const [fastViewTitle,setFastViewTitle] = useState('Rýchly náhľad');


  const divFav = useRef(null);
  const groupFav = useRef(null);
  const fav = useRef(null);
  const lastUsedFav = useRef(null);

  function hideAll(){
    props.divFav.current.hide();
    props.fav.current.hide();
    props.groupFav.current.hide();
    props.lastUsedFav.current.hide();
  }


  const props = {divFav,groupFav,fav,lastUsedFav,hideAll,setFavorite};

  const divItems = [
      { label: 'Pridať skupinu', 
        icon: 'pi pi-fw pi-clone',
        command: () => {
          console.log("PRIDAT SKUPINU");
          setNewValue('');
          setDialogHeader("Vytvoriť skupinu:");
          setOperation("create_group");
          setVisibleSearch(true);
    } }
  ];

  const lastUsedItems = [
    { label: 'Pridať do favoritov', 
      icon: <FavoriteIcon/>,
      command: () => {
        openFavoriteDialog(favorite?.name,favorite?.page);
      } 
    },
    { label: 'Pridať do rých.náhľadu', 
      icon: <PriceIcon/>,
      command: () => {
        setFastView(favorite?.page,null,null);
      } 
    }
];

  const groupItems = [
    { label: 'Premenovať', 
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        console.log("PREMENOVAŤ SKUPINU");
        setNewValue(favorite.name);
        setDialogHeader("Premenovať skupinu:");
        setOperation("rename_group");
        setVisibleSearch(true);  
      } 
    },
    { label: 'Vymazať', 
      icon: 'pi pi-fw pi-trash',
      command: () => {
        console.log("VYMAZAŤ SKUPINU");
        setNewValue('');
        setDialogHeader("Naozaj chcete vymazať celú skupinu?");
        setOperation("delete_group");
        setVisibleSearch(true); 
      }
    }
  ];

  const favItems = [
    { label: 'Premenovať', 
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        console.log("PREMENOVAŤ FAVORITA"); 
        setNewValue(favorite.name);
        setDialogHeader("Premenovať favorita:");
        setOperation("rename_favorite");
        setVisibleSearch(true);   
      } 
    },
    { label: 'Vymazať', 
      icon: 'pi pi-fw pi-trash',
      command: () => {
        console.log("VYMAZAŤ FAVORITA");
        setNewValue(null);
        setDialogHeader("Naozaj chcete vymazať favorita?");
        setOperation("delete_favorite");
        setVisibleSearch(true); 
      }
    },
    { label: 'Zmeniť skupinu', 
      icon: 'pi pi-fw pi-sort',
      command: () => {
        console.log("ZMENIŤ SKUPINU");
        setNewValue(favorite.favorite_group_id);
        setDialogHeader("Zmeniť skupinu na:");
        setOperation("change_group");
        setVisibleSearch(true); 
      }
    },
    { label: 'Pridať do rýchleho náhľadu', 
      icon: 'pi pi-fw pi-star',
      command: () => {
        setFastView(favorite?.page,null,null);
      } 
    }
  ];

  const favorites = {
    content: <div className="h-full" onContextMenu={(e) => {props.hideAll();divFav.current.show(e);}}>
              <ul className="space-y-1 text-sm" style={{backgroundColor:'#333'}}>
              <FavoriteContext.Consumer>
                {(value) => {
                /*value.map(function(favorite, i){
                  console.log("FAVORIT")
                                      return <li key={i} onContextMenu={(e) => {groupFav.current.show(e);divFav.current.hide();}}> 
                                      <button onClick={() => dispatch({type:'create_new_tab',name:favorite.title,page:favorite.page})}
                                          className="flex items-center w-full p-2 text-white transition duration-75 group hover:bg-gray-700 pl-11">{favorite.title}</button>
                                  </li>;
                })*/
                if(value!=null){
                  return <FavoriteMenu items={value} depth={1} props={props}/>
                }
              }}
              </FavoriteContext.Consumer>
              </ul>
            </div>,
    closable: false,
  };

  const last_used = {
    content: <div className="h-full">
              <ul className="space-y-1 text-sm" style={{backgroundColor:'#333'}}>
              <LastUsedContext.Consumer>
                {(value) => (
                  <LastUsedMenu items={value} props={props}/>
                )}
              </LastUsedContext.Consumer>
              </ul>
            </div>,
    closable: false,
  };

  const fast_view = {
    content: <div className="h-full">
              <ul className="space-y-1 text-sm" style={{backgroundColor:'#333'}}>
              <FastViewContext.Consumer>
              {(value) => {
                if(value.length){
                  const Page = components[value[0].page];
                  setFastViewTitle(value[0].page);
                  //const props = [...JSON.parse(panel.columns),...JSON.parse(panel.attributes)];
                  return <Page columns_visible={JSON.parse(value[0].columns)}/>;
                }
                return null;
                
            }}
              </FastViewContext.Consumer>
              </ul>
            </div>,
    closable: false,
  };
  
  const box = {
    dockbox: {
      mode: 'vertical',
      children: [
        {
          mode: 'horizontal',
          children: [
            {
              size: 100,
              tabs: [{...favorites, id: 't1', title: 'Favoriti'}],
            },
            {
              size: 100,
              tabs: [{...last_used, id: 't2', title: 'Posledné použité'}],
            },
            {
              size: 300,
              tabs: [{...fast_view, id: 't3', title: fastViewTitle}],
            },
          ]
        },
        /*{
          size:10,
          tabs: [{closable: false, id: 't4', title: 'Rýchly náhľad'}],
        }*/
      ]
    }
  };

  function confirmMyDialog(){
    setVisibleSearch(false);
    setVisibleLoading(true);
    if(operation=="rename_favorite" || operation=="rename_group"){
      console.log("Favorite",favorite);
      let db_favorite = {...favorite};
      db_favorite.name = newValue;
      let formData = new FormData();
      for (let value in db_favorite) {
          formData.append(value, db_favorite[value]);
      }
      let url = operation=="rename_favorite" ? `/api/favorites/update/${db_favorite.id}`: `/api/favorite_groups/update/${db_favorite.id}`;
      postRequest(url, formData,true)
      .then((response) => {
          getRequest("/api/my_favorite_groups").then((response) => {
            dispatch({type:'load_favorites',newFavorites:response.data});
            setVisibleLoading(false);
        });
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
    if(operation=="delete_favorite" || operation=="delete_group"){
      console.log("Favorite",favorite);
      
      let db_favorite = {...favorite};

      let url = operation=="delete_favorite" ? `/api/favorites/${db_favorite.id}`: `/api/favorite_groups/${db_favorite.id}`;
      deleteRequest(url)
      .then((response) => {
          getRequest("/api/my_favorite_groups").then((response) => {
            dispatch({type:'load_favorites',newFavorites:response.data});
            setVisibleLoading(false);
        });
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
    else if(operation=="change_group"){
      console.log("Favorite",favorite);
      let db_favorite = {...favorite};
      db_favorite.favorite_group_id = newValue;
      let formData = new FormData();
      for (let value in db_favorite) {
          formData.append(value, db_favorite[value]);
      }
      postRequest(`/api/favorites/update/${db_favorite.id}`, formData,true)
      .then((response) => {
          getRequest("/api/my_favorite_groups").then((response) => {
            dispatch({type:'load_favorites',newFavorites:response.data});
            setVisibleLoading(false);
        });
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
    else if(operation=="create_group"){
      let formData = new FormData();
      formData.append("name", newValue);
      postRequest(`/api/favorite_groups`, formData,true)
      .then((response) => {
          getRequest("/api/my_favorite_groups").then((response) => {
            dispatch({type:'load_favorites',newFavorites:response.data});
            setVisibleLoading(false);
        });
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
  }


return (
  <div className="h-[calc(100vh_-_137px)] relative">
    <FavoriteContext.Provider value={state.favorites}>
    <LastUsedContext.Provider value={state.last_used}>
    <FastViewContext.Provider value={state.fast_view}>
    <ContextMenu model={divItems} ref={divFav} breakpoint="767px" className="bg-[#333]"/>
    <ContextMenu model={groupItems} ref={groupFav} breakpoint="767px" className="bg-[#333]"/>
    <ContextMenu model={favItems} ref={fav} breakpoint="767px" className="bg-[#333]" />
    <ContextMenu model={lastUsedItems} ref={lastUsedFav} breakpoint="767px" className="bg-[#333]" />
      <Dialog header={dialogHeader} visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
        <div className="flex flex-columns">
          
          {(operation=="rename_group" || operation=="rename_favorite" || operation=="create_group") && <input
              value={newValue} onChange={e => setNewValue(e.target.value)}
              type="text"
              className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
          />}
          {operation=="change_group" && 
          <select 
          value={newValue} onChange={e => setNewValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            {state.favorites.map((favorite_group) => (
              <option key={favorite_group.id} value={favorite_group.id}>{favorite_group.name}</option>
            ))}                          
          </select>
          }
          <button onClick={() => confirmMyDialog()} className='w-20 ml-8 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>{(operation=="delete_group" || operation=="delete_favorite") ? "ÁNO":"ZMEŇ"}</button>
        </div>
      </Dialog>
      <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
      <DockLayout
      defaultLayout={box}
      style={{ position: "absolute",top:10,left:10,bottom:10,right:10
      }}
    />
    </FastViewContext.Provider>
    </LastUsedContext.Provider>
    </FavoriteContext.Provider>
            {/*<div className="w-auto h-screen m-4 bg-white border-black border-2 rounded-md shadow-md resize">
                <h1 className="bg-zinc-900 p-2 text-xl font-semibold text-left text-white" >
                   Favoriti
                </h1>
            </div> 
            <div className="w-auto h-screen m-4 bg-white border-black border-2 rounded-md shadow-md resize">
                <h1 className="bg-zinc-900 p-2 text-xl font-semibold text-left text-white">
                  Posledné použíté
                </h1>
            </div>
                          
            
            <div className="w-auto h-screen m-4 bg-white border-black border-2 rounded-md shadow-md resize">
                <h1 className="bg-zinc-900 p-2 text-xl font-semibold text-left text-white">
                Rýchly prístup
                </h1>
</div>*/}    
</div>
/*<ResizableDiv>
        <div className='bg-zinc-900'>
          <h3 className='bg-zinc-800 text-gray-100 text-center font-bold text-xl py-3'>Favoriti</h3>
          <Menu items={menuItems} depth={1}/>
        </div>
      </ResizableDiv>
      <ResizableDiv>
        <div>
          <h3 className='bg-zinc-800 text-gray-100 text-center font-bold text-xl py-3'>Posledné použité</h3>
        </div>
      </ResizableDiv>
      <ResizableDiv>
        <div>
          <h3 className='bg-zinc-800 text-gray-100 text-center font-bold text-xl py-3'>Rýchly prístup</h3>
        </div>
</ResizableDiv>*/
    )
}

export default HomePage;