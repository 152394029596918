import React, { useContext } from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import UtilContext from '../../components/UtilContext';

function LastUsedMenu({ items,props}) {

  const [state,dispatch] = useContext(DatabaseContext);
  const {setFastView} = useContext(UtilContext);

  return (
    <ul className="pt-2 pb-4 space-y-1 text-sm bg-white">
      {items.map((item) => (
          <li key={item.name}>
              <button onDoubleClick={() => dispatch({type:'create_new_tab',name:item.name,page:item.page})} onContextMenu={(e) => {props.setFavorite(item);props.hideAll();props.lastUsedFav.current.show(e);}}
                  className="flex items-center w-full p-2  text-black transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-black dark:hover:bg-gray-100 pl-11">{item.name}</button>
          </li>
      ))}
    </ul>
  );
}
export default LastUsedMenu;