import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';

export default function TrainingCardAdd({training}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const ref = useRef(null);
    const [formType,setFormType] = useState("add");

    const [trainingsMarked, setTrainingsMarked] = useState({});
    const [markUnmark, setMarkUnmarked] = useState(true);

    const [formTraining,setFormTraining] = useState(training);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState([]);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);

    const [fileList, setFileList] = useState([]);

    function addNewTraining(){
        dispatch({type:'create_new_tab',name:'Nové školenie',page:'TrainingCardAdd',props:{training: null,type:'add'},mask:'skol003'})
    }
    function copyTraining(){
        dispatch({type:'create_new_tab',name:'Nové školenie',page:'TrainingCardAdd',props:{training: formik.values,type:'copy'},mask:'skol003'})
    }

    function changeTemplate(template_id){
        const trainingMarked = {};
        setTrainingDepartment(state.training_templates.filter((template) => template.id==template_id)[0]?.template_records.map((item) => {
            trainingMarked[item.training_id]=true;
        }));
        setTrainingsMarked(trainingMarked);
        console.log(trainingMarked);
        formik.setFieldValue("training_template_id",template_id);
    }

    function changeMarkings(value){
        const trainingMarked = {...trainingsMarked};
        for(let val in trainingsMarked){
            trainingMarked[val]=value;
        };
        setTrainingsMarked(trainingMarked);
    }

    function setMask(item,value){
        const trainingMarked = {...trainingsMarked};
        trainingMarked[item] = value;
        setTrainingsMarked(trainingMarked);
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formTraining==null?{
            deparment_id: '',initial_date: '',employee_id:'',trainings:[],}
            :formTraining,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            let trainings = Object.keys(trainingsMarked)
            .filter(function(k){return trainingsMarked[k]})
            for (var i = 0; i < trainings.length; i++) {
                formData.append('trainings[]', trainings[i]);
            }
            //console.log(trainingsMarked);
            for (let value in values) {
                if(value!="trainings")formData.append(value, values[value] != null ? values[value] : "");
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/
           if(formType=="add"){
                postRequest('/api/training_cards/card', formData,true)
                .then((response) => {
                    setFormType("edit");
                    getRequest("/api/training_cards").then((response) => {
                        dispatch({type:'load_training_cards',newTrainingCards:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                    getRequest("/api/training_employees").then((response) => {
                        dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
           }
        },
    });

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTraining()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTraining()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová škol. firma","TrainingRecordAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingRecordAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÁ KARTA ZAMESTNANCA
                </h1>
                <div>
                        <label
                            htmlFor="employee_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zamestnanca
                        </label>
                        <select 
                        required
                        name='employee_id'
                        onChange={formik.handleChange} value={formik.values.employee_id} 
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber zamestnanca</option>
                          {state.employees.filter(a => !state.training_cards.map((b) => b.id).includes(a.id)).map((employee) => (
                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="training_template_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Šablóna pre oddelenie 
                        </label>
                        <select 
                        name="training_template_id"
                        onChange={(e) => changeTemplate(e.target.value)} value={formik.values.training_template_id} 
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Bez šablóny</option>
                          {state.training_templates.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </div>
                      {(formik.values.training_template_id && formik.values.training_template_id!="") && <div>
                        <label
                            htmlFor="initial_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum prvotného školenia
                        </label>
                        <input
                            name='initial_date'
                            type="date" required
                            onChange={formik.handleChange} value={formik.values.initial_date}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                     </div>}
                        <button type='submit' ref={submitRef}></button>
            </div>
            {(formik.values.training_template_id && formik.values.training_template_id !== "" && formik.values.training_template_id !== null) &&  <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <div className='flex justify-between'>
                    <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   {state.training_templates.filter((template) => template.id == formik.values.training_template_id)[0]?.name}
                </h1>
                <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
                </div>
                <div className="flex flex-col h-[600px] overflow-y-auto">
                    {state.training_templates.filter((template) => template.id == formik.values.training_template_id)[0].template_records.sort((a,b) => a.training.department.defined_id < b.training.department.defined_id ? -1 : 1).map(function(item, index){ 
                        return <div key={index} className="flex flex-row border border-black rounded-md p-2 m-1"> 
                            <Checkbox onChange={(e) => setMask(item.training_id,e.checked)} checked={trainingsMarked[item.training_id]}></Checkbox><span className="ml-2">{item.training.department.name + " - " + item.training.name}</span>
                        </div> ;
                    })} 
                </div>
                <button type='submit' ref={submitRef}></button>
            </div>}
            </div>
            </div>
             </form>
            </div>
            </> 
            );

}