import { Fragment ,useContext, useState} from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import AuthContext from "./AuthContext";
import { DatabaseContext } from '../redux/DatabaseStore';
import { Badge } from 'primereact/badge';
import UtilContext from './UtilContext';
import { Avatar } from 'primereact/avatar';
import { CustomerServiceIcon } from '../pages/svg/svgIcons';

const navigation = [
  /*{ name: 'Domov', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },*/
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
  const { user, logout } = useContext(AuthContext);
  const [state,dispatch] = useContext(DatabaseContext);
  const {setVisibleNotifications,getUserColor,getUserInitials} = useContext(UtilContext);

  return (
<Disclosure as="nav" className="bg-[#e9ecef]">
      {({ open }) => (
        <>
          <div className="max-w-full px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-12 w-auto"
                    src={require('../assets/img/logo-legro.png')}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-blue-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                  type="button"
                  className="rounded-full p-1 pt-3 mr-2 text-gray-400 hover:text-[#555]"
                  onClick={() => dispatch({type:'create_new_tab',name:'Podpora',page:'SupportTicketAdd',mask: 'sup001'})}
                >
                  <CustomerServiceIcon/>
                  <span className="sr-only">View tickets</span>
                  {/*<BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                </button>
                <button
                  type="button"
                  className="rounded-full p-1 pt-3 text-gray-400 hover:text-[#555]"
                  onClick={() => setVisibleNotifications(true)}
                >
                  <i title='Notifikácie' className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.4rem' }}>
                    {state.notifications?.length>0 && <Badge value={state.notifications.length}></Badge>}
                  </i>
                  <span className="sr-only">View notifications</span>
                  {/*<BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full  text-sm focus:outline-none">
                      <span className="sr-only">Open user menu</span>
                      <Avatar className='p-overlay-badge' title={user?.employee?.name || user?.username} label={getUserInitials(user?.employee?.name || user?.username)} shape="circle" style={{ backgroundColor: getUserColor(user.username), color: '#ffffff',marginRight: '5px'}}>
                      {state.support_tickets?.filter((ticket) => ticket.status!=="Vyriešený")?.length>0 && <Badge value={state.support_tickets.filter((ticket) => ticket.status!=="Vyriešený").length}></Badge>}
                      </Avatar>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-[101] mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                        <h2 className='block font-semibold px-4 py-2 text-m text-gray-700'>{user?.employee?.name || user?.username}</h2>
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                          onClick={() => dispatch({type:'create_new_tab',name:'Zoznam žiadostí',page:'SupportTicketList',mask: 'sup002'})}
                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full px-4 py-2 text-sm text-gray-700')}
                          ><div className='flex flex-row justify-between'>
                            <span className='text-left'>Otázky</span>
                          <div className='justify-end'>{state.support_tickets?.filter((ticket) => ticket.status!=="Vyriešený")?.length>0 && <Avatar label={state.support_tickets?.filter((ticket) => ticket.status!=="Vyriešený")?.length} style={{ backgroundColor: '#2196f3', color: '#ffffff',fontSize:'0.75rem',height:'1.25rem', width:'1.25rem',marginRight:'7px'}} shape="circle" />}</div>
                          </div>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                          onClick={() => dispatch({type:'change_tab',name:'2'})}
                            className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                          >
                            Môj účet
                          </button>
                        )}
                      </Menu.Item>
                      <hr></hr>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {dispatch({type:'set_initial_state'});logout();}}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left')}
                          >
                            Odhlásiť sa
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}