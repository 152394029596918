import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import '../../assets/css/priorities.css';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';

export default function PriorityAdd({priority, type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView}= useContext(UtilContext);

    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);

    const [formPriority,setFormPriority] = useState(priority);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState(!user.permissions.includes("edit priorities"));
    const [visibleLoading,setVisibleLoading] = useState(false);

    const [visibleDuplicate, setVisibleDuplicate] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    function addNewPriority(){
        dispatch({type:'create_new_tab',name:'Nová priorita',page:'PriorityAdd',props:{priority: null,type:'add',mask:'prio003'}})
      }

    function copyPriority(){
        dispatch({type:'create_new_tab',name:'Nová priorita',page:'PriorityAdd',props:{priority: formik.values,type:'add',mask:'prio003'}})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const acceptDuplicate = () => {
        saveValues();
    }

    const rejectDuplicate = () => {
        let mytoast = toast.current ? toast : ownToast; 
        mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }


    function saveValues(){
        if(formType=="add"){
            if(formik.values.delivery_date=="" && formik.values.article_number==""){
                formik.values["assignment_type"] = "manually_partially";
            }
            else{
                formik.values["assignment_type"] = "manually";
            }
        }
        let formData = new FormData();
        for (let value in formik.values) {
            formData.append(value, formik.values[value]);
        }
        console.log(formType)
        if(formType=="add"){
            postRequest('/api/priorities', formData,true)
            .then((response) => {
                console.log(response);
                if(response.status == 201){
                    if(response.data.length>0)formik.setValues(response.element);
                    dispatch({type:'load_priorities',newPriorities:response.data});
                    setFormType("edit");
                    let mytoast = toast.current ? toast : ownToast; 
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    dispatch({type:'priorities_reload',value:true});
                }
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
        }
        else{
            postRequest(`/api/priorities/update/${formik.values.id}`, formData,true)
            .then((response) => {
                if(response.status == 200){
                    dispatch({type:'load_priorities',newPriorities:response.data});
                    dispatch({type:'priorities_reload',value:true});
                    let mytoast = toast.current ? toast : ownToast; 
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                }
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
        }
    }

    const formik = useFormik({
        initialValues: formPriority==null?{
            priority_department_id: '',production_order_number:'',sales_order_number:'',article_number:'',delivery_date:'',
            description_1:'',description_2:'',drawing_name:'',drawing_url:'',total_amount:'',assignment_type:'manually'
        }:formPriority,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            var same_priorities = null;
            if(formType=="add"){
                same_priorities = state.priorities.filter((priority) => 
                ((priority.production_order_number && priority.production_order_number == formik.values.production_order_number) && (priority.sales_order_number && priority.sales_order_number == formik.values.sales_order_number)) && priority.priority_department_id == formik.values.priority_department_id).length;
                
            }
            else{
                same_priorities = state.priorities.filter((priority) => 
                ((priority.production_order_number && priority.production_order_number == formik.values.production_order_number) && (priority.sales_order_number && priority.sales_order_number == formik.values.sales_order_number)) && priority.priority_department_id == formik.values.priority_department_id)
                .filter(priority => priority.id != formik.values.id).length;
            }
            if(same_priorities){
                //confirm();
                console.log("SAME PRIORITIES");
                setVisibleDuplicate(true);
            }
            else{
                saveValues();
            }
        },
    });

    function searchForPriority(production_order_number){
        if(production_order_number!=""){
            setDialogText("Hľadám");
            setVisible(true);
            let formData = new FormData();
            formData.append("production_order_number", production_order_number);
            postRequest(`/api/priorities/searchPriority`, formData,true)
            .then((response) => {
                setVisible(false);
                let priority = response.data;
                if(priority?.production_order_number){
                    let form_id = null;
                    if(formik.values?.id)form_id = formik.values.id;
                    formik.setValues(priority);
                    formik.setFieldValue("assignment_type","manually");
                    formik.setFieldValue("priority_department_id","");
                    if(form_id)formik.setFieldValue("id",form_id);
                    console.log(formik.values);
                }
                else{
                    alert("Zákazka sa nenašla!");
                }
            });
        }
        else{
            alert("Nezadal si zákazku!");
        }
    }

    const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-zinc-800 text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
          document.addEventListener('click', handleClickOutside, true);
          return () => {
              document.removeEventListener('click', handleClickOutside, true);
          };
      }, []);

    return (
        <div>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewPriority()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyPriority()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
          {/*<div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25"  disabled><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25"  disabled><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25"  disabled><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25"  disabled><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>*/}
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button onClick={() => openFavoriteDialog("Nová Priorita","PriorityAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("PriorityAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
          </IntersectionObserverWrapper>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   Ručne zadaná priorita
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                        <div>
                        <label
                            for="production_order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Výrobná zákazka
                        </label>
                        <input
                            name='production_order_number'
                            type="text"
                            required
                            onChange={formik.handleChange} value={formik.values.production_order_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <div className='flex flex-row mb-2'>
                            <button type='button' onClick={() => searchForPriority(formik.values.production_order_number)} className='wrapIcon flex flex-row border mt-1 border-gray-400 rounded-md bg-white px-1 py-1 hover:text-white hover:!stroke-white hover:bg-blue-500 hover:border-blue-500'>
                            <LoadPrioIcon/>
                            <span className='ml-2'>Načítať z terminplánu</span>
                            </button>
                        </div>
                        </div>
                        <div>
                        <label
                            for="sales_order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Predajná zákazka
                        </label>
                        <input
                            name='sales_order_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.sales_order_number}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                      <div>
                        <label
                            for="priority_department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select
                            required
                            name='priority_department_id'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.priority_department_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        <option value="">Vyber oddelenie</option>
                        {state.priority_departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                        ))}
                        </select>
                        {state.priorities.filter((priority) => 
                        ((priority.production_order_number && priority.production_order_number == formik.values.production_order_number) || (priority.sales_order_number && priority.sales_order_number == formik.values.sales_order_number)) && priority.priority_department_id == formik.values.priority_department_id)
                        .filter(priority => priority.id != formik.values.id).length ? <Message severity="info" text="Duplikát"/> : null}
                        </div>
                        <div>
                        <label
                            for="article_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Artikel
                        </label>
                        <input
                            name='article_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.article_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="delivery_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dodací termín
                        </label>
                        <input
                            name='delivery_date'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.delivery_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>             
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <div>
                        <label
                            for="description_1"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov artikla 
                        </label>
                        <input
                            name='description_1'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description_1}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="description_2"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis artikla
                        </label>
                        <input
                            name='description_2'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description_2}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="drawing_url"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Link výkresu
                        </label>
                        <input
                            name='drawing_url'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.drawing_url}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="drawing_name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Číslo výkresu
                        </label>
                        <input
                            name='drawing_name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.drawing_name}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="total_amount"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Počet kusov
                        </label>
                        <input
                            name='total_amount'
                            type="number"
                            onChange={formik.handleChange} value={formik.values.total_amount}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <button type="submit" ref={submitRef} className='hidden'></button>          
            </div>
            </div>
            </div>
            </form>
            <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
            <ConfirmDialog group="declarative" visible={visibleDuplicate} onHide={() => setVisibleDuplicate(false)} message="Prajete si vložiť duplikát?" 
                header="Jedná sa o duplikát priority" icon="pi pi-info-circle" accept={rejectDuplicate} reject={acceptDuplicate} defaultFocus='accept' acceptLabel='Nie' rejectLabel='Áno' rejectClassName='p-button-danger'/>
            </div>
            );
} 
