import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';

export default function PaintingConditionAdd({painting_condition,type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const [closeTab,setCloseTab] = useState(false);
    const ownToast = useRef(null);
    const submitRef = useRef(null);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible} = useContext(UtilContext);

    const [formPaintingCondition,setFormPaintingCondition] = useState(painting_condition);
    const [formType, setFormType] = useState(type);
    const [checkTimeCondition, setCheckTimeCondition] = useState(false);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add painting"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit painting"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    function addNewPaintingCondition(){
        dispatch({type:'create_new_tab',name:'Nové podmienky lakovne',page:'PaintingConditionAdd',props:{painting_condition: null,type:'add'},mask:'lak003'})
    }

    function copyPaintingCondition(){
        dispatch({type:'create_new_tab',name:'Nové podmienky lakovne',page:'PaintingConditionAdd',props:{painting_condition: formik.values,type:'copy'},mask:'lak003'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    useEffect(() => {
        if(type=="add" && !checkTimeCondition){
            setCheckTimeCondition(false);
            setDialogText("Overujem podmienky");
            setVisible(true);
            getRequest(`/api/painting_conditions/checkTimeConditions`)
            .then((response) => {
                if(response.status == 200){
                    setVisible(false);
                }
                else{
                    dispatch({type:'close_tab',id:state.selectedTab});
                    setVisible(false);
                    alert("Si mimo intervalu pre vloženie!");   
                }
            }).catch((reason) => {
                setVisible(false);
                alert("Nepodarilo sa overiť pomienky!");
            })
        }
      },[type,checkTimeCondition]);

    const formik = useFormik({
        initialValues: formPaintingCondition==null?{
            outdoor_temp: '',indoor_temp:'',outdoor_hum:'',indoor_hum:'',weather:'',ph_probe:'OK',ph_phosphate:'OK',note:'',temp_check:'OK',nacl_check:'OK',state:'',
        }:formPaintingCondition,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            //let ph_phosphate = state.painting_intervals.filter(interval => interval.id==values.ph_phosphate);
            //let temp_check = state.painting_intervals.filter(interval => interval.id==values.temp_check);
            if(values.ph_probe=='OK' && values.nacl_check=='OK' && values.ph_phosphate=='OK' && values.temp_check=='OK'){
                values.state='OK';
            }
            else {
                values.state='NOK';
            }
            for (let value in values) {
                formData.append(value, values[value]);
            }
            if(formType=="add"){
                postRequest('/api/painting_conditions', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/painting_conditions").then((response) => {
                        dispatch({type:'load_painting_conditions',newPaintingConditions:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/painting_conditions/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/painting_conditions").then((response) => {
                        dispatch({type:'load_painting_conditions',newPaintingConditions:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function loadOutdoorWeather() {
        var key = '24110dc31c01b76eeef9d9264d0483e8';
        //fetch('https://api.openweathermap.org/data/2.5/weather?id=865084&units=metric&lang=sk&appid=' + key)  
        fetch('https://api.openweathermap.org/data/2.5/weather?lat=48.559885&lon=21.266370&units=metric&lang=sk&appid=' + key)
        .then(function(resp) { return resp.json() }) // Convert data to json
        .then(function(data) {
          console.log(data);
          formik.setFieldValue("outdoor_temp",Math.round(data.main.temp));
          formik.setFieldValue("outdoor_hum",Math.round(data.main.humidity));
        })
        .catch(function() {
          // catch any errors
        });
    }

    function getIndex(painting_condition_id) {
        return state.painting_conditions.findIndex(obj => obj.id === painting_condition_id);
    }

    async function scrollToFirst(){
        let my_painting_condition = state.painting_conditions[0];
        setFormPaintingCondition(my_painting_condition);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormPaintingCondition(state.painting_conditions[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.painting_intervals.length){
            setFormPaintingCondition(state.painting_conditions[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.painting_intervals.length-1;
        let my_painting_condition = state.painting_conditions[last];
        setFormPaintingCondition(my_painting_condition);
    }

    return (

        <div>
            <Toast ref={ownToast}/>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewPaintingCondition()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyPaintingCondition()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => openFavoriteDialog("Nové podmienky lakovne","PaintingConditionAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("PaintingConditionAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   PODMIENKY LAKOVANIA
                </h1>
                <div className='flex flex-row m-auto justify-left mt-2'>
                    <div className='mr-2'>
                    <button type="button" onClick={() => loadOutdoorWeather()} className='wrapIconFill flex flex-row border mt-1 border-gray-400 rounded-md bg-white px-1 py-1 hover:text-white hover:!stroke-white hover:bg-blue-500 hover:border-blue-500'>
                    <svg class="w-6 h-6 text-gray-800 dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-11a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm0 12a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1ZM4.343 5.757a1 1 0 0 0 1.414-1.414L4.343 2.929a1 1 0 0 0-1.414 1.414l1.414 1.414Zm11.314 8.486a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM4 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm15-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2ZM4.343 14.243l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414a1 1 0 0 0-1.414-1.414ZM14.95 6.05a1 1 0 0 0 .707-.293l1.414-1.414a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 .707 1.707Z"/>
                    </svg>
                    <span className='ml-2 text-xl font-light text-left'>Nahrať údaje z internetu</span>
                    </button>
                    </div>
                </div>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            for="outdoor_temp"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VONKAJŠIA TEPLOTA v °C
                        </label>
                        <input
                            name='outdoor_temp'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.outdoor_temp}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="outdoor_hum"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VONKAJŠIA VLHKOSŤ v %
                        </label>
                        <input
                            name='outdoor_hum'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.outdoor_hum}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            for="indoor_temp"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VNÚTORNÁ TEPLOTA v °C
                        </label>
                        <input
                            name='indoor_temp'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.indoor_temp}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="indoor_hum"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VNÚTORNÁ VLHKOSŤ v %
                        </label>
                        <input
                            name='indoor_hum'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.indoor_hum}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                      <div>
                        <label
                            for="weather"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POČASIE
                        </label>
                        <select 
                        name='weather'
                        onChange={formik.handleChange} value={formik.values.weather}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber počasie</option>
                            {state.weather.map((weather) => 
                            <option value={weather.type} key={weather.id}>{weather.type}</option>
                            )}
                        </select>
                        </div>              
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-xl font-semibold text-left text-zinc-900">
                   Príprava pred lakovaním 
                </h1>
                      <div className="mt-6">
                        <label
                            for="ph_probe"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Kontrola čistoty pH sondy
                        </label>
                        <select 
                        name='ph_probe'
                        onChange={formik.handleChange} value={formik.values.ph_probe}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </div>
                        <div>
                        <label
                            for="ph_phosphate"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Kontrola pH fosfátu
                        </label>
                        <select 
                        name='ph_phosphate'
                        onChange={formik.handleChange} value={formik.values.ph_phosphate}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            {state.painting_intervals.filter(interval=>interval.type=='PH').map((painting_interval) => (
                            <option key={painting_interval.id} value={painting_interval.state}>{painting_interval.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            for="temp_check"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Kontrola teploty WAP
                        </label>
                        <select 
                        name='temp_check'
                        onChange={formik.handleChange} value={formik.values.temp_check}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            {state.painting_intervals.filter(interval=>interval.type=='WAP').map((painting_interval) => (
                            <option key={painting_interval.id} value={painting_interval.state}>{painting_interval.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            for="nacl_check"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Kontrola NaCl v sude
                        </label>
                        <select 
                        name='nacl_check'
                        onChange={formik.handleChange} value={formik.values.nacl_check}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="OK">OK</option>
                          <option value="NOK">NOK</option>
                        </select>
                        </div>
                        <div>
                        <label
                            for="note"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poznámka
                        </label>
                        <textarea
                        name='note'
                        onChange={formik.handleChange} value={formik.values.note}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <button type="submit" ref={submitRef} className='hidden'></button>          
            </div>
            </div>
            </div>
            </form>
            </div>
            );
} 
