import React from "react"
import { useTabContext } from "@mui/lab"
import { Box } from "@mui/material"

const TabPanel = ({ children, value }) => {
  const { value: contextValue } = useTabContext() || {}
  return (
    <Box
      sx={{ display: value === contextValue ? "block" : "none" }}
      key={value}
    >
      {children}
    </Box>
  )
}
export default TabPanel;