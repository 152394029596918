import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';

export default function PaintingIntervalAdd({painting_interval, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    

    const [formPaintingInterval,setFormPaintingInterval] = useState(painting_interval);
    const [formType, setFormType] = useState(type);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("edit painting"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit painting"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const submitRef = useRef(null);

    function addNewPaintingInterval(){
        dispatch({type:'create_new_tab',name:'Nové intervaly lakovne',page:'PaintingIntervalAdd',props:{painting_interval: null,type:'add'},mask:'lak009'})
    }

    function copyPaintingInterval(){
        dispatch({type:'create_new_tab',name:'Nové intervaly lakovne',page:'PaintingIntervalAdd',props:{painting_interval: formik.values,type:'copy'},mask:'lak009'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formPaintingInterval==null?{
            type: '',name:'',state:'',
        }:formPaintingInterval,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            if(formType=="add"){
                postRequest("/api/painting_intervals", formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/painting_intervals").then((response) => {
                        dispatch({type:'load_painting_intervals',newPaintingIntervals:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/painting_intervals/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/painting_intervals").then((response) => {
                        dispatch({type:'load_painting_intervals',newPaintingIntervals:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(painting_interval_id) {
        return state.painting_intervals.findIndex(obj => obj.id === painting_interval_id);
    }

    async function scrollToFirst(){
        let my_painting_interval = state.painting_intervals[0];
        setFormPaintingInterval(my_painting_interval);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormPaintingInterval(state.painting_intervals[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.painting_intervals.length){
            setFormPaintingInterval(state.painting_intervals[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.painting_intervals.length-1;
        let my_painting_interval = state.painting_intervals[last];
        setFormPaintingInterval(my_painting_interval);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
          alert("Vyberte skupinu!");
          return;
        }
        else if(favoriteGroupValue=="nova"){
          if(favoriteValue==""){
            alert("Napíšte názov novez skupiny!");
            return;
          }
          else{
            //VYTVORIT SKUPINU AJ FAVORITA
            setFavoriteVisible(false);
            setVisibleLoading(true);
            let formData = new FormData();
            formData.append("name", favoriteValue);
            postRequest(`/api/favorite_groups`, formData,true)
            .then((response) => {
              let formData2 = new FormData();
              formData2.append("name", "Nový interval lakovne");
              formData2.append("page", "PaintingIntervalAdd");
              formData2.append("favorite_group_id", response.data.id);
              postRequest(`/api/favorites`, formData2,true)
              .then((response) => {
                  getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisibleLoading(false);
                });
              }).catch((reason) => {
                  setVisibleLoading(false);
              })
            }).catch((reason) => {
                setVisibleLoading(false);
            })
          }
        }
        else{
          //VYTVORIT FAVORITA a pridat do skupiny
          setFavoriteVisible(false);
          setVisibleLoading(true);
          let formData2 = new FormData();
          formData2.append("name", "Nový interval lakovne");
          formData2.append("page", "PaintingIntervalAdd");
          formData2.append("favorite_group_id", favoriteGroupValue);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
          })
        }
    }

    return (

        <div>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewPaintingInterval()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyPaintingInterval()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                { !user.permissions.includes("edit painting") && 
                    <div className='w-full h-8 flex justify-center px-2 py-1 mb-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                            <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                        </svg>
                    </div> }
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   INTERVALY 
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <form className="mt-6" onSubmit={formik.handleSubmit}>
                      <div>
                        <label
                            for="type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Typ intervalu
                        </label>
                        <select 
                         name='type'
                         onChange={formik.handleChange} value={formik.values.type}
                         disabled={!user.permissions.includes("edit painting")} 
                         className={` ${
                             user.permissions.includes("edit painting") ? "bg-white " : "bg-gray-200 "
                         } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                            <option className='' value="">Vyber možnosť</option>
                            <option className='' value="PH">pH sonda</option>
                            <option className='' value="WAP">WAP</option>
                        </select>
                        </div>
                        <div>
                        <label
                            for="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov intervalu
                        </label>
                        <input
                             name='name'
                             type="text"
                             onChange={formik.handleChange} value={formik.values.name}
                             readOnly={!user.permissions.includes("edit painting")} 
                             className={` ${
                                 user.permissions.includes("edit painting") ? "bg-white " : "bg-gray-200 "
                             } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            for="von_vlh"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Stav intervalu
                        </label>
                        <select 
                         name='state'
                         onChange={formik.handleChange} value={formik.values.state}
                         disabled={!user.permissions.includes("edit painting")} 
                         className={` ${
                             user.permissions.includes("edit painting") ? "bg-white " : "bg-gray-200 "
                         } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                            <option className='' value="">Vyber možnosť</option>
                            <option className='' value="NOK1">NOK1</option>
                            <option className='' value="OK">OK</option>
                            <option className='' value="NOK2">NOK2</option>
                        </select>
                        </div>   
                        <button type="submit" ref={submitRef} className='hidden'></button>      
                </form>              
            </div>
            </div>
            </div>
            <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
            <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
            </div>
            );
} 
