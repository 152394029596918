import { Component, useContext, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from "quill-image-uploader";
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import "./../assets/css/editor.css";
import { Avatar } from 'primereact/avatar';
import ReactDOM from 'react-dom';
import QuillMention from 'quill-mention';
import "quill-mention/dist/quill.mention.css";
import { DatabaseContext } from '../redux/DatabaseStore';
import AuthContext from './AuthContext';
import UtilContext from './UtilContext';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { DeleteIcon } from '../pages/svg/svgIcons';
import "./../assets/css/editor.css";
//Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/imageUploader", ImageUploader);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

/*
 * Simple editor component that takes placeholder text as a prop
 */
function MyEditor({editorValue,autofocus=false,hideToolbar=false,hideFileUpload=false,formik}){

  const [state,dispatch] = useContext(DatabaseContext);
  const {user} = useContext(AuthContext);
  const uploadRef = useRef(null);
  const quillRef = useRef(null);
  const {openFavoriteDialog,toast,setFastView,getUserColor}= useContext(UtilContext);

  function handleChange(value, delta){
    formik?.setFieldValue("description",value);
  }

  useEffect(() => {
    // Update the document title using the browser API
    if(autofocus){
      quillRef.current.focus();
    }
  },[autofocus]);

  const [totalSize, setTotalSize] = useState(0);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;
    formik?.setFieldValue("files",files);

    Object.keys(files).forEach((key) => {
        _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      formik?.setFieldValue("files",formik?.values?.files?.filter((my_file) => my_file != file));
      callback();
  };

  const onTemplateClear = () => {
      setTotalSize(0);
      formik?.setFieldValue("files",[]);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    const value = totalSize / 100000;
    const formatedValue = uploadRef && uploadRef.current ? uploadRef.current.formatSize(totalSize) : '0 B';

    return (
        <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center',padding:'5px' }}>
            {chooseButton}
            {cancelButton}
            <div className="flex align-items-center gap-3 ml-auto">
                {/*<span>{formatedValue}</span>
                <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>*/}
            </div>
        </div>
    );
};

const itemTemplate = (file, props) => {
    return (
            <div className="flex align-items-center p-2" style={{ width: '40%' }}>
                <div className='relative'>
                <img alt={file.name} role="presentation" src={file.objectURL} width={'100px'} />
                <button type='button' className='absolute right-2 top-2 bg-red-500 border rounded-full p-1 border-red-500' onClick={() => onTemplateRemove(file, props.onRemove)}><DeleteIcon/></button>
                </div>
                <span className="flex flex-column text-left ml-3 font-bold">
                    {file.name.length>10 ? file.name.substring(0, 10)+"..."+file.name.split('.').pop() : file.name}
                </span>
            </div>
    );
};

const emptyTemplate = () => {
    return (
        <div className="flex flex-column items-center ">
            <i className="pi pi-image mt-1 p-5" style={{ fontSize: '1.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
            <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                Natiahnite obrázky sem
            </span>
        </div>
    );
};

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined !bg-[#2196F3] !border-[#2196F3]'};
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  function imageHandler() {
    const editor = quillRef.current.getEditor();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      file.objectURL = URL.createObjectURL(file);
      formik?.setFieldValue("files",[...uploadRef.current.getFiles(),file]);
      uploadRef.current.setFiles([...uploadRef.current.getFiles(),file]);
    };
  }

  function imagePasteHandler(imageDataUrl, type, imageData) {
    const file = imageData.toFile()
    file.objectURL = URL.createObjectURL(file);
    formik?.setFieldValue("files",[...uploadRef.current.getFiles(),file]);
    uploadRef.current.setFiles([...uploadRef.current.getFiles(),file]);
  }


  const [modules,setModules] = useState({
    toolbar: hideToolbar ? false :{
      container: [
          [{ size: [] }, { font: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ color: [] }, { background: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
            image: imageHandler,
        }
    },
    clipboard: {
      matchVisual: false,
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            source: function(searchTerm, renderList, mentionChar) {
              let values = state.users.map((user) => ({id: user.id,value: user?.employee?.name || user?.username}));
      
              if (searchTerm.length === 0) {
                renderList(values.slice(0, 5), searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                      ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches.slice(0, 5), searchTerm);
              }
            },
            renderItem: function(item, searchTerm){
              const all_names = item.value.trim().split(' ');
              const initials = all_names.length == 3 ? all_names.reduce((acc, curr, index) => {
                  if(index === all_names.length - 2 || index === all_names.length - 1){
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                  }
                  return acc;
                }, '') : all_names.reduce((acc, curr, index) => {
                  if(index === 0 || index === all_names.length - 1){
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                  }
                  return acc;
                }, '');
                const element = document.createElement('span');
                var article = <div className='flex flex-row items-center'>
                <Avatar className='p-overlay-badge' title={user?.employee?.name || user?.username} label={initials} shape="circle" style={{ backgroundColor: '#1d4ed8', color: '#ffffff',marginRight: '5px'}}>
                </Avatar>
                <div>{item.value}</div>
                </div>;
                ReactDOM.render(article, element);
                return element;
            },
            dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'disabled',],
    },
    imageDropAndPaste: {
      handler: imagePasteHandler,
    },
    /*imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },*/
    /*imageUploader: {
      upload: (file) => {
        setFileList([...fileList,file]);
        console.log(file);
        return "";
      }
    }*/
  },

  );

  const [formats,setFormats] = useState([
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'background',
    'bullet',
    'indent',
    'link',
    'image',
    'imageBlot',
    'mention'
  ]);

  return (<>
    <ReactQuill
      ref={quillRef}
      theme={undefined}
      onChange={handleChange}
      value={formik?.values?.description || editorValue}
      modules={modules}
      formats={formats}
      bounds={'#root'}
      placeholder={"Napíš komentár..."}
      readOnly={hideFileUpload ? true : false}
    />
    {!hideFileUpload && <>
    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
    <FileUpload ref={uploadRef} name="demo[]" accept="image/*" multiple maxFileSize={10000000} 
    onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
    headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
    chooseOptions={chooseOptions} cancelOptions={cancelOptions}/>
    </>}
      </>
  );
}

export default MyEditor;
