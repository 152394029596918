import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon, InfoIcon, ErrorIcon, TaskIcon, PermissionIcon, NeedIcon, EmergencyIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../../assets/css/support.css'
import { Editor } from 'primereact/editor';
import { Avatar } from 'primereact/avatar';
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import MyEditor from './../../components/Editor';

export default function SupportTicketAdd({suport_ticket, type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,getUserColor,setDialogText,setVisible}= useContext(UtilContext);

    const [text, setText] = useState('');

    const [closeTab,setCloseTab] = useState(false);

    const [formSupportTicket,setFormSupportTicket] = useState(suport_ticket);
    const [activeIndex,setActiveIndex] = useState(null);

    var findedIds = [];

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    function loopThroughDOM(obj) {
        if(Array.isArray(obj)){
            for(let i=0;i<obj.length;i++){
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("children")){
                    loopThroughDOM(obj[i]["props"]["children"]);
                }
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("data-value")){
                    findedIds.push(obj[i]["props"]["data-id"]);
                }
            }
        }
        else{
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("children") && obj.props.children!=undefined){
                loopThroughDOM(obj["props"]["children"]);
            }
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("data-value")){
                findedIds.push(obj["props"]["data-id"]);
            }
        }
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formSupportTicket==null?{
            theme:'',description:'',mask_number:'',priority:'normal'
        }:formSupportTicket,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            setDialogText("Ukladám...");
            setVisible(true);
            if(values.priority=="")values.priority="normal";
            let formData = new FormData();
            for (let value in values) {
                if(value=="files"){
                    for(let i = 0;i<values[value].length;i++){
                        formData.append('files[]',values[value][i]);
                    }
                }
                else formData.append(value, values[value]);
            }
            formData.append('contractor_id', user.id);
            let mentions = parse(values.description);
            loopThroughDOM(mentions);
            findedIds.push(user.id+'');
            formData.append('mentions', findedIds.filter(onlyUnique));
            switch(activeIndex){
                case 0: {formData.append('type', 'Info');break;}
                case 1: {formData.append('type', 'Error');break;}
                case 2: {formData.append('type', 'Task');break;}
                case 3: {formData.append('type', 'Permission');break;}
                case 4: {formData.append('type', 'Need');break;}
                case 5: {formData.append('type', 'Emergency');break;}
                default: {formData.append('type', 'Info');break;}
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }*/
            postRequest('/api/support_tickets', formData,true)
            .then((response) => {
                getRequest("/api/support_tickets").then((response) => {
                    dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    findedIds = [];
                    setActiveIndex(null);
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                setVisible(false);
            }).catch((reason) => {
            setVisible(false);
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
      },
    });

    return (
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full relative flex flex-col overflow-x h-[calc(100vh_-_137px)]">
            <div className='w-full px-4 py-4 bg-[#333] text-white border rounded-sm'>
                <h1 className='text-center text-3xl font-semibold '>Schelling App - Podpora</h1>
            </div>
            <div className='card flex flex-col px-4 py-4 border rounded-sm shadow-xl overflow-y-auto'>
            <Accordion activeIndex={activeIndex} onTabChange={(e) => {setActiveIndex(e.index);formik.resetForm();}}>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=0 ? "hidden" : ""}`} 
                header={<React.Fragment><InfoIcon width={10} height={10}/><span>Schelling App - Otázka / Question</span></React.Fragment>}>
                        <p>Aby sme Vám vedeli pomôcť čo najefektívnejším spôsobom, prosím zodpovedajte tieto otázky v časti "Popis" nasledovne.</p>
                            <ol>
                                <li>Čo sa pokúšate urobiť?</li>
                                <li>Ako ste to urobili / Aké kroky ste podstúpili?</li>
                                <li>Aký ste dostali výsledok?</li>
                                <li>Aký bol očakávaný výsledok?</li>
                            </ol>
                            <p className='mb-2'>Ďakujeme!</p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' required value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis:</label>
                        {/*<Editor modules={modules} name='description' value={formik.values.description} onTextChange={(e) => {formik.setFieldValue('description',e.htmlValue);}} style={{ height: '180px' }} />*/}
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        <br/>Počet súborov: {}<br/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' value={formik.values.mask_number} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Priorita:</label>
                            <select  
                            name='priority' value={formik.values.priority} onChange={formik.handleChange}
                            className="block w-full text-sm px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                <option value="low">Nízka</option>
                                <option value="normal">Normálna</option>
                                <option value="high">Vysoká</option>
                                <option value="blocker">Bloker - nedá sa pracovať</option>
                            </select>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=1 ? "hidden" : ""}`} 
                header={<React.Fragment><ErrorIcon width={10} height={10}/><span>Chyba / Error</span></React.Fragment>}>
                        <p>Aby sme Vám vedeli pomôcť čo najefektívnejším spôsobom, prosím zodpovedajte tieto otázky v časti "Popis" nasledovne.</p>
                            <ol>
                                <li>Čo sa pokúšate urobiť?</li>
                                <li>Ako ste to urobili / Aké kroky ste podstúpili?</li>
                                <li>Aký ste dostali výsledok?</li>
                                <li>Aký bol očakávaný výsledok?</li>
                            </ol>
                            <p className='mb-2'>Ďakujeme!</p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' value={formik.values.mask_number} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Priorita:</label>
                            <select 
                            name='priority' value={formik.values.priority} onChange={formik.handleChange}
                             className="block w-full text-sm px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                <option value="low">Nízka</option>
                                <option value="normal">Normálna</option>
                                <option value="high">Vysoká</option>
                                <option value="blocker">Bloker - nedá sa pracovať</option>
                            </select>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=2 ? "hidden" : ""}`} 
                header={<React.Fragment><TaskIcon width={10} height={10}/><span>Úloha / Task</span></React.Fragment>}>
                        <p>Aby sme Vám vedeli pomôcť čo najefektívnejším spôsobom, prosím zodpovedajte tieto otázky v časti "Popis" nasledovne.</p>
                            <ol>
                                <li>Čo sa pokúšate urobiť?</li>
                                <li>Ako ste to urobili / Aké kroky ste podstúpili?</li>
                                <li>Aký ste dostali výsledok?</li>
                                <li>Aký bol očakávaný výsledok?</li>
                            </ol>
                            <p className='mb-2'>Ďakujeme!</p>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' value={formik.values.mask_number} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Priorita:</label>
                            <select 
                            name='priority' value={formik.values.priority} onChange={formik.handleChange}
                             className="block w-full text-sm px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                <option value="low">Nízka</option>
                                <option value="normal">Normálna</option>
                                <option value="high">Vysoká</option>
                                <option value="blocker">Bloker - nedá sa pracovať</option>
                            </select>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=3 ? "hidden" : ""}`} 
                header={<React.Fragment><PermissionIcon width={10} height={10}/><span>Povolenie / Permission</span></React.Fragment>}>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' value={formik.values.mask_number} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=4 ? "hidden" : ""}`} 
                header={<React.Fragment><NeedIcon width={10} height={10}/><span>Potreba / Need</span></React.Fragment>}>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' value={formik.values.mask_number} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
                <AccordionTab className={` ${activeIndex!=null && activeIndex!=5 ? "hidden" : ""}`} 
                header={<React.Fragment><EmergencyIcon width={10} height={10}/><span>Núdzová správa / Emergency</span></React.Fragment>}>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' value={formik.values.theme} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                        <label className='font-semibold  text-sm'>Popis:</label>
                        <MyEditor editorValue={formik.values.description} formik={formik} />
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' value={formik.values.mask_number} onChange={formik.handleChange} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </AccordionTab>
            </Accordion>
            </div>
        </div>
        </form>
            </>
            );
} 
