import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from "primereact/checkbox";
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { SaveIcon, AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, StepForwardIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import UtilContext from '../../components/UtilContext';
import { FamilyRestroomRounded } from '@mui/icons-material';

export default function CustomerAdd({customer,type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {openFavoriteDialog,toast,setFastView,setVisible,setDialogText}= useContext(UtilContext);

    const [formCustomer,setFormCustomer] = useState(customer);
    const [customerType, setCustomerType] = useState(type);

    const [scrollIcons,setScrollIcons] = useState(true);

    const [closeTab,setCloseTab] = useState(false);

    const submitRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
        // Update the document title using the browser API
        if(customerType=="edit"){
            setScrollIcons(false);
        }
    },[customerType]);

    /* validate form */
    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formCustomer==null?{
            id: '',name:'',description:'',defined_id:'',offender_id:'',
        }:formCustomer,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            setDialogText("Ukladám...");
            setVisible(true);
            if(customerType=="add"){
                postRequest('/api/customers', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/customers").then((response) => {
                        dispatch({type:'load_customers',newCustomers:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setCustomerType("edit");
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
            else{
                postRequest(`/api/customers/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/customers").then((response) => {
                        dispatch({type:'load_customers',newCustomers:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        formik.setValues(values);
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
        },
    });


    function addNewCustomer(){
        dispatch({type:'create_new_tab',name:'Nový zákazník',page:'CustomerAdd',props:{customer: null,type:'add'},mask:'glo010'})
    }

    function copyCustomer(){
        dispatch({type:'create_new_tab',name:'Nový zákazník',page:'CustomerAdd',props:{customer: formik.values,type:'copy'},mask:'glo010'})
    }

    function getIndex(customer_id) {
        return state.customers.findIndex(obj => obj.id === customer_id);
    }

    async function scrollToFirst(){
        let customer = state.customers[0];
        setFormCustomer(customer);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormCustomer(state.customers[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<=state.customers.length-1){
            setFormCustomer(state.customers[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.customers.length-1;
        let customer = state.customers[last];
        setFormCustomer(customer);
    }

    return (
        <div>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => addNewCustomer()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => copyCustomer()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="opacity-25"><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => openFavoriteDialog("Nový zákazník","CustomerAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => setFastView("CustomerAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
        </div>
        
        <div className="relative flex flex-col justify-left overflow-x min-h-[calc(100vh-177px)]">
          <div className=" mb-2 ">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ZÁKAZNÍK
                </h1>
                {customerType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-1 mb-2">
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zákazníka
                        </label>
                        <input
                            name='name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis zákazníka
                        </label>
                        <input
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                    <div>
                        <label
                            htmlFor="defined_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Priradené ID
                        </label>
                        <input
                            name='defined_id'
                            type="number"
                            onChange={formik.handleChange} value={formik.values.defined_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                </div>  
                <button type="submit" ref={submitRef} className='hidden'></button>
            </div> 
                </form>       
            </div>
            <div>
            <input
                name='type'
                type="hidden"
                value={formik.values.type}
            />
            </div>
        </div>
        </div>
    );
}