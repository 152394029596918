import React, { useContext,useState, useEffect, useRef  } from "react";
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DatabaseContext } from "../../redux/DatabaseStore";
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, CalendarIcon,EditRowIcon} from "../svg/svgIcons";
import { deleteRequest, getRequest,postRequest } from '../../api/ApiController';
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../components/AuthContext";
import moment from "moment";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';


export default function PreviewCal() {

    const [state,dispatch] = useContext(DatabaseContext);

    const [timePeriod,setTimePeriod] = useState('Všetko');
    const [date,setDate] = useState(null);
    const [dateProps,setDateProps] = useState({hidden:true});

    addLocale('sk', {
        firstDayOfWeek: 1,
        showMonthAfterYear: false,
        dayNames: ['Nedela','Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
        dayNamesShort: ['Ne','Pon','Ut','Str','Štv','Pia','So'],
        dayNamesMin: ['Ne','Po', 'Ut', 'Str', 'Štv', 'Pi', 'So'],
        monthNames: ['Január', 'Február', 'Marec', 'Apríl', 'Máj ', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj ', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        today: 'Dnes',
        clear: 'Vymazať'
    });

    const [calibratedData,setCalibratedData] = useState({
        labels: ['Po expirácií', '15 dní do exp.', '30 dní do exp.','Spôsobilé'],
        datasets: [{
                data: [state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => (gauge.gauge_calibrations.length == 0 && gauge.gauge_status=="Kalibrované")  || (gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Kalibrované" && new Date(gauge.gauge_calibrations[0].next_calibration_date) < new Date())).length, 
                state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Kalibrované" && new Date(gauge.gauge_calibrations[0].next_calibration_date) <= new Date(new Date().setDate(new Date().getDate() + 15)) && new Date(gauge.gauge_calibrations[0].next_calibration_date) > new Date()).length,
                state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Kalibrované" && new Date(gauge.gauge_calibrations[0].next_calibration_date) <= new Date(new Date().setDate(new Date().getDate() + 30)) && new Date(gauge.gauge_calibrations[0].next_calibration_date) > new Date()).length,
                state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Kalibrované" && new Date(gauge.gauge_calibrations[0].next_calibration_date) > new Date(new Date().setDate(new Date().getDate() + 30))).length],
                backgroundColor: ["#b91c1c","#ca8a04","#cccc00","#15803d","#262626"],
                hoverBackgroundColor: ["#b91c1c","#ca8a04","#cccc00","#15803d","#262626"],
        }]
    });

    const [informativeData,setInformativeData] = useState({
        labels: ['Po expirácií', '15 dní do exp.', '30 dní do exp.','Spôsobilé'],
        datasets: [{
            data: [state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => (gauge.gauge_calibrations.length == 0 && gauge.gauge_status=="Informatívne")  || (gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Informatívne" && new Date(gauge.gauge_calibrations[0].next_calibration_date) < new Date())).length, 
            state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Informatívne" && new Date(gauge.gauge_calibrations[0].next_calibration_date) <= new Date(new Date().setDate(new Date().getDate() + 15)) && new Date(gauge.gauge_calibrations[0].next_calibration_date) > new Date()).length,
            state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Informatívne" && new Date(gauge.gauge_calibrations[0].next_calibration_date) <= new Date(new Date().setDate(new Date().getDate() + 30)) && new Date(gauge.gauge_calibrations[0].next_calibration_date) > new Date()).length,
            state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_calibrations.length > 0 && gauge.gauge_status=="Informatívne" && new Date(gauge.gauge_calibrations[0].next_calibration_date) > new Date(new Date().setDate(new Date().getDate() + 30))).length],
            backgroundColor: ["#b91c1c","#ca8a04","#cccc00","#15803d","#262626"],
            hoverBackgroundColor: ["#b91c1c","#ca8a04","#cccc00","#15803d","#262626"],
        }]
    })

    const [allData,setAllData] = useState({
        labels: ['Kalibrované', 'Informatívne'],
        datasets:  [{
            data: [state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_status=="Kalibrované").length,
            state.gauges.filter(gauge => gauge.state != "Vyradené").filter(gauge => gauge.gauge_status=="Informatívne").length],
            backgroundColor: ["#15803d","#1d4ed8","#ca8a04","#15803d","#262626"],
            hoverBackgroundColor: ["#15803d","#1d4ed8","#ca8a04","#15803d","#262626"],
        }]
    });


    const [calibratedOptions] = useState({
        plugins: {
            legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: 'after',status:'Kalibrované'}});
            }
            else if(item[0].index==1){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: '15',status:'Kalibrované'}});
            }
            else if(item[0].index==2){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: '30',status:'Kalibrované'}});
            }
            else if(item[0].index==3){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: 'others',status:'Kalibrované'}});
            }
        }
    });

    const [informativeOptions] = useState({
        plugins: {
            legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: 'after',status:'Informatívne'}});
            }
            else if(item[0].index==1){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: '15',status:'Informatívne'}});
            }
            else if(item[0].index==2){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: '30',status:'Informatívne'}});
            }
            else if(item[0].index==3){
                dispatch({type:'create_new_tab',name:'Zoznam kalibrácií',page:'GaugeCalibrationList',props:{preview: 'others',status:'Informatívne'}});
            }
        }
    });

    const [allOptions] = useState({
        plugins: {
            legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Zoznam meradiel',page:'GaugeList',props:{preview:'Kalibrované'}});
            }
            else if(item[0].index==1){
                dispatch({type:'create_new_tab',name:'Zoznam meradiel',page:'GaugeList',props:{preview:'Informatívne'}});
            }
            else if(item[0].index==2){
                dispatch({type:'create_new_tab',name:'Zoznam meradiel',page:'GaugeList',props:{preview:'Vyradené'}});
            }
        }
    });

    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
            let count = 0;
            for (const element of chart.data.datasets[0].data) {
                count = count + element;
            }
            if (count < 1) {
                let ctx = chart.ctx;
                let width = chart.width;
                let height = chart.height;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "26px Arial";
                ctx.color = "red";
                ctx.fillStyle = "darkgrey";
                ctx.fillText("Bez záznamov", width / 2, height / 2);
                ctx.restore();
            }
        }
    };

    return (
        <div className="min-h-[calc(100vh-137px)] ">
        <div className="lg:grid grid-cols-3 gap-1 content-center">
        <div>
            <h1 className="flex items-center justify-center text-3xl font-semibold mt-8">Kalibrované</h1>
            <Chart type="doughnut" data={calibratedData} options={calibratedOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]} />
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {calibratedData.datasets[0].data.reduce((a, b) => a + b, 0)}</span>
        </div>
        <div>
            <h1 className="flex items-center justify-center text-3xl font-semibold mt-8">Informatívne</h1>
            <Chart type="doughnut" data={informativeData} options={informativeOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]}/>
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {informativeData.datasets[0].data.reduce((a, b) => a + b, 0)}</span>
        </div>
        <div>
            <h1 className="flex items-center justify-center text-3xl font-semibold mt-8">Všetky</h1>
            <Chart type="doughnut" data={allData} options={allOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]} />
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {allData.datasets[0].data.reduce((a, b) => a + b, 0)}</span>
        </div>
      </div>
</div>
    );
}
