import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, StepForwardIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import UtilContext from '../../components/UtilContext';

export default function DepartmentAdd({department,type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {openFavoriteDialog,toast,setFastView,setVisible,setDialogText}= useContext(UtilContext);


    const [formDepartment,setFormDepartment] = useState(department);
    const [departmentType, setDepartmentType] = useState(type);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);

    const [closeTab,setCloseTab] = useState(false);

    const submitRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
        // Update the document title using the browser API
        if(departmentType=="edit"){
            setScrollIcons(false);
        }
    },[departmentType]);

    /* validate form */
    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formDepartment==null?{
            name: '',hourly_rate:'',defined_id:'',
        }:formDepartment,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            if(departmentType=="add"){
                postRequest('/api/departments', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/departments").then((response) => {
                        dispatch({type:'load_departments',newDepartments:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setDepartmentType("edit");
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    alert("Daný úkon sa nepodaril!");
                })
            }
            else{
                postRequest(`/api/departments/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/departments").then((response) => {
                        dispatch({type:'load_departments',newDepartments:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        formik.setValues(values);
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    alert("Daný úkon sa nepodaril!");
                })
            }
        },
    });

      function copyDepartment(){
        dispatch({type:'create_new_tab',name:'Nové oddelenie',page:'DepartmentAdd',props:{department: formik.values,type:'copy'},mask:'glo002'})
      }
      function addNewDepartment(){
        dispatch({type:'create_new_tab',name:'Nové oddelenie',page:'DepartmentAdd',props:{department: null,type:'add'},mask:'glo002'})
      }

    function getIndex(department_id) {
        return state.departments.findIndex(obj => obj.id === department_id);
    }

    async function scrollToFirst(){
        let department = state.departments[0];
        setFormDepartment(department);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormDepartment(state.departments[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<=state.departments.length-1){
            setFormDepartment(state.departments[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.departments.length-1;
        let department = state.departments[last];
        setFormDepartment(department);
    }


return(
<>
<div>
    <div className='w-full h-10 flex flex-row bg-[#333]'>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => addNewDepartment()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => copyDepartment()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => openFavoriteDialog("Nové oddelenie","DepartmentAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => setFastView("DepartmentAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
        </div>
<div className="relative flex flex-col justify-left overflow-x min-h-[calc(100vh-177px)]">
    <div className=" mb-2 ">
    <form onSubmit={formik.handleSubmit}>
    <div className="w-auto p-6 m-4 bg-gray-100">
    <h1 className="text-3xl font-semibold text-left text-zinc-900">
        ODDELENIE
    </h1>
    {departmentType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
    <div className="mb-2">
      <div>
        <label
            for="name"
            className="block text-md font-semibold text-gray-800"
        >
            Názov oddelenia
        </label>
        <input
            name='name'
            type="text"
            onChange={formik.handleChange} value={formik.values.name}
            className="block w-fit  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        </div>
        <div>
        <label
            for="hourly_rate"
            className="block text-md font-semibold text-gray-800"
        >
            Hod. sadzba
        </label>
        <input
            name='hourly_rate'
            type="text"
            onChange={formik.handleChange} value={formik.values.hourly_rate}
            className="block w-fit px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        </div>
    </div>
    <div className="mb-2">
      <div>
        <label
            for="defined_id"
            className="block text-md font-semibold text-gray-800"
        >
            Číslo oddelenia
        </label>
        <input
            name='defined_id'
            type="text"
            onChange={formik.handleChange} value={formik.values.defined_id}
            className="block w-fit px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
        />
        </div>
        </div>
    
    </div>
    <button type="submit" ref={submitRef} className='hidden'></button>  
    </form>           
    </div>
    </div>
    </div>
    </>
    );
}