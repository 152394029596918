import React,{useContext,useEffect, useState,useRef,createElement } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../components/AuthContext";
import moment from "moment";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';


export default function GaugeCalibrationList({preview=null,status=null}){

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [insertIcon,setInsertIcon] = useState(true);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [searchIconColor,setSearchIconColor] = useState("");

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [printVisible,setPrintVisible] = useState(false);
    const [printValue,setPrintValue] = useState("Zoznam");
    
    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({specific_id: true,inventory_number:true,gauge_name:true,gauge_status:true,gauge_type:true,gauge_description:true,
      gauge_category:true,max_calibration_interval:true,'user.name':true,'calibration_company.name':true,serial_number:true,manufacturer:true,purchase_date:true,state:true,location:true,custom_validator_name:true,
    custom_calibration_date:true,custom_next_calibration_date:true,capability:true,custom_verification_result:true,custom_contractor_name:true,custom_calibration_number_protocol:true,custom_description:true,
    });
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [editFormGauge, setEditFormGauge] = useState([]);

    const ref = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

      function matchAny(data, filterParams) {
        var match = false;
        const regex = RegExp(filterParams.value, 'i');
    
        for (var key in data) {
            if (regex.test(data[key]) === true) {
                match = true;
            }
        }
        return match;
      }

      function searchTable(){
        ref.current.setFilter(matchAny, { value: searchValue});
        setSearchIconColor("active");
        if (searchValue === " ") {
          ref.current.clearFilter()
        }
        setVisibleSearch(false);
      }
    
        const accept = () => {
            let rows_to_delete = [];
            rows_to_delete = ref.current.getSelectedData();
            rows_to_delete.forEach(element => {
              console.log(element);
              deleteRequest(`/api/gauges/${element.id}`)
                  .then((response) => {
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                    getRequest("/api/gauges").then((response) => {
                      dispatch({type:'load_gauges',newGauges:response.data});
                  });
                  }).catch((reason) => {
                      alert(reason);
                      let mytoast = toast.current ? toast : ownToast;
                      mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
                  })
            });
        }
    
        const reject = () => {
            let mytoast = toast.current ? toast : ownToast;
            mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        }
    
        const confirm = () => {
            confirmDialog({
                message: 'Prajete si vymazať tento záznam?',
                header: 'Potvrdenie vymazania',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Áno',
                rejectLabel: 'Nie',
                accept,
                reject
            });
        };
    
      const rowClick = (e, row) => {
        console.log(e, row);
     };
    
      const onSelectRow = (data, rows) => {
        setEditFormGauge(data);
          if(data.length === 0){
            setInsertIcon(true);
            setEditIcon(true);
            setCopyIcon(true);
            setDeleteIcon(true);
          }
          else{
            setInsertIcon(false);
            setEditIcon(false);
            setCopyIcon(false);
            setDeleteIcon(false);
          }
      };

      function CalibrationDate(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.calibration_date ? DateTime.fromISO(rowData.gauge_calibrations[0].calibration_date).toFormat("dd.MM.yyyy") : <span style={{color:'darkred',fontWeight:'bold'}}>Nekalibrované!</span>;
      }

      function calibrationDateFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(DateTime.fromISO(rowData.gauge_calibrations[0].calibration_date).toFormat("dd.MM.yyyy").toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
        else{
          let uncalibrated = "Nekalibrované!";
          if(uncalibrated.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
      }

      function NextCalibrationDate(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.next_calibration_date ? DateTime.fromISO(rowData.gauge_calibrations[0].next_calibration_date).toFormat("dd.MM.yyyy") : <span style={{color:'darkred',fontWeight:'bold'}}>Nekalibrované!</span>;
      }

      function nextCalibrationDateFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(DateTime.fromISO(rowData.gauge_calibrations[0].next_calibration_date).toFormat("dd.MM.yyyy").toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
        else{
          let uncalibrated = "Nekalibrované!";
          if(uncalibrated.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
      }

      function Capability(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData.state=="Vyradené") return "DEA";
        return rowData.gauge_calibrations[0]?.next_calibration_date ? (DateTime.fromISO(rowData.gauge_calibrations[0].next_calibration_date) > DateTime.now() ? "OK" : "NOK" ) : "NOK";
      }

      function capabilityFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.state=="Vyradené"){
          if("DEA".toLowerCase() == headerValue.toLowerCase())return true;
          else return false;
        }
        else if(rowData.gauge_calibrations.length>0){
          if(DateTime.fromISO(rowData.gauge_calibrations[0].next_calibration_date) > DateTime.now()){
            if("OK".toLowerCase() == headerValue.toLowerCase())return true;
            else return false;
          }
          else{
            if("NOK".toLowerCase() == headerValue.toLowerCase())return true;
            else return false;
          }
        }
        else{
          if("NOK".toLowerCase() == headerValue.toLowerCase())return true;
          else return false;
        }
      }

      function VerificationResult(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.verification_result ? rowData.gauge_calibrations[0].verification_result : <span style={{color:'darkred',fontWeight:'bold'}}>Nekalibrované!</span>;
      }

      function verificationResultFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(rowData.gauge_calibrations[0].verification_result.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
        else{
          if("Nekalibrované!".toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
      }

      function Validator(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.validator_id ? rowData.gauge_calibrations[0].validator.name : <span>Interne</span>;
      }
      
      function validatorFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(rowData.gauge_calibrations[0]?.validator_id){
            if(rowData.gauge_calibrations[0]?.validator.name.toLowerCase().includes(headerValue.toLowerCase()))return true;
            else return false;
          }
          else{
            let internally = "Interne";
            if(internally.toLowerCase().includes(headerValue.toLowerCase()))return true;
            else return false;
          }
        }
        else{
          let uncalibrated = "Nekalibrované!";
          if(uncalibrated.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
      }

      function Contractor(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.contractor_id ? rowData.gauge_calibrations[0].contractor.name : <span style={{color:'darkred',fontWeight:'bold'}}>Nekalibrované!</span>;
      }

      function contractorFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(rowData.gauge_calibrations[0]?.contractor.name.toLowerCase().includes(headerValue.toLowerCase()))return true;
            else return false;
        }
        else{
          let uncalibrated = "Nekalibrované!";
          if(uncalibrated.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
      }

      function Description(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.description;
      }

      function descriptionFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(rowData.gauge_calibrations[0]?.description.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
        else{
          if(headerValue!="")return false;
        }
        return true;
      }

      function CalibrationNumberProtocol(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.gauge_calibrations[0]?.calibration_number_protocol;
      }

      function calibrationNumberFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.gauge_calibrations.length>0){
          if(rowData.gauge_calibrations[0]?.calibration_number_protocol.toLowerCase().includes(headerValue.toLowerCase()))return true;
          else return false;
        }
        else{
          if(headerValue!="")return false;
        }
        return true;
      }

      function onEditRow(gauge){
        dispatch({type:'create_new_tab',name:'Upraviť kalibráciu meradla',page:'GaugeCalibrationAdd',props:{gauge: gauge,gauge_calibration: gauge,type:'edit'},mask:'kal005'})
      };
      
      function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
      }

      const [draggingItem,setDraggingItem] = useState(null); 
      const [columns,setColumns] = useState([
        { title: '', field: 'id',formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
        { title: '', field: 'editor',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Poradové číslo', field: 'specific_id',headerFilter:"input",width:200,},
        { title: 'Inventárne číslo', field: 'inventory_number',headerFilter:"input",width:200, },
        { title: 'Názov', field: 'gauge_name',headerFilter:"input",width:200, },
        { title: 'Štatút meradla', field: 'gauge_status',headerFilter:"input",width:200,},
        { title: 'Typ meradla', field: 'gauge_type',headerFilter:"input",width:200,},
        { title: 'Popis meradla', field: 'gauge_description',headerFilter:"input",width:200,},
        { title: 'Kategória meradla', field: 'gauge_category',headerFilter:"input",width:200,},
        { title: 'Max. kal. interval', field: 'max_calibration_interval',headerFilter:"input",width:200,},
        { title: 'Meno používateľa', field: 'user.name',headerFilter:"input",width:200, },
        { title: 'Kalibračná firma', field: 'calibration_company.name',headerFilter:"input",width:200, },
        { title: 'Výrobné číslo', field: 'serial_number',headerFilter:"input",width:200,},
        { title: 'Výrobca', field: 'manufacturer',headerFilter:"input",width:200,},
        { title: 'Dátum nákupu', field: 'purchase_date',headerFilter:"input",width:200,},
        { title: 'Lokalita', field: 'location',headerFilter:"input",width:200},
        { title: 'Stav', field: 'state',headerFilter:"input",width:200},
        { title: 'Kalibroval', field: 'custom_validator_name',headerFilter:"input",headerFilterFunc:validatorFilter,width: 200, formatter: reactFormatter(<Validator/>)},
        { title: 'Dátum kalibrácie', field: 'custom_calibration_date',headerFilter:"input",headerFilterFunc:calibrationDateFilter,formatter: reactFormatter(<CalibrationDate/>),width: 200},
        { title: 'Dátum násl. kalibrácie', field: 'custom_next_calibration_date',headerFilter:"input",headerFilterFunc:nextCalibrationDateFilter,width: 200,formatter: reactFormatter(<NextCalibrationDate/>) },
        { title: 'Spôsobilosť', field: 'capability',headerFilter:"input",headerFilterFunc:capabilityFilter,width: 200,formatter: reactFormatter(<Capability/>) },
        { title: 'Výsledok overenia', field: 'custom_verification_result',headerFilter:"input",headerFilterFunc:verificationResultFilter,width: 200,formatter: reactFormatter(<VerificationResult/>)},
        { title: 'Záznam vykonal', field: 'custom_contractor_name',headerFilter:"input",headerFilterFunc:contractorFilter,width: 200, formatter: reactFormatter(<Contractor/>)},
        { title: 'Kalibračný protokol', field: 'custom_calibration_number_protocol',headerFilter:"input",headerFilterFunc:calibrationNumberFilter,width: 200, formatter: reactFormatter(<CalibrationNumberProtocol/>)},
        { title: 'Popis', field: 'custom_description',headerFilter:"input",headerFilterFunc:descriptionFilter,width: 200,formatter: reactFormatter(<Description/>)},
      ]);

      async function scrollToFirst(){
        let res = state.gauges[0].id;
        ref.current.scrollToRow(res, "top", true);
        setScrollValue(1);
      }
    
      async function scrollUp(){
        let visible_rows = Math.floor((elementRef.current.clientHeight-68)/38-1);
        if((scrollValue-visible_rows+1) >= 0){
          let res = state.gauges[scrollValue-visible_rows+1].id;
          ref.current.scrollToRow(res, "top", true);
          setScrollValue(scrollValue-visible_rows);
        }
        else if(scrollValue > 0 && scrollValue <= visible_rows){
          let res = state.gauges[0].id;
          ref.current.scrollToRow(res, "top", true);
          setScrollValue(1);
        }
      }
    
      async function scrollDown(){
        let visible_rows = Math.floor((elementRef.current.clientHeight-68)/38-1);
        let rows = ref.current.getRows(true).length;
        if(scrollValue===1){
          let res = state.gauges[scrollValue+visible_rows-1].id;
          ref.current.scrollToRow(res, "top", true);
          setScrollValue(scrollValue+visible_rows);
        }
        else if((scrollValue+visible_rows) <= rows){
          let res = state.gauges[scrollValue+visible_rows-1].id;
          let rows = ref.current.getRows(true).length;
          setScrollValue(scrollValue+visible_rows);
          if(res > rows-visible_rows){
            res = state.gauges[rows-visible_rows].id;
            setScrollValue(rows-visible_rows);
          }
          ref.current.scrollToRow(res, "top", true);
        }
      }
    
      async function scrollToLast(){
        let visible_rows = Math.floor((elementRef.current.clientHeight-68)/38-1);
        let rows = ref.current.getRows(true).length;
        let res = state.gauges[rows-visible_rows].id;
        ref.current.scrollToRow(res, "top", true);
        setScrollValue(rows-visible_rows);
      }
      function addNewGaugeCalibration(){
        dispatch({type:'create_new_tab',name:'Nová kalibrácia meradla',page:'GaugeCalibrationAdd',props:{gauge: ref.current.getSelectedData()[0],gauge_calibration: ref.current.getSelectedData()[0],type:'add'},mask:'kal005'})
      }
      function copyGaugeCalibration(){
        dispatch({type:'create_new_tab',name:'Nová kalibrácia meradla',page:'GaugeCalibrationAdd',props:{gauge: ref.current.getSelectedData()[0],gauge_calibration: ref.current.getSelectedData()[0],type:'copy'},mask:'kal005'})
      }
      function editGaugeCalibration(){
        dispatch({type:'create_new_tab',name:'Upraviť kalibráciu meradla',page:'GaugeCalibrationAdd',props:{gauge: ref.current.getSelectedData()[0],gauge_calibration: ref.current.getSelectedData()[0],type:'edit'},mask:'kal005'})
      }

      function confirmPrintDialog(){
        if(printValue=="Zoznam"){
          ref.current.print(false, true)
        }
        else{
          handlePrint();
        }
      }
    
      function handleDragStart(e, item){ 
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', ''); 
      }; 
  
      function handleDragEnd(){ 
        setDraggingItem(null);
      }; 
  
      function handleDragOver(e){ 
          e.preventDefault(); 
      }; 
  
      function handleDrop(e, targetItem){ 
          if (!draggingItem) return; 
  
          const currentIndex = columns.indexOf(draggingItem); 
          const targetIndex = columns.indexOf(targetItem); 
  
          if (currentIndex !== -1 && targetIndex !== -1) { 
            columns.splice(currentIndex, 1); 
            columns.splice(targetIndex, 0, draggingItem); 
             
          } 
          ref.current.setColumns(columns);
      }; 
    
    function setMask(item,value){
      const columnVisible = {...columnsVisible};
     columnVisible[item] = value;
     console.log(columnVisible);
      for(const val in columnVisible) {
        if(columnVisible[val]==false){
          ref.current.hideColumn(`${val}`);
        }
        else{
          ref.current.showColumn(`${val}`);
        }
      }
      setColumnsVisible(columnVisible);
    }
  
    const itemRenderer = (item, options) => (
      <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
          <span className={`${item.icon} text-primary`} />
          <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
          {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
    );
  
    const [expandedKeys, setExpandedKeys] = useState({});
  
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
          setExpandedKeys({});
      }
    };
  
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
              {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("GaugeCalibrationList","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("GaugeCalibrationList","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_4',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];
  
    function showChosenNotifications(){
      let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
      if(db_chosen_notifications?.length>0){
        setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
      }
      else{
        setChosenNotifications([]);
      }
      setNotificationVisible(true);
    }
  
    function saveChosenNotifications(){
        let formData = new FormData();
        formData.append("type","gauge_calibrations");
        formData.append("data", JSON.stringify(chosenNotifications));
        postRequest('/api/chosen_notifications', formData,true)
        .then((response) => {
            if(response.status == 201){
              dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
              let mytoast = toast.current ? toast : ownToast;
              mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
            }else{
              let mytoast = toast.current ? toast : ownToast;
              mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
            }
        }).catch((reason) => {
              console.log(reason);
              alert("Daný úkon sa nepodaril!");
        });
        setNotificationVisible(false);
    }

    function previewFilter(data, filterParams){
      //data - the data for the row being filtered
      //filterParams - params object passed to the filter
      if(data.state=="Vyradené") return false;
      if(filterParams.time_period=="after"){
        return (data.gauge_calibrations.length == 0 && data.gauge_status===filterParams.status) || (data.gauge_calibrations.length > 0 && data.gauge_status===filterParams.status && new Date(data.gauge_calibrations[0].next_calibration_date) < new Date());
      }
      else if(filterParams.time_period=="15"){
        return data.gauge_calibrations.length > 0 && data.gauge_status==filterParams.status && new Date(data.gauge_calibrations[0].next_calibration_date) <= new Date(new Date().setDate(new Date().getDate() + 15)) && new Date(data.gauge_calibrations[0].next_calibration_date) > new Date();
      }
      else if(filterParams.time_period=="30"){
        return data.gauge_calibrations.length > 0 && data.gauge_status==filterParams.status && new Date(data.gauge_calibrations[0].next_calibration_date) <= new Date(new Date().setDate(new Date().getDate() + 30)) && new Date(data.gauge_calibrations[0].next_calibration_date) > new Date();
      }
      else if(filterParams.time_period=="others"){
        return data.gauge_calibrations.length > 0 && data.gauge_status==filterParams.status && new Date(data.gauge_calibrations[0].next_calibration_date) > new Date(new Date().setDate(new Date().getDate() + 30));
      }
      return false;
  }

  function changeMarkings(value){
    if(value){
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=true;
          ref.current.showColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
    else{
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=false;
          ref.current.hideColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
  }
    
    return(
        <>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        {/*<div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewGaugeCalibration()}><AddIcon/></button></div>*/}
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editGaugeCalibration()}><EditIcon/></button></div>
        {/*<div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyGaugeCalibration()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>*/}
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="search"><button onClick={searchIconColor!="active"? () => {setVisibleSearch(true);}:() => {setSearchIconColor("");setSearchValue("");ref.current.clearFilter();}}>{searchIconColor == "" ?  <MdSearch fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="export"><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Zoznam kalibrácií","GaugeCalibrationList")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="fast"><button onClick={() => setFastView("GaugeCalibrationList",columnsVisible,columns)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
    </IntersectionObserverWrapper>
      </div>
      <div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    <div ref={elementRef}>
      <ReactTabulator
      data={state.gauges} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
          if(preview!=null){
            console.log(preview,status);
            ref.current.setFilter(previewFilter, {time_period:preview,status: status});
          }
          let mask = state.user_mask_filters.filter(mask => mask.page=="GaugeCalibrationList");
            if(mask.length>0){
              let attributes = [];
              try {
                attributes = JSON.parse(mask[0].attributes)
              } catch (e) {
                attributes = []
              }
              if(attributes?.length>1)ref.current.setColumnLayout(attributes);
              let filters = [];
              try {
                filters = JSON.parse(mask[0].filters);
              } catch (e) {
                filters = []
              }
              filters.forEach(filter => {
                ref.current.setHeaderFilterValue(filter.field,filter.value);
              });
              let json_columns;
              try {
                json_columns = JSON.parse(mask[0].columns);
                setColumnsVisible(json_columns);
              } catch (e) {
                json_columns = []
              }
              for(let column in json_columns){
                if(json_columns[column]==false)ref.current.hideColumn(column);
                else ref.current.showColumn(column);
              }
            }
        },
        columnMoved:() => {
          setUserMaskFilter("GaugeCalibrationList","column_move",null,null,ref.current.getColumnLayout());
        }
      }}
      options={{
      renderHorizontal:"virtual",movableColumns: true,
      printAsHtml:true,printHeader:"<h1>Zoznam kalibrácií<h1>",printRowRange:"active",
      }}/>
    </div> 
  </div> 
</div> 
</div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
          <Dialog header="Tlačiť ako" visible={printVisible} style={{ width: '40vw' }} onHide={() => setPrintVisible(false)}>
        <div className="flex flex-columns">
          <select 
          value={printValue} onChange={e => setPrintValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option value="Zoznam">Zoznam</option>                      
          </select>
          <button onClick={() => confirmPrintDialog()} className='w-20 ml-8 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>TLAČ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index==0 || index == 1) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
          <button onClick={() => {setUserMaskFilter("GaugeCalibrationList","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
          <InfinitySpin 
          width='208'
          color="#000"
          />
          <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
      </div> 
        </>
    )
}

/*rowFormatter:function(row){
        //row - row component
        var holderEl = document.createElement("div");
            var tableEl = document.createElement("div");

            holderEl.style.boxSizing = "border-box";
            holderEl.style.padding = "10px 30px 10px 10px";
            holderEl.style.borderTop = "1px solid #333";
            holderEl.style.borderBotom = "1px solid #333";
            holderEl.style.background = "#ddd";

            //tableEl.style.border = "1px solid #333";

            holderEl.appendChild(tableEl);

            row.getElement().appendChild(holderEl);

            var subTable = new Tabulator(tableEl, {
                layout: "fitColumns",
                data: row.getData().gauge_calibrations,
                columns: [
                  { title: "", field: "calibration_date" },
                  { title: "", field: "next_calibration_date"},
                  { title: "", field: "verification_result"}
                ]
            })
    },*/