import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { Checkbox } from "primereact/checkbox";
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';
import { PanelMenu } from 'primereact/panelmenu';

export default function PriorityDepartmentAdd({priority_department, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,setFastView,visible}= useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);

    const [formPaintingInterval,setFormPaintingInterval] = useState (priority_department);
    const [formType, setFormType] = useState(type);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("edit otk-section1"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit otk-section1"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteIcon,setFavoriteIcon] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [departments, setDepartments] = useState((priority_department!=null)?priority_department.departments.map((dep) => dep.id):[]);

    const onDepartmentsChange = (e) => {
        let _departments = [...departments];

        if (e.checked)
            _departments.push(e.value);
        else
            _departments.splice(_departments.indexOf(e.value), 1);
            setDepartments(_departments);
    }


    const toast = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    function addNewPriorityDepartment(){
        dispatch({type:'create_new_tab',name:'Nové oddelenie priorít',page:'PriorityDepartmentAdd',props:{priority_department: null,type:'add'}})
    }
    function copyPriorityDepartment(){
        dispatch({type:'create_new_tab',name:'Nové oddelenie priorít',page:'PriorityDepartmentAdd',props:{priority_department: formik.values,type:'copy'}})
    }

    useEffect(() => {
        console.log(priority_department);
    });

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formPaintingInterval==null?{
            name:'',abbreviation:'',
        }:formPaintingInterval,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            formData.append("name", values["name"]);
            formData.append("abbreviation", values["abbreviation"]);
            for (var i = 0; i < departments.length; i++) {
                formData.append("departments[]", departments[i]);
            }
            if(formType=="add"){
                postRequest("/api/priority_departments", formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/priority_departments").then((response) => {
                        dispatch({type:'load_priority_departments',newPriorityDepartments:response.data});
                        dispatch({type:'priority_departments_reload',value:true});
                        dispatch({type:'priority_departments_reload',value:false});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/priority_departments/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/priority_departments").then((response) => {
                        dispatch({type:'load_priority_departments',newPriorityDepartments:response.data});
                        dispatch({type:'priority_departments_reload',value:true});
                        dispatch({type:'priority_departments_reload',value:false});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(painting_interval_id) {
        return state.painting_intervals.findIndex(obj => obj.id === painting_interval_id);
    }

    async function scrollToFirst(){
        let my_painting_interval = state.painting_intervals[0];
        setFormPaintingInterval(my_painting_interval);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormPaintingInterval(state.painting_intervals[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.painting_intervals.length){
            setFormPaintingInterval(state.painting_intervals[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.painting_intervals.length-1;
        let my_painting_interval = state.painting_intervals[last];
        setFormPaintingInterval(my_painting_interval);
    }


    const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-zinc-800 text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
          document.addEventListener('click', handleClickOutside, true);
          return () => {
              document.removeEventListener('click', handleClickOutside, true);
          };
      }, []);
    
        const items = [
        {
          key: '0',
          label: 'Akcie',
          icon: 'pi pi-sliders-v',
          template: itemRenderer,
          items: [
              {
                key: '0_3',
                label: 'Notifikácie',
                icon: 'pi pi-envelope',
                command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
                template: itemRenderer,
            },
          ]
        }
      ];
    
      function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "priority"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","priority");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast; 
                mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast; 
                mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }

    return (

        <div>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewPriorityDepartment()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyPriorityDepartment()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Nové oddelenie priorít","PriorityDepartmentAdd")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("PriorityDepartmentAdd")}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        </IntersectionObserverWrapper>
        </div>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                { !user.permissions.includes("edit painting") && 
                    <div className='w-full h-8 flex justify-center px-2 py-1 mb-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                            <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                        </svg>
                    </div> }
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ODDELENIE PRIORÍT 
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <form className="mt-6" onSubmit={formik.handleSubmit}>
                      
                        <div>
                            <label
                                for="name"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Názov oddelenia
                            </label>
                            <input
                                name='name'
                                type="text"
                                onChange={formik.handleChange} value={formik.values.name}
                                readOnly={!user.permissions.includes("edit otk-section1")} 
                                className={` ${
                                    user.permissions.includes("edit otk-section1") ? "bg-white " : "bg-gray-200 "
                                } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                            />
                        </div>
                        <div>
                            <label
                                for="abbreviation"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Skratka oddelenia
                            </label>
                            <input
                                name='abbreviation'
                                type="text"
                                onChange={formik.handleChange} value={formik.values.abbreviation}
                                readOnly={!user.permissions.includes("edit otk-section1")} 
                                className={` ${
                                    user.permissions.includes("edit otk-section1") ? "bg-white " : "bg-gray-200 "
                                } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                            />
                        </div>
                        <div className='grid grid-cols-2'>
                            {state.departments.map((department) => 
                                <div className="flex align-items-center">
                                    <Checkbox inputId={department.id} name="departments" value={department.id} onChange={onDepartmentsChange} checked={departments.includes(department.id)} />
                                    <label htmlFor={department.id} className="ml-2">{department.name}</label>
                                </div>
                            )}
                        </div>
                        
                        <button type="submit" ref={submitRef} className='hidden'></button>      
                </form>              
            </div>
            </div>
            </div>
            <Toast ref={toast} />
            <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
            <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </div>
            );
} 
