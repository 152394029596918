import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import { Checkbox } from "primereact/checkbox";
import UtilContext from '../../../components/UtilContext';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';
import { PanelMenu } from 'primereact/panelmenu';

export default function PriorityLoadAdd({priority_load, type,load_type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,toast,openFavoriteDialog,setFastView,visible}= useContext(UtilContext);

    const [closeTab,setCloseTab] = useState(false);
    const [formPriorityLoad,setFormPriorityLoad] = useState(priority_load);
    const [formType, setFormType] = useState(type);

    const [insertCopyIcon,setInsertCopyIcon] = useState(!user.permissions.includes("edit priorities"));
    const [scrollIcons,setScrollIcons] = useState(!user.permissions.includes("edit priorities"));
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteIcon,setFavoriteIcon] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    function addNewPriorityLoad(){
        dispatch({type:'create_new_tab',name:'Nové oddelenie priorít',page:'PriorityLoadAdd',props:{priority_load: null,type:'add',load_type: load_type}})
    }
    function copyPriorityLoad(){
        dispatch({type:'create_new_tab',name:'Nové oddelenie priorít',page:'PriorityLoadAdd',props:{priority_load: formik.values,type:'add',load_type: load_type}})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formPriorityLoad==null?{
            name:'',slug:'',column_position:'',sheet_name:''
        }:formPriorityLoad,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in formik.values) {
                formData.append(value, formik.values[value]);
            }
            if(load_type=="automatic"){
                formData.append("priority_type", "automatic");
            }
            else{
                formData.append("priority_type", "manually");
            }
            if(formType=="add"){
                postRequest("/api/priority_loads", formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/priority_loads").then((response) => {
                        dispatch({type:'load_priority_loads',newPriorityLoads:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/priority_loads/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/priority_loads").then((response) => {
                        dispatch({type:'load_priority_loads',newPriorityLoads:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(priority_load_id) {
        if(load_type=="automatic"){
            return state.priority_automatic_loads.findIndex(obj => obj.id === priority_load_id);
        }
        return state.priority_manually_loads.findIndex(obj => obj.id === priority_load_id);
    }   

    async function scrollToFirst(){
        var my_painting_interval;
        if(load_type=="automatic"){
            my_painting_interval = state.priority_automatic_loads[0];
        }
        else{
            my_painting_interval = state.priority_manually_loads[0];
        }
        setFormPriorityLoad(my_painting_interval);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            if(load_type=="automatic"){
                setFormPriorityLoad(state.priority_automatic_loads[id-1]);
            }
            else{
                setFormPriorityLoad(state.priority_manually_loads[id-1]);
            }
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.painting_intervals.length){
            if(load_type=="automatic"){
                setFormPriorityLoad(state.priority_automatic_loads[id+1]);
            }
            else{
                setFormPriorityLoad(state.priority_manually_loads[id+1]);
            }
        }
    }
    
    async function scrollToLast(){
        var last,my_painting_interval;
        if(load_type=="automatic"){
            last = state.priority_automatic_loads.length-1;
            my_painting_interval = state.priority_automatic_loads[last];
        }
        else{
            last = state.priority_manually_loads.length-1;
            my_painting_interval = state.priority_manually_loads[last];
        }
        setFormPriorityLoad(my_painting_interval);
    }

    const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
          document.addEventListener('click', handleClickOutside, true);
          return () => {
              document.removeEventListener('click', handleClickOutside, true);
          };
      }, []);
    
        const items = [
        {
          key: '0',
          label: 'Akcie',
          icon: 'pi pi-sliders-v',
          template: itemRenderer,
          items: [
              {
                key: '0_3',
                label: 'Notifikácie',
                icon: 'pi pi-envelope',
                command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
                template: itemRenderer,
            },
          ]
        }
      ];
    
      function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "priority"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","priority");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast; 
                mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast; 
                mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }

    return (

        <div>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewPriorityLoad()}><AddIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyPriorityLoad()}><CopyIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="up"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="down"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled><FavoriteIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button><PriceIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
            <div className="card flex justify-content-center z-[100] " data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
        </IntersectionObserverWrapper>
        </div>
        <div className="relative flex flex-col justify-center overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÉ NAST. {load_type=="automatic" ? " AUT. " : " MAN. "} PRIORÍT 
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <form className="mt-6" onSubmit={formik.handleSubmit}>
                      
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Názov 
                            </label>
                            <input
                                name='name'
                                type="text"
                                onChange={formik.handleChange} value={formik.values.name}
                                required
                                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="slug"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Prezývka v DB
                            </label>
                            <input
                                name='slug'
                                type="text"
                                onChange={formik.handleChange} value={formik.values.slug}
                                required
                                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="column_position"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Pozícia stĺpca
                            </label>
                            <input
                                name='column_position'
                                type="number"
                                onChange={formik.handleChange} value={formik.values.column_position}
                                required
                                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="sheet_name"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Názov zošita
                            </label>
                            <input
                                name='sheet_name'
                                type="text"
                                onChange={formik.handleChange} value={formik.values.sheet_name}
                                required
                                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <button type="submit" ref={submitRef} className='hidden'></button>      
                </form>              
            </div>
            </div>
            </div>
            <Dialog visible={visibleLoading} style={{ width: '25vw',height:'25vh',backgroundColor: 'transparent',boxShadow: 'none'}} className='mypanel flex flex-row'  closable={false} draggable={false} showHeader={false}>
            <InfinitySpin 
            width='208'
            color="#000"
            />
            <p className='text-2xl font-bold text-center w-52 text-black'>Načítavam...</p>
            </Dialog>
            <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </div>
            );
} 
