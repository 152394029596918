import React,{ createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate,useLocation } from "react-router-dom";
import axiosToken from "./axiosToken";
import { getRequest, checkToken, refreshToken } from "../api/ApiController";
import { UtilProvider } from "./UtilContext";


/* Context, which serves to authenticate the user in the system */
const AuthContext = createContext();
export const PassLogoutProvider = {};

export const AuthContextProvider = ({ children }) => {

  /* Logged in user */
  const [user, setUser] = useState(() => {
    if (localStorage.getItem("tokens")) {
      //let tokens = JSON.parse(localStorage.getItem("tokens"));
      //let decoded_token = jwt_decode(tokens.access_token);
      //console.log(decoded_token.exp * 1000,Date.now());
      //if(decoded_token.exp * 1000 < Date.now() && !refreshTokenPromise){
      refreshToken();
      //return decoded_token["user"];
    }
    return null;
  });

  const [token,setToken] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  /* Processing of user login */
  const login = async (payload) => {
    return await axiosToken.post("/api/auth/login",payload).then((response) => {
        /* Storing json web tokens */
        UtilProvider.toast.current.clear();
        //console.log(response.data);
        if(response?.data?.status>=400){
          if(response.data.message==="User not found"){
            //alert("Používateľ sa nenašiel!");
            UtilProvider.setToastPosition("top-center");
            UtilProvider.toast.current.show({ severity: 'error', summary: 'Prihlásenie', detail: 'Používateľ sa nenašiel!',sticky:true});
          }
        }
        else{
          UtilProvider.setToastPosition("top-right");
          setToken(JSON.stringify(response.data));
          const decoded_token = jwt_decode(response.data.access_token);
          //setUser(decoded_token.user);
          //console.log("USER");
          //console.log(decoded_token.user);
          return decoded_token.user;
        }
    })
    .catch((reason) => {
      //alert(reason.message);
      //console.log("ALERT: "+reason.message)
      return null;
    })
  };

  const asyncLocalStorage = {
    setItem(key, value){
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    removeItem(key){
      return Promise.resolve().then(function () {
          localStorage.removeItem(key);
      });
    }
  };

  const verify = (logged_user) => {
    asyncLocalStorage.setItem("tokens", token).then(function () {
      if(location)navigate(`/openTab/${location.search}`);
      else navigate("/");
      setUser(logged_user);
    });
  };

  /* Processing of user logout */
  const logout = async () => {
    console.log("LOGOUT CALLING");
    /*getRequest("/api/auth/logout").then((response) => {
      console.log(response);
      asyncLocalStorage.removeItem("tokens").then(function () {
        setUser(null);
        navigate("/login");
      });
    }).catch((error) => {
      console.log(error);
      asyncLocalStorage.removeItem("tokens").then(function () {
        setUser(null);
        navigate("/login");
      });
    })*/
    asyncLocalStorage.removeItem("tokens").then(function () {
      setUser(null);
      navigate("/login");
    });
  };

  PassLogoutProvider.logout = logout;
  PassLogoutProvider.user = user;
  PassLogoutProvider.setUser = setUser;

  /* Providing authentication to the children element*/
  return (
    <AuthContext.Provider value={{ user, login,verify, logout ,setUser}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;