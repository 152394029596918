import React, { useState,useContext } from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';

function FavoriteMenu({ items, depth,props }) {

  const [state,dispatch] = useContext(DatabaseContext);
  const [displayChildren, setDisplayChildren] = useState({});

  return (
    <ul className="pt-2 pb-4 space-y-1 text-sm bg-[#333]">
      {items.map((item) => (
          <React.Fragment key={item.id}>
          {item.favorites ?
          <li className="rounded-sm ">
            {item.favorites && (
              <button type="button" className="flex items-center w-full p-2 space-x-3  rounded-md group text-black hover:bg-[#555]" onClick={() => {
                setDisplayChildren({
                  ...displayChildren,
                  [item.name]: !displayChildren[item.name],
                });
              }} onContextMenu={(e) => {props.setFavorite(item);props.hideAll();props.groupFav.current.show(e);}}>
              {/*depth===1 && (item.icon)*/}
              <span className="text-gray-100 flex-1 text-left ml-3 whitespace-nowrap">{item.name}</span>
              <svg sidebar-toggle-item="true" className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
            )}
            {displayChildren[item.name] && item.favorites && <FavoriteMenu items={item.favorites} depth={depth+1} props={props}/>}
          </li> 
          :

          <li onContextMenu={(e) => {props.setFavorite(item);props.hideAll();props.fav.current.show(e);}}>
              <button onDoubleClick={() => dispatch({type:'create_new_tab',name:item.name,page:item.page})}
                  className="flex items-center w-full p-2  text-white transition duration-75 rounded-lg group hover:bg-[#555] pl-11">{item.name}</button>
          </li>
      }</React.Fragment>
      ))}
    </ul>
  );
}
export default FavoriteMenu;