import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest, deleteRequest} from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, DeleteIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, PaperClipIcon, CopyLinkIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';

export default function Reklamácia({error_record,er_type}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible} = useContext(UtilContext);

    const [offenderDepartment, setOffenderDepartment] = useState();
    const [offenders, setOffenders] = useState([]);
    const [recordProcessorDepartment, setRecordProcessorDepartment] = useState();
    const [recordProcessors, setRecordProcessors] = useState([]);
    const [auditorDepartment, setAuditorDepartment] = useState();
    const [auditors, setAuditors] = useState([]);
    const [images,setImages] = useState('');
    const [viewedImage,setViewedImage] = useState('');

    const [formErrorRecord,setFormErrorRecord] = useState(error_record);
    const [errorRecordType, setErrorRecordType] = useState(er_type);
    const [fileList, setFileList] = useState([]);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add otk-section1") || error_record.contractor_id==user.employee.id)?false:true);
    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);

    const [visibleFiles,setVisibleFiles] = useState(false);

    const [closeTab,setCloseTab] = useState(false);

    const submitRef = useRef(null);
    const componentRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

    async function copyLink(){
        await navigator.clipboard.writeText("https://app.imaschellinggroup.eu?tab=Reklamacia&error_record_id="+error_record.id)
        .then(() => {
          let mytoast = toast.current ? toast : ownToast; 
            mytoast.current.show({ severity: 'info', summary: 'Schránka', life: 3000, detail: "Skopírované do schránky!" });
          setTimeout(() => {
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(() => {
        // Update the document title using the browser API
        if(formErrorRecord!=null){
            if(user.permissions.includes("edit otk-section2"))setScrollIcons(false);
            setDepartmentsWithEmployees();
        }
        if(formErrorRecord!=null && errorRecordType=="edit"){
            getImages();
        }
    },[formErrorRecord]);

    function setDepartmentsWithEmployees(){
        if(formErrorRecord.offender_id){
            const offender = state.offenders.filter((offender) => {
                if(offender.employee && offender.employee.offender_id === formErrorRecord.offender_id){
                    let edit_offender = offender;
                    edit_offender.department = offender.employee.department_id;
                    return edit_offender;
                }
                if(offender.supplier && offender.supplier.offender_id === formErrorRecord.offender_id){
                    let edit_offender = offender;
                    edit_offender.department = "Dodávateľ";
                    return edit_offender;
                }
                if(offender.customer && offender.customer.offender_id === formErrorRecord.offender_id){
                    let edit_offender = offender;
                    edit_offender.department = "Zákazník";
                    return edit_offender;
                }
                return false;
            });
            if(offender.length>0)filterOffenders(offender[0].department);
        }
        if(formErrorRecord.record_processor_id){
            const record_processor = state.employees.filter(record_processor => record_processor.id === formErrorRecord.record_processor_id);
            filterRecordProcessors(record_processor[0].department.id);
        }
        if(formErrorRecord.auditor_id){
            const auditor = state.employees.filter(auditor => auditor.id === formErrorRecord.auditor_id);
            filterAuditors(auditor[0].department.id);
        }
    }

    function getImages(){
        getRequest(`/api/error_records/images/${formErrorRecord.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    /* validate form */
    const validate = values => {
        const errors = {};
        if (!values.type) {
            errors.type = '*Povinné';
        }
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formErrorRecord==null?{
            type: 'Reklamácia',article_number:'',article_name:'',order_number:'',customer_id:'',offender_id:'',repair_costs:'25',total_amount:'',
            defective_pieces:'',group_id:'',ack_error:'',claim_counterparty_number:'',error_type_id:'',contractor_note:'',record_processor_id:'',
            problem_description:'',main_reason:'',immediate_measure:'',long_term_measure:'',prevention_id:'',auditor_id:'',auditor_note:'',
            state:'Otvorená',
        }:formErrorRecord,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            if(errorRecordType=="add"){
                formData.append("contractor_id", user?.employee?.id ? user?.employee?.id : values.record_processor_id )
                postRequest('/api/error_records', formData,true)
                .then((response_first) => {
                    setFormErrorRecord(response_first.data);
                    getRequest("/api/error_records").then((response) => {
                        dispatch({type:'load_error_records',newErrorRecords:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(response.data.filter(error_record => error_record.type == 'Reklamácia')[0]);
                        setErrorRecordType("edit");
                        if(fileList.length){
                            getImages();
                            setFileList([]);
                            fileListRef.current.value = null;
                        }
                        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/error_records/update/${formik.values.id}`, formData,true)
                .then((response_first) => {
                    getRequest("/api/error_records").then((response) => {
                        dispatch({type:'load_error_records',newErrorRecords:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        if(fileList.length){
                            getImages(response_first.data.id);
                            setFileList([]);
                            fileListRef.current.value = null;
                        }
                        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    function addNewRekError(){
        dispatch({type:'create_new_tab',name:'NOVÁ REKLAMÁCIA',page:'Reklamacia',mask:'otk007'})
    }

    function copyRekError(){
        dispatch({type:'create_new_tab',name:'NOVÁ REKLAMÁCIA',page:'Reklamacia',props:{error_record: formik.values,er_type:'add'},mask:'otk007'})
    }

    /* filter offender by department in form */
    function filterOffenders(department){
        setOffenderDepartment(department);
        if(department === "Zákazník"){
            setOffenders(state.offenders
            .filter(offender => offender.type === 'customer')
            .map(offender => offender.customer));
        }
        else if(department === "Dodávateľ"){
            setOffenders(state.offenders
                .filter(offender => offender.type === 'supplier')
                .map(offender => offender.supplier));
        }
        else if(department === ""){
            setOffenders([]);
        }
        else{
            setOffenders(state.offenders
                .filter(offender => offender.type === 'employee')
                .filter(offender => offender.employee?.department_id === parseInt(department))
                .map(offender => offender.employee));
        }
    }

    /* filter record processors by department in form */
    function filterRecordProcessors(departmentId){
        setRecordProcessorDepartment(departmentId);
        setRecordProcessors(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    /* filter auditors by department in form */
    function filterAuditors(departmentId){
        setAuditorDepartment(departmentId);
        setAuditors(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    function getIndex(error_record_id) {
        return state.error_records_rek.findIndex(obj => obj.id === error_record_id);
    }

    async function scrollToFirst(){
        let my_error_record = state.error_records_rek[0];
        formik.setValues(my_error_record);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            formik.setValues(state.error_records_rek[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.error_records_rek.length){
            formik.setValues(state.error_records_rek[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.error_records_rek.length-1;
        let my_error_record = state.error_records_rek[last];
        formik.setValues(my_error_record);
    }


    function editIntError(error_record){
        dispatch({type:'create_new_tab',name:'EDIT INT. CHYBA',page:'InternaChyba',props:{error_record: error_record,er_type:'edit'}})
    }

    function editExtError(error_record){
        dispatch({type:'create_new_tab',name:'EDIT EXT. CHYBA',page:'ExternaChyba',props:{error_record: error_record,er_type:'edit'}})
    }

    function showDocumentation(item){
        dispatch({type:'create_new_tab',name:'Dokumentácia - detail',page:'DocumentationDetail',props:{measure: item,name:'DOKUMENTÁCIA',type:'edit',not_last_used: true}})
    }

    function showSelfControl(item){
        dispatch({type:'create_new_tab',name:'Sebakontrola - detail',page:'DocumentationDetail',props:{measure: item,name:'SEBAKONTROLA',type:'edit',not_last_used: true}})
    }

    function showMeasurementProtocol(item){
        dispatch({type:'create_new_tab',name:'Merací protokol - detail',page:'DocumentationDetail',props:{measure: item,name:'MERACÍ PROTOKOL',type:'edit',not_last_used: true}})
    }

    function showNDTProtocol(item){
        dispatch({type:'create_new_tab',name:'NDT protokol - detail',page:'DocumentationDetail',props:{measure: item,name:'NDT PROTOKOL',type:'edit',not_last_used: true}})
    }

    function showAnnealingProtocol(item){
        dispatch({type:'create_new_tab',name:'Žíhací protokol - detail',page:'DocumentationDetail',props:{measure: item,name:'ŽÍHACÍ PROTOKOL',type:'edit',not_last_used: true}})
    }

        const accept = (id) => {
            getRequest(`/api/error_records/deleteImage/${id}`)
                .then((response) => {
                if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
                else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
                  getImages();
                }).catch((reason) => {
                    alert(reason);
                    if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
                    else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
                })
          };
    
          const reject = () => {
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
            else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
          }
  

  function deleteFiles(img){
      confirmDialog({
          message: 'Prajete si vymazať tento súbor?',
          header: 'Potvrdenie vymazania',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Nie',
          rejectLabel: 'Áno',
          accept:reject,
          reject:() => accept(img.id)
      });
  }

    return (
        <div>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewRekError()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyRekError()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => handlePrint()}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy_link"><button onClick={() => copyLink()}><CopyLinkIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Reklamácia","Reklamacia")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("Reklamacia")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><VerticalLineIcon/></div>
          </IntersectionObserverWrapper>
        </div>
        <div className='overflow-y-auto h-[calc(100vh-177px)]'>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col justify-left overflow-x">
          <div className="xl:grid grid-cols-3 gap-1 mb-2 ">
            <div className={`w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md`}>
                { !user.permissions.includes("add otk-section1") && 
                <div className='w-full h-8 flex justify-center px-2 py-1 mb-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                            <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                        </svg>
                    </div> }
                <h1 className="text-2xl font-semibold text-left text-zinc-900">
                   REKLAMÁCIA
                </h1>
                {errorRecordType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.specific_id}</label>}
                <div className="mt-6">
                {/* Prvá sekcia*/}
                    <div className="md:grid grid-cols-2 gap-1 mb-2">
                      <div>
                        <label
                            htmlFor="article_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Artikel
                        </label>
                        <input
                            required
                            name='article_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.article_number}
                            readOnly={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="article_name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov artikla
                        </label>
                        <input
                            name='article_name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.article_name}
                            readOnly={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zákazka
                        </label>
                        <input
                            required
                            name='order_number'
                            type="number"
                            onChange={formik.handleChange} value={formik.values.order_number}
                            readOnly={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="customer_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zákazník
                        </label>
                        <select 
                        name='customer_id'
                        onChange={formik.handleChange} value={formik.values.customer_id}
                        disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber zákazníka</option>
                          {state.customers.map((customer) => (
                            <option key={customer.id} value={customer.id}>{customer.name}</option>
                          ))}                          
                        </select>
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="offenderDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie Vinník
                        </label>
                        <select 
                        required
                        name='offenderDepartment'
                        onChange={(e) => filterOffenders(e.target.value)} value={offenderDepartment}
                        disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                           <option value="">Vyber oddelenie</option>
                           {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                          <option value="Zákazník">Zákazník</option>
                          <option value="Dodávateľ">Dodávateľ</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="offender_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Vinník
                        </label>
                        <select 
                        required
                        name='offender_id'
                        onChange={formik.handleChange} value={formik.values.offender_id}
                        disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                        
                          <option value="">Vyber zamestnanca</option>
                          {offenders.map((offender) => (
                            <option key={offender.offender_id} value={offender.offender_id}>{offender.name}</option>
                          ))}
                        </select>
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="repair_costs"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Náklady
                        </label>
                        <input
                            type="text"
                            name='repair_costs'
                            onChange={formik.handleChange} value={formik.values.repair_costs}
                            readOnly={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="ack_error"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Chyba
                        </label>
                        <select 
                        name='ack_error'
                        onChange={formik.handleChange} value={formik.values.ack_error}
                        disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber možnosť</option>
                          <option value="Uznaná">Uznaná</option>
                          <option value="Neuznaná">Neuznaná</option>
                        </select>
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="total_amount"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Množstvo
                        </label>
                        <input
                            required
                            type="number"
                            name='total_amount'
                            onChange={formik.handleChange} value={formik.values.total_amount}
                            readOnly={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="error_type_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Druh chyby
                        </label>
                        <select 
                        required
                        name='error_type_id'
                        onChange={formik.handleChange} value={formik.values.error_type_id}
                        disabled={!user.permissions.includes("add otk-section1")} 
                        className={` ${
                            user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                           <option value="">Vyber druh chyby</option>
                           {state.error_types.map((error_type) => (
                            <option key={error_type.id} value={error_type.id}>{error_type.name}</option>
                          ))}
                        </select>
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="defective_pieces"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Počet chybných
                        </label>
                        <input
                            required
                            name='defective_pieces'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.defective_pieces}
                            readOnly={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="group_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Skupina
                        </label>
                        <select 
                        required
                        name='group_id'
                        onChange={formik.handleChange} value={formik.values.group_id}
                        disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber skupinu</option>
                          {state.groups.map((group) => (
                            <option key={group.id} value={group.id}>{group.name}</option>
                          ))}
                        </select>
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="claim_counterparty_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Číslo reklamácie protistrany
                        </label>
                        <input
                            name='claim_counterparty_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.claim_counterparty_number}
                            disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        {/*<div>
                        <label
                            htmlFor="contractor_note"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poznámka
                        </label>
                        <textarea
                            name='contractor_note'
                            onChange={formik.handleChange} value={formik.values.contractor_note}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                          </div>*/}
                        <div>
                        <label
                            htmlFor="problem_description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis problému
                        </label>
                        <textarea
                            required
                            name="problem_description" type="text" onChange={formik.handleChange} value={formik.values.problem_description}
                            disabled={!user.permissions.includes("add otk-section1")} 
                            className={` ${
                                user.permissions.includes("add otk-section1") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                </div>           
            </div>
            <div className={`w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md`}>
                { !user.permissions.includes("add otk-section2") && 
            <div className='w-full h-8 flex justify-center px-2 py-1 mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                        <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                    </svg>
                </div> }
                <h1 className="text-xl font-semibold text-left mt-3 text-zinc-900">
                   Spracovanie reklamácie
                </h1>
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="recordProcessorDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select 
                        required
                        name='offenderDepartment'
                        onChange={(e) => filterRecordProcessors(e.target.value)} value={recordProcessorDepartment}
                        disabled={!user.permissions.includes("add otk-section2")} 
                        className={` ${
                            user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="record_processor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zamestnanec
                        </label>
                        <select 
                        required
                        name="record_processor_id"
                        onChange={formik.handleChange} value={formik.values.record_processor_id}
                        disabled={!user.permissions.includes("add otk-section2")} 
                        className={` ${
                            user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber zamestnanca</option>
                          {recordProcessors.map((recordProcessor) => (
                            <option key={recordProcessor.id} value={recordProcessor.id}>{recordProcessor.name}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                        <div>
                        <label
                            htmlFor="main_reason"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Hlavná príčina
                        </label>
                        <textarea
                            name="main_reason" type="text" onChange={formik.handleChange} value={formik.values.main_reason}
                            readOnly={!user.permissions.includes("add otk-section2")} 
                            className={` ${
                                user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="immediate_measure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Okamžité opatrenie
                        </label>
                        <textarea
                            name="immediate_measure" type="text" onChange={formik.handleChange} value={formik.values.immediate_measure}
                            readOnly={!user.permissions.includes("add otk-section2")} 
                            className={` ${
                                user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="long_term_measure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dlhodobé opatrenie
                        </label>
                        <textarea
                            name="long_term_measure" type="text" onChange={formik.handleChange} value={formik.values.long_term_measure}
                            readOnly={!user.permissions.includes("add otk-section2")} 
                            className={` ${
                                user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                            } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="prevention_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zabránenie opakovania chyby skrz
                        </label>
                        <select 
                        name="prevention_id" onChange={formik.handleChange} value={formik.values.prevention_id}
                        disabled={!user.permissions.includes("add otk-section2")} 
                        className={` ${
                            user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber zabránenie</option>
                          {state.preventions.map((prevention) => (
                            <option key={prevention.id} value={prevention.id} >{prevention.name}</option>
                            ))}
                        </select>
                        </div>
                        {errorRecordType == "edit" && 
                        <>
                        <label className='block text-md font-semibold text-gray-800 w-full'>Prílohy:</label>
                        <div className="w-full px-4 py-2 mb-2 ">
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td className=''>Interná chyba:</td>
                                            <td className='flex flex-wrap justify-end'>
                                            {state.error_records_int.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => editIntError(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}
                                                </td>
                                        </tr>
                                    </table>
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td>Externá chyba:</td>
                                            <td className='flex flex-wrap justify-end'>
                                            {state.error_records_ext.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => editExtError(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}</td>
                                        </tr>
                                    </table>
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td>Dokumentácia:</td>
                                            <td className='flex flex-wrap justify-end'>
                                                {state.documentation.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => showDocumentation(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td>Sebakontrola:</td>
                                            <td className='flex flex-wrap justify-end'>
                                            {state.self_control.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => showSelfControl(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td>Merací protokol:</td>
                                            <td className='flex flex-wrap justify-end'>
                                            {state.measurement_protocol.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => showMeasurementProtocol(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td>NDT protokol:</td>
                                            <td className='flex flex-wrap justify-end'>
                                            {state.ndt_protocol.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => showNDTProtocol(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                                    <table className='w-full border border-separate border-gray-400 rounded-md bg-white'>
                                        <tr>
                                            <td>Žihací protokol:</td>
                                            <td className='flex flex-wrap justify-end'>
                                            {state.annealing_protocol.filter((item) => item.order_number == formErrorRecord.order_number)?.map((item) => (
                                                    <button type="button" className='cursor-pointer' onClick={() => showAnnealingProtocol(item)}><PaperClipIcon/>
                                                    </button>
                                                ))}
                                            </td>
                                        </tr>
                                    </table>
                            </div></>}
                </div>           
            </div>
            <div className={`w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md`}>
                { !user.permissions.includes("add otk-section3") && 
            <div className='w-full h-8 flex justify-center px-2 py-1 mb-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                        <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"></path>
                    </svg>
                </div> }
                <h1 className="text-xl font-semibold text-left text-zinc-900">
                Konečná kontrola zavedeného opatrenia skrz OTK
                </h1>
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="auditorDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select 
                        name="auditorDepartment" onChange={(e) => filterAuditors(e.target.value)} value={auditorDepartment}
                        disabled={!user.permissions.includes("add otk-section3")} 
                        className={` ${
                            user.permissions.includes("add otk-section3") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="auditor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zamestnanec
                        </label>
                        <select 
                        name="auditor_id" onChange={formik.handleChange} value={formik.values.auditor_id}
                        disabled={!user.permissions.includes("add otk-section3")} 
                        className={` ${
                            user.permissions.includes("add otk-section3") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber zamestnanca</option>
                          {auditors.map((auditor) => (
                            <option key={auditor.id} value={auditor.id}>{auditor.name}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                        <div>
                        <label
                            htmlFor="auditor_note"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poznámka
                        </label>
                        <textarea
                        name="auditor_note" type="text" onChange={formik.handleChange} value={formik.values.auditor_note}
                        disabled={!user.permissions.includes("add otk-section3")} 
                        className={` ${
                            user.permissions.includes("add otk-section3") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="state"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Stav
                        </label>
                        <select 
                        name="state" onChange={formik.handleChange} value={formik.values.state}
                        disabled={!user.permissions.includes("add otk-section3")} 
                        className={` ${
                            user.permissions.includes("add otk-section3") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="Otvorená">Otvorená</option>
                          <option value="Zatvorená">Zatvorená</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="files"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Súbory:
                        </label>
                          <input
                          accept='image/*,application/pdf,.msg'
                          name='files' 
                          ref={fileListRef}
                          onChange={handleFileChange}
                          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                          type='file'
                          multiple="multiple"
                          id='file'/>
                        </div>
                        <div>
                        <label
                            htmlFor="images"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Galéria:
                        </label>
                        {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                            <tr>
                                <td className='text-black h-10'><div className='flex flex-row p-2'>
                                    <div onClick={() => setVisibleFiles(true)}>
                                        <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                        </div>
                                    </div></td>
                            </tr>
                        </table>))}
                        </div>
                </div>           
            </div>
            </div>
            <div>
            <input
                name='type'
                type="hidden"
                value={formik.values.type}
            />
            <button type="submit" ref={submitRef} className='hidden'></button>
            {/*<button type="submit" className='w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>*/}
            </div>
        </div>
        </form>
        <div ref={componentRef} style={{display:'none'}}>
            <EightDreport error_record={formErrorRecord}/>
        </div>
        <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
        </div>
        </div>
    );
}