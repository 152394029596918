import React, { useState, useMemo } from "react";
import { IconButton, Menu, MenuItem,List,ListItem,ListItemIcon,ListItemText,Collapse } from "@mui/material";

export const OverflowMenuProvider = {};

export default function OverflowMenu({ children, className, visibilityMap }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  OverflowMenuProvider.setVisible = setAnchorEl;

  const shouldShowMenu = useMemo(
    () => Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap]
  );
  if (!shouldShowMenu) {
    return null;
  }
  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="w-5"
      >
        <i className="pi pi-ellipsis-v"></i>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {React.Children.map(children, (child) => {
          if (!visibilityMap[child.props["data-targetid"]]) {
            if(child.props["data-targetid"]=="vert"){
                return null
            }
            if(child.props["data-targetid"]=="actions"){
              /*return (
                <div className="card flex justify-content-center z-[100] bg-zinc-800" data-targetid="actions">
                  <PanelMenu model={items} className="w-full md:w-20rem" />
                </div>
              );*/
              return React.cloneElement(child,child.props);
            }
            return (
              <MenuItem key={child} onClick={handleClose}>
                {React.cloneElement(child,child.props)}
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </div>
  );
}
