import React,{useContext, useEffect, useState} from 'react';
import Navbar from './../components/Navbar';
 import './../assets/css/muistyled.css';
import Menu from './recursive_menu/Menu';
import { menuItems } from './recursive_menu/menuItems';
import TabPanelList from './../components/TabPanelList';
import { useParams } from 'react-router-dom';
import { DatabaseContext } from '../redux/DatabaseStore';
import { useLocation } from 'react-router-dom';
import AuthContext from '../components/AuthContext';
import UtilContext from '../components/UtilContext';




function MainPage() {
  
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [treeData, setTreeData] = useState(menuItems);
  const [searchMode, setSearchMode] = useState(false);
  const [displayChildren, setDisplayChildren] = useState({Priority:false});
  const [state,dispatch] = useContext(DatabaseContext);
  const {visible}= useContext(UtilContext);
  const location = useLocation();

  function expandItems(treeData){
    treeData.map((item) => {
        if(item.children){
            displayChildren[item.id] = true;
            setDisplayChildren(displayChildren);
            console.log("SET "+item.id+" TRUE");
            expandItems(item.children);
        }
    });
    console.log(displayChildren);
  }

  /*useEffect(() => {
    if(location){
        const query = new URLSearchParams(location.search);
        console.log(location.search);
        if(query.get("tab")=="PriorityList"){
            let query_priority_id = query.get("priority_id") ? query.get("priority_id") : null;
            let query_priority_problem_id = query.get("problem_id") ? query.get("problem_id") : null;
            let query_props = { query_priority_id,query_priority_problem_id}
            setTimeout(() => dispatch({type:'create_new_tab',name:'Zoznam Priorít',page:'PriorityList',props:{query_props:query_props}}), 3000);
        }
    }
  },[location]); */

  const onQueryChange = (e) => {
    
    setTreeData(filterBy(menuItems, e.target.value))
    if(e.target.value!=""){
        setSearchMode(true);
        expandItems(treeData);
    }
    else{
        setSearchMode(false);
        setDisplayChildren({});
    }
  }

  function filterBy(arr, query){
    return query ? arr.reduce((acc, item) => {
      if( item.children?.length ){
         const filtered = filterBy(item.children, query)
         if( filtered.length ) return [...acc, {...item, children:filtered}] 
      }
  
      const {children, ...itemWithoutChildren} = item;
      return item.title?.toLowerCase().includes(query.toLowerCase()) ? [...acc, itemWithoutChildren] : acc   
    },[]) : arr
  }

  return (
  <>
  <div className='flex flex-col h-full'>
<Navbar/>
<div className='flex flex-row h-[calc(100%_-_64px)]'>
 <div className='flex flex-col'>
 <div className="flex h-full">
            <div
                className={` ${
                    open ? "w-16" : "w-60 "
                } flex no-scrollbar overflow-y-auto flex-col h-full p-3 bg-[#333] shadow duration-300`}
            >
                <div className="space-y-3">
                    <div className="flex items-center justify-between">
                        {!open && <h2 className="text-xl font-bold text-white">
                           Menu
                        </h2>}
                        <button onClick={() => {setOpen(!open);if(!open)setDisplayChildren({});}}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 6h16M4 12h8m-8 6h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center py-4">
                            <button
                                type="submit"
                                onClick={() => setOpen(false)}
                                className="p-2 focus:outline-none focus:ring"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                        </span>
                        <input
                            type="search"
                            name="Search"
                            onChange={onQueryChange}
                            placeholder="Search..."
                            className={` ${
                                open ? "w-10 " : "w-full pr-4"
                            } py-2 pl-10 text-sm rounded-md focus:outline-none`}
                        />
                    </div>
                    <div className="flex-1">
                        <Menu items={treeData} depth={1} searchMode={searchMode} setDisplayChildren={setDisplayChildren} displayChildren={displayChildren} menuOpen={open} setMenuOpen={setOpen}/>
                    </div>
                </div>
            </div>
            
        </div>
  </div>
  <div id='mainContent' className='w-full [overflow-y:hidden] [overflow-x:hidden]'>
    <TabPanelList/>
  </div>
  </div>
  </div>
    </>
  )

}

export default MainPage;