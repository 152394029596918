import React, { useContext,useState, useEffect, useRef  } from "react";
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DatabaseContext } from "../../redux/DatabaseStore";
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, CalendarIcon,EditRowIcon} from "../svg/svgIcons";
import { deleteRequest, getRequest,postRequest } from '../../api/ApiController';
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../components/AuthContext";
import moment from "moment";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';


export default function PreviewLak() {

  const [state,dispatch] = useContext(DatabaseContext);
  const {user} = useContext(AuthContext);

  const [timePeriod,setTimePeriod] = useState('Všetko');
  const [date,setDate] = useState(null);
  const [dateProps,setDateProps] = useState({hidden:true});

  var notFullFilled = 0;
  var partiallyFullFilled = [];
  var partiallyFullFilledCount = 0;
  var fullFilled = [];
  var fullFilledCount = 0;
  var okFromAll = [];
  var nokFromAll = [];

  const [okRecords,setOkRecords] = useState([]);
  const [nokRecords,setNokRecords] = useState([]);

  const [partiallyRecords,setPartiallyRecords] = useState([]);
  const [fullRecords,setFullRecords] = useState([]);

  const [paintingRecordsData,setPaintingRecordsData] = useState({
    labels: ['OK', 'NOK'],
    datasets: [{
            data: [okFromAll.length,nokFromAll.length],
            backgroundColor: ["#1d4ed8","#b91c1c","#15803d","#ca8a04"],
            hoverBackgroundColor: ["#1d4ed8","#b91c1c","#15803d","#ca8a04"]
        }]
    });

    const [numberOfPaintingRecordsData,setNumberOfPaintingRecordsData] = useState({
        labels: ['Žiaden záznam','Jeden záznam', 'Viac ako jeden záznam'],
        datasets: [{
                data: [notFullFilled,partiallyFullFilledCount,fullFilledCount],
                backgroundColor: ["#b91c1c","#1d4ed8","#15803d","#ca8a04"],
                hoverBackgroundColor: ["#b91c1c","#1d4ed8","#15803d","#ca8a04"]
            }]
    })

  useEffect(()=>{
    count_records();
  }, []);


  addLocale('sk', {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: ['Nedela','Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    dayNamesShort: ['Ne','Pon','Ut','Str','Štv','Pia','So'],
    dayNamesMin: ['Ne','Po', 'Ut', 'Str', 'Štv', 'Pi', 'So'],
    monthNames: ['Január', 'Február', 'Marec', 'Apríl', 'Máj ', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj ', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    today: 'Dnes',
    clear: 'Vymazať'
});


  function getBusinessDatesCount(startDate, endDate) {
    const curDate = startDate;
    notFullFilled = 0;
    partiallyFullFilled = [];
    partiallyFullFilledCount = 0;
    fullFilled = [];
    fullFilledCount = 0;
    okFromAll = [];
    nokFromAll = [];
    let count = 0;
    while (curDate.valueOf() <= endDate.valueOf()) {
        const dayOfWeek = curDate.getDay();
        if(dayOfWeek !== 0 && dayOfWeek !== 6){
            count = count + 1;
            var records = state.painting_conditions.filter(record => new Date(record.created_at).toDateString() == curDate.toDateString());
            if(records.length>=2){
                fullFilled = [...fullFilled,...records];
                fullFilledCount = fullFilledCount + 1;
                okFromAll = [...okFromAll,...records.filter(record => record.state == "OK")];
                nokFromAll = [...nokFromAll,...records.filter(record => record.state == "NOK")];
            }
            else if(records.length==1){
                partiallyFullFilled = [...partiallyFullFilled,...records];
                partiallyFullFilledCount = partiallyFullFilledCount + 1;
                okFromAll = [...okFromAll,...records.filter(record => record.state == "OK")];
                nokFromAll = [...nokFromAll,...records.filter(record => record.state == "NOK")];
            }
            else{
                notFullFilled = notFullFilled + 1;
            }
        }
        curDate.setDate(curDate.getDate() + 1);
    }
    setOkRecords(okFromAll.map(record => record.id));
    setNokRecords(nokFromAll.map(record => record.id));
    setPartiallyRecords(partiallyFullFilled.map(record => record.id));
    setFullRecords(fullFilled.map(record => record.id));
    //setFullFilledRecords(fullFilled);
}

  function count_records(date){

    var startDate = new Date();
    var endDate = new Date();
    if(date==null){
        startDate= new Date(state.painting_conditions[0].created_at);
        endDate = new Date(state.painting_conditions[state.painting_conditions.length-1].created_at);
        //endDate.setDate(endDate.getDate() + 1);
    }
    else if(timePeriod=="Za deň"){
        startDate= new Date(date);
        endDate = new Date(date);
    }
    else if(timePeriod == "Za mesiac"){
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        startDate= new Date(firstDay);
        endDate = new Date(lastDay);
        //endDate.setDate(endDate.getDate() + 1);
    }
    else if(timePeriod == "Za rok"){
        var firstDay = new Date(date.getFullYear(), 0, 1);
        var lastDay = new Date(date.getFullYear(), 11, 31);
        startDate= new Date(firstDay);
        endDate = new Date(lastDay);
        //endDate.setDate(endDate.getDate() + 1);
    }
    else if(timePeriod == "Vlastné"){
        startDate= new Date(date[0]);
        endDate = new Date(date[1]);
        //endDate.setDate(endDate.getDate() + 1);
    }
    else{
        startDate= new Date(state.painting_conditions[0].created_at);
        endDate = new Date(state.painting_conditions[state.painting_conditions.length-1].created_at);
        //endDate.setDate(endDate.getDate() + 1);
    }
    getBusinessDatesCount(startDate,endDate);
    setPaintingRecordsData({
        labels: ['OK', 'NOK'],
        datasets: [{
                data: [okFromAll.length,nokFromAll.length],
                backgroundColor: ["#1d4ed8","#b91c1c","#15803d","#ca8a04"],//"#4CAF50","#F44336","#15803d","#ca8a04"
                hoverBackgroundColor: ["#1d4ed8","#b91c1c","#15803d","#ca8a04"]
            }]
        }); 
    setNumberOfPaintingRecordsData({
        labels: ['Žiaden záznam','Jeden záznam', 'Viac ako jeden záznam'],
        datasets: [{
                data: [notFullFilled,partiallyFullFilledCount,fullFilledCount],
                backgroundColor: ["#b91c1c","#1d4ed8","#15803d","#ca8a04"],//"#9E9E9E","#2196F3","#4CAF50","#ca8a04"
                hoverBackgroundColor: ["#b91c1c","#1d4ed8","#15803d","#ca8a04"]
            }]
    });
  }


    const paintingNotFullFilledOptions = {
        plugins: {
            legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==1){
                dispatch({type:'create_new_tab',name:'Podmienky lakovania',page:'PaintingConditionList',props:{preview: 'count',timePeriod: timePeriod,date: date,records: partiallyRecords}});
            }
            else if(item[0].index==2){
                dispatch({type:'create_new_tab',name:'Podmienky lakovania',page:'PaintingConditionList',props:{preview: 'count',timePeriod: timePeriod,date: date,records: fullRecords}});
            }
        }
    };

    const paintingFullFilledOptions = {
        plugins: {
            legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Podmienky lakovania',page:'PaintingConditionList',props:{preview: 'OK',timePeriod: timePeriod,date: date,records: okRecords}});
            }
            else if(item[0].index==1){
                dispatch({type:'create_new_tab',name:'Podmienky lakovania',page:'PaintingConditionList',props:{preview: 'NOK',timePeriod: timePeriod,date: date,records: nokRecords}});
            }
        }
    };

    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
            let count = 0;
            for (const element of chart.data.datasets[0].data) {
                count = count + element;
            }
            if (count < 1) {
                let ctx = chart.ctx;
                let width = chart.width;
                let height = chart.height;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "26px Arial";
                ctx.color = "red";
                ctx.fillStyle = "darkgrey";
                ctx.fillText("Bez záznamov", width / 2, height / 2);
                ctx.restore();
              }
        }
      };

      function changeTimePeriod(time_period){
        setTimePeriod(time_period);
        setDate(null);
        if(time_period=="Za deň"){
            setDateProps({dateFormat:"dd.mm.yy"});
        }
        else if(time_period=="Za mesiac"){
            setDateProps({view:"month",dateFormat:"mm.yy"});
        }
        else if(time_period=="Za rok"){
            setDateProps({view:"year",dateFormat:"yy"});
        }
        else if(time_period=="Vlastné"){
            setDateProps({dateFormat:"dd.mm.yy",selectionMode:"range"});
        }
        else{
            setDateProps({hidden:true});
            count_records(null);  
        }
    }

    function showResult(new_date){
        setDate(new_date);
        count_records(new_date);
    }


    return (
        <div className="min-h-[calc(100vh-137px)]">
      <div className="lg:grid grid-cols-3 gap-1">
            <div></div>
            <div>
                <label
                    htmlFor="time_period"
                    className="block text-xl font-semibold text-gray-800 mt-4"
                >
                    Obdobie:
                </label>
                <select 
                name='time_period'
                onChange={(e) => changeTimePeriod(e.target.value)} value={timePeriod}
                className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                    <option value="Všetko">Všetko</option>
                    <option value="Za deň">Za deň</option>
                    <option value="Za mesiac">Za mesiac</option>
                    <option value="Za rok">Za rok</option>
                    <option value="Vlastné">Vlastné</option>
                </select>
            </div>
            <div></div>
            <div></div>
            <div className="flex-auto">
                <Calendar id="buttondisplay" style={{border: '1px solid #e5e7eb',transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',borderRadius: '6px'}} 
                value={date} onChange={(e) => showResult(e.target.value)} showIcon className="w-full" visible={true} locale="sk" {...dateProps} hideOnRangeSelection />
            </div>
            <div></div>
        </div>
        <div className="lg:grid grid-cols-[35%_30%_35%] gap-1">
        <div>
            <h1 className="flex flex-col items-center justify-center text-2xl font-semibold mt-8">Podmienky lakovania</h1>
            <Chart type="doughnut" data={paintingRecordsData} options={paintingFullFilledOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]} />
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {paintingRecordsData.datasets[0].data[0] + paintingRecordsData.datasets[0].data[1]}</span>
        </div>
        <div>
        </div>
        <div>
            <h1 className="flex flex-col items-center justify-center text-2xl font-semibold mt-8">Počet záznamov</h1>
            <Chart type="doughnut" data={numberOfPaintingRecordsData} options={paintingNotFullFilledOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]}/>
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {numberOfPaintingRecordsData.datasets[0].data[0] + numberOfPaintingRecordsData.datasets[0].data[1] + numberOfPaintingRecordsData.datasets[0].data[2]}</span>
        </div>
      </div>
</div>
    );
}
