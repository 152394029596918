import React, {createContext, useReducer} from 'react';
import DatabaseReducer from './DatabaseReducer';

//the initial state of the data
export const initialState = {
    departments: [],
    departments_reload:false,
    employees:[],
    employees_reload:false,
    customers:[],
    customers_reload:false,
    control_records:[],
    error_types:[],
    groups:[],
    preventions:[],
    suppliers:[],
    offenders:[],
    priority_departments: [],
    priority_departments_employees: null,
    priority_departments_reload: false,
    priorities: [],
    priorities_reload:false,
    error_records:[],
    error_records_int:[],
    error_records_ext:[],
    error_records_rek:[],
    error_records_reload:false,
    documentation:[],
    self_control:[],
    measurement_protocol:[],
    ndt_protocol:[],
    annealing_protocol:[],
    measure_complexities:[],
    users:[],
    users_reload:false,
    roles:[],
    roles_reload:false,
    tabs:[{value: `1`,label: "HomePage",mask:'home0001'},{value: `2`,label: "ProfilePage",mask:'profile0001'}],
    panels:[{value: `1`,child: "HomePage"},{value: `2`,child: "ProfilePage"}],
    tabIndex: 3,
    selectedTab: '1',
    selectedMask: 'home0001',
    favorites: [],
    fast_view: [],
    user_mask_filters: [],
    last_used: [],
    user:null,
    painting_intervals:[],
    painting_intervals_reload:false,
    painting_conditions:[],
    painting_conditions_reload:false,
    painting_time_conditions:[],
    painting_employee_conditions:[],
    weather:[],
    gauges:[],
    gauges_reload:false,
    gauge_calibrations:[],
    gauge_calibrations_reload:false,
    calibration_companies:[],
    calibration_companies_reload:false,
    calibration_company_certificates:[],
    calibration_company_certificates_reload:false,
    notifications:[],
    chosen_notifications:null,
    summary_emails_otk:[],
    summary_emails_painting:[],
    summary_emails_calibration:[],
    support_tickets:[],
    training_companies:[],
    training_company_contacts:[],
    training_types:[],
    training_cards:[],
    training_employees:[],
    training_templates:[],
    training_company_certificates:[],
    training_invitation_templates:[],
    training_invitations:[],
}
    
const DatabaseStore=({children})=>{

    const [state,dispatch] = useReducer(DatabaseReducer,initialState);   

    return (
        /* providing global state to all children */
        <DatabaseContext.Provider value={[state,dispatch]}>
            {children}
        </DatabaseContext.Provider>
    );
}

export const DatabaseContext = createContext(initialState);

export default DatabaseStore;