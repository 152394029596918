import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { FormikConsumer, useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon, InfoIcon, ErrorIcon, TaskIcon, PermissionIcon, NeedIcon, EmergencyIcon,EditRowIcon, BellSlashIcon, DeleteIcon, OutlookMsgIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../../assets/css/support.css'
import { Editor } from 'primereact/editor';
import { ReactTabulator ,reactFormatter} from 'react-tabulator';
import { Avatar } from 'primereact/avatar';
import { DateTime } from "luxon";
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tag } from 'primereact/tag';
import { Select } from "antd";
import MyEditor from '../../components/Editor';
import { Skeleton } from 'primereact/skeleton';
import MsgReader from '@kenjiuno/msgreader';


export default function SupportTicketList({ticket_id=null}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,getUserColor,getUserInitials,setDialogText,setVisible}= useContext(UtilContext);


    const [showTable,setShowTable] = useState(true);
    const [showTicket,setShowTicket] = useState(false);
    const [showDetail,setShowDetail] = useState(false);
    const [showAddUser,setShowAddUser] = useState(false);

    const [images,setImages] = useState(null);
    const [viewedImage,setViewedImage] = useState('');
    const [visibleFiles,setVisibleFiles] = useState(false);

    const [addedUser,setAddedUser] = useState("");

    const [commentClick,setCommentClick] = useState(false);

    const [updatableComment,setUpdatableComment] = useState(0);

    const [text, setText] = useState('');

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const op = useRef(null);
    const submitRef = useRef(null);

    const [formSupportTicket,setFormSupportTicket] = useState();
    const [formType, setFormType] = useState();
    const [searchValue,setSearchValue] = useState("");
    const [statusValue,setStatusValue] = useState("Otvorený");

    const [preview,setPreview] = useState(<></>);

    var findedIds = [];

    function getImages(id=null){
        getRequest(`/api/support_tickets/images/${id ? id : formSupportTicket.id}`).then((response) => {
            //console.log(response);
            if(response.data!=="{}")setImages(response.data);
            else setImages([]);
        });
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    function loopThroughDOM(obj) {
        if(Array.isArray(obj)){
            for(let i=0;i<obj.length;i++){
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("children")){
                    loopThroughDOM(obj[i]["props"]["children"]);
                }
                if(obj[i].hasOwnProperty("props") && obj[i].props.hasOwnProperty("data-value")){
                    findedIds.push(obj[i]["props"]["data-id"]);
                }
            }
        }
        else{
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("children") && obj.props.children!=undefined){
                loopThroughDOM(obj["props"]["children"]);
            }
            if(obj.hasOwnProperty("props") && obj.props.hasOwnProperty("data-value")){
                findedIds.push(obj["props"]["data-id"]);
            }
        }
    }
    

    const formik = useFormik({
        initialValues: {
            description:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            setDialogText("Ukladám...");
            setVisible(true);
            let formData = new FormData();
            for (let value in values) {
                if(value=="files"){
                    for(let i = 0;i<values[value].length;i++){
                        formData.append('files[]',values[value][i]);
                    }
                }
                else formData.append(value, values[value]);
            }
            formData.append('contractor_id',user.id);
            formData.append('ticket_id',formSupportTicket.id);
            let mentions = parse(values.description || "");
            loopThroughDOM(mentions);
            formData.append('mentions', findedIds.filter(onlyUnique));
            postRequest('/api/support_comments', formData,true)
            .then((response) => {
                setFormSupportTicket(response.data);
                getImages(response.data.id);
                getRequest("/api/support_tickets").then((response) => {
                    dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    setCommentClick(false);
                    findedIds = [];
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                setVisible(false);
            }).catch((reason) => {
                setVisible(false);
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
      },
    });

    const formikUpdate = useFormik({
        initialValues: {
            description:''
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
        setDialogText("Ukladám...");
        setVisible(true);
          let formData = new FormData();
          for (let value in values) {
            if(value=="files"){
                for(let i = 0;i<values[value].length;i++){
                    formData.append('files[]',values[value][i]);
                }
            }
            else formData.append(value, values[value]);
          }
          let mentions = parse(values.description || "");
          loopThroughDOM(mentions);
          formData.append('mentions', findedIds.filter(onlyUnique));
          postRequest(`/api/support_comments/update/${formikUpdate.values.id}`, formData,true)
          .then((response) => {
              setFormSupportTicket(response.data);
              getImages(response.data.id);
              getRequest("/api/support_tickets").then((response) => {
                  dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                  if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                  formikUpdate.resetForm();
                  setUpdatableComment(false);
                  findedIds = [];
                  toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Komentár upravený!" });
              });
              setVisible(false);
          }).catch((reason) => {
              setVisible(false);
              if(!navigator.online){
                  alert("Ste offline, dáta budu odoslané po znovupripojení!");
              }
              else{
                  alert("Daný úkon sa nepodaril!");
              }
          });
      },
    });

    useEffect(() => {
        if(ticket_id != null){
            console.log(ticket_id);
            let ticket = state.support_tickets.filter((ticket) => ticket.id == ticket_id);
            if(ticket.length>0){
                console.log(ticket[0]);
                setFormSupportTicket(ticket[0]);
                setShowTable(false);
                setShowTicket(true);
                getImages(ticket[0].id);
                ticket_id=null;
            }
        }
        
    }, [ticket_id]);

    const rowClick = (e, row) => {
        console.log(e, row);
     };

     function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' 
        onClick={() => {setFormSupportTicket(rowData);setShowTable(false);setShowTicket(true);getImages(rowData.id);}}
        ><EditRowIcon/></button></div>;
    }

    function TypeFormatter(props) {
        const rowData = props.cell.getRow().getData();
        switch(rowData.type){
            case 'Info': {return <InfoIcon width={6} height={6}/>;}
            case 'Error': {return <ErrorIcon  width={6} height={6}/>;}
            case 'Task': {return <TaskIcon  width={6} height={6}/>;}
            case 'Permission': {return <PermissionIcon  width={6} height={6}/>;}
            case 'Need': {return <NeedIcon  width={6} height={6}/>;}
            case 'Emergency': {return <EmergencyIcon  width={6} height={6}/>;}
            default: {return <InfoIcon  width={6} height={6}/>;}
        }
    }

    function PriorityFormatter(props) {
        const rowData = props.cell.getRow().getData();
        switch(rowData.priority){
            case 'low': {return "Nízka";}
            case 'normal': {return "Normálna";}
            case 'high': {return "Vysoká";}
            case 'blocker': {return "Bloker";}
            default: {return "Normálna";}
        }
    }

    function ContractorFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.contractor?.employee?.name || rowData.contractor?.username;
    }

    function DeleteFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'>
            <button type="button" className='w-fit flex flex-row px-1 py-1 border rounded-md bg-red-900' 
            onClick={() => {deleteTicket(rowData.id)}} ><DeleteIcon/></button></div>;
    }

    const [columns,setColumns] = useState( user.role=="admin" ? [
        { title: '', field: 'editor',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Typ', field: 'type',formatter: reactFormatter(<TypeFormatter/>),width:100,resizable:false},
        { title: 'Číslo tiketu', field: 'defined_id',width:150,resizable:false},
        { title: 'Priorita', field: 'priority',width:130,formatter: reactFormatter(<PriorityFormatter/>),resizable:false},
        { title: 'Popis', field: 'theme',width: 200,resizable:false},
        { title: 'Status', field: 'status',resizable:false},
        { title: 'Zhotoviteľ tiketu', field: 'custom_contractor',formatter: reactFormatter(<ContractorFormatter/>),resizable:false },
        { title: 'Maska', field: 'mask_number',resizable:false},
        { title: '', field: 'custom_delete',formatter: reactFormatter(<DeleteFormatter/>),resizable:false },
        {field: 'contractor_id',visible: false},
      ] :[
      { title: '', field: 'editor',hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
      { title: 'Typ', field: 'type',formatter: reactFormatter(<TypeFormatter/>),width:100,resizable:false},
      { title: 'Číslo tiketu', field: 'defined_id',width:150,resizable:false},
      { title: 'Priorita', field: 'priority',width:130,formatter: reactFormatter(<PriorityFormatter/>),resizable:false},
      { title: 'Popis', field: 'theme',width: 200,resizable:false},
      { title: 'Status', field: 'status',resizable:false},
      { title: 'Zhotoviteľ tiketu', field: 'custom_contractor',formatter: reactFormatter(<ContractorFormatter/>),resizable:false },
      { title: 'Maska', field: 'mask_number',resizable:false},
      {field: 'contractor_id',visible: false},
    ]);

    function renderTicketType(type) {
        switch(type) {
        case 'Info':
            return <div className='flex gap-2 text-gray-800 items-center'><InfoIcon width={8} height={8}/><span>Otázka</span></div>;
        case 'Error':
            return <div className='flex gap-2 text-gray-800 items-center'><ErrorIcon width={8} height={8}/><span>Chyba</span></div>;
        case 'Task':
            return <div className='flex gap-2 text-gray-800 items-center'><TaskIcon width={8} height={8}/><span>Úloha</span></div>;
        case 'Permission':
            return <div className='flex gap-2 text-gray-800 items-center'><PermissionIcon width={8} height={8}/><span>Povolenie</span></div>;
        case 'Need':
            return <div className='flex gap-2 text-gray-800 items-center'><NeedIcon width={8} height={8}/><span>Potreba</span></div>;
        case 'Emergency':
            return <div className='flex gap-2 text-gray-800 items-center'><EmergencyIcon width={8} height={8}/><span>Núdzová správa</span></div>;
        default:
            return <div className='flex gap-2 text-gray-800 items-center'><InfoIcon width={8} height={8}/><span>Otázka</span></div>;
        }
    }

    function renderTicketStatus(type) {
        switch(type) {
        case 'Vytvorený':
            return <Tag className='bg-gray-400 '>
                        <span className="text-base font-normal">{formSupportTicket.status}</span>
                    </Tag>;
        case 'V riešení':
            return <Tag className='bg-yellow-400'>
                        <span className="text-base font-normal">{formSupportTicket.status}</span>
                    </Tag>;
        case 'Čaká sa':
            return <Tag className='bg-blue-400'>
                        <span className="text-base font-normal">{formSupportTicket.status}</span>
                    </Tag>;
        case 'Vyriešené':
            return <Tag className='bg-green-400'>
                        <span className="text-base font-normal">{formSupportTicket.status}</span>
                    </Tag>;
        default:
            return <Tag className='bg-gray-400 '>
                        <span className="text-base font-normal">{formSupportTicket.status}</span>
                    </Tag>;
        }
    }

    const fetchMsgFile = async (url) => {
        const response = await fetch(url);
  
        if (!response.ok) {
            
            //throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
  
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;    
    };

    async function renderFile(file){
        if(!file)setPreview("");
        //console.log(file.filename.split('.').pop());
        switch(file.filename.split('.').pop()){
            case 'img':
                setPreview(<img src={file.url} className='object-contain h-[75vh] m-auto'></img>);
                break;
            case 'msg':
                // Create a new MsgReader instance with the ArrayBuffer
                const buffer = await fetchMsgFile(file.url);
                const msgReader = new MsgReader(buffer);
                //console.log(msgReader);
                let msg_data = msgReader.getFileData();
                //console.log(msg_data);
                setPreview(<div className='flex flex-col w-full text-balance'>
                    <div className='mb-2'>
                        <label className='font-semibold mr-1'>Dátum:</label>
                        <span>{DateTime.fromHTTP(msg_data.messageDeliveryTime).toFormat("dd.MM.yyyy HH:mm:ss")}</span>
                    </div>
                    <div className='mb-2 '>
                        <label className='font-semibold mr-1'>Prijímatelia:</label>
                        <div>
                            {msg_data.recipients.map((recipient) => {
                                return <>
                                    <div>{recipient.name}</div>
                                    <div>{recipient.smtpAddress}</div>
                                </>
                            })}
                        </div>
                    </div>
                    <div className='mb-2'>
                        <label className='font-semibold mr-1'>Subjekt:</label>
                        <div>{msg_data.subject}</div>
                    </div>
                    <div className='mb-2'>
                        <label className='font-semibold mr-1'>Telo správy:</label>
                        <div>{msg_data.body}</div>
                    </div>
                    <div className='mb-2' >
                        <label className='font-semibold mr-1'>Prílohy:</label>
                        <div>{msg_data.body}</div>
                    </div>
                </div>);
                break;
            default:
                setPreview(<img src={file.url} className='object-contain h-[75vh] m-auto'></img>);
        }
    }

    function matchAny(data, filterParams) {
        var match = false;
        const regex = RegExp(filterParams.value, 'i');
    
        for (var key in data) {
            if (regex.test(data[key]) === true) {
                match = true;
            }
        }
        return match;
      }
    
      function searchTable(value){
        if(searchValue!=""){
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("function matchAny")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
        }
        if(value!=""){
            ref.current.addFilter(matchAny, {value: value});
        }
        setSearchValue(value);
      }

      function checkStatus(value){
        if(value=="Otvorené"){
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("status")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
            ref.current.addFilter("status","in",["Vytvorený","V riešení","Čaká sa"]);
        }
        else{
            var filters = ref.current.getFilters();
            for(let i=0;i<filters.length;i++){
                if(String(filters[i]["field"]).startsWith("status")){
                    ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
                }
            }
            ref.current.addFilter("status","in",["Vyriešený"]);
        }
        setStatusValue(value);
      }

      function checkContractor(value){
        var filters = ref.current.getFilters();
        for(let i=0;i<filters.length;i++){
            if(String(filters[i]["field"]).startsWith("contractor_id")){
                ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
            }
        }
        if(value=="Mnou vytvorené"){
            ref.current.addFilter("contractor_id","=",user.id);
        }
        else if(value=="Spomenutý"){
            ref.current.addFilter("contractor_id","!=",user.id);
        }
        setStatusValue(value);
      }

      function checkType(value){
        var filters = ref.current.getFilters();
        for(let i=0;i<filters.length;i++){
            if(String(filters[i]["field"]).startsWith("type")){
                ref.current.removeFilter(filters[i]["field"],filters[i]["type"],filters[i]["value"]);
            }
        }
        if(value!="All"){
            ref.current.addFilter("type","=",value);
        }
        setStatusValue(value);
      }

      function checkNotifiation(){
        return formSupportTicket.mentions?.filter((mentioner) => mentioner.id==user.id)[0]?.pivot?.notification;
      }

    function changeStatus(value){
        let formData = new FormData();
        formData.append('status', value);
        postRequest(`/api/support_tickets/updateStatus/${formSupportTicket.id}`, formData,true)
        .then((response) => {
            setFormSupportTicket(response.data);
            getRequest("/api/support_tickets").then((response) => {
                dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Status upravený!"});
            });
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
        });
    }

    function changeNotification(){
        let formData = new FormData();
        let value = Number(!checkNotifiation());
        formData.append('value', value);
        postRequest(`/api/support_tickets/updateNotification/${formSupportTicket.id}`, formData,true)
        .then((response) => {
            setFormSupportTicket(response.data);
            getRequest("/api/support_tickets").then((response) => {
                dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: value ? "Notifikácie nastavené" : "Notifikácie zrušené"});
            });
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
        });
    }

    function addUser(){
        let formData = new FormData();
        formData.append('mention', addedUser);
        postRequest(`/api/support_tickets/addUser/${formSupportTicket.id}`, formData,true)
        .then((response) => {
            setFormSupportTicket(response.data);
            setShowAddUser(false);
            getRequest("/api/support_tickets").then((response) => {
                dispatch({type:'load_support_tickets',newSupportTickets:response.data});
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ pridaný"});
            });
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
        });
    }

    const accept = (id) => {
        getRequest(`/api/support_tickets/deleteImage/${id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
            getImages();
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
        })
    };

    const reject = () => {
        if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }
  

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(img.id)
        });
    }

    const acceptTicket = (id) => {
        deleteRequest(`/api/support_tickets/${id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Tiket bol úspešne vymazaný!" });
            getRequest("/api/support_tickets").then((response) => {
                dispatch({type:'load_support_tickets',newSupportTickets:response.data});
            });
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Tiket sa nepodarilo vymazať!', life: 3000 });
        })
    };
  

    function deleteTicket(id){
        confirmDialog({
            message: 'Prajete si vymazať tento tiket?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => acceptTicket(id)
        });
    }

    return (
        <>
            <div className='relative flex flex-col bg-gray-100 w-full h-[calc(100vh_-_137px)] py-4 px-4 overflow-y-auto'>
                {showTable && <div className='w-auto bg-white rounded-md shadow-md'>
                    <div>
                        <h2 className='text-left font-semibold text-blue-800 mb-2 p-2'>Schelling App - Podpora</h2>
                        <h1 className='text-2xl font-bold text-left text-black p-2'>Tikety</h1>
                    </div>
                    <div className='flex flex-row mb-2'>
                    <div className='relative flex flex-row'>
                        <span className="absolute inset-y-0 left-0 flex items-center">
                            <button
                                className="p-2 focus:outline-none focus:ring"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                    />
                                </svg>
                            </button>
                        </span>
                        <input
                            type="search"
                            name="Search"
                            placeholder="Hľadať..."
                            onChange={(e) => searchTable(e.target.value)}
                            className={"w-60 pr-4 py-2 ml-1 pl-10 border text-sm rounded-md focus:outline-none"}
                        />
                        </div>
                        <label className='font-semibold py-2 px-2'>Status:</label>
                        <select className='w-fit bg-[#333] text-white px-2 py-1 rounded-md mr-2' onChange={(e) => checkStatus(e.target.value)}>
                            <option value="Otvorené">Otvorené tikety</option>
                            <option value="Zatvorené">Zatvorené tikety</option>
                        </select>
                        <select className='w-fit bg-[#333] text-white px-2 py-1 rounded-md mr-2' onChange={(e) => checkContractor(e.target.value)}>
                            <option value="Všetky">Všetky tikety</option>
                            <option value="Mnou vytvorené">Mnou otvorené tikety</option>
                            <option value="Spomenutý">Tikety, kde som spomenutý</option>
                        </select>
                        <label className='font-semibold py-2 px-2'>Typ tiketu:</label>
                        <select className='w-fit bg-[#333] text-white px-2 py-1 rounded-md mr-2' onChange={(e) => checkType(e.target.value)}>
                            <option value="All">Všetky</option>
                            <option value="Info">Informatívna otázka</option>
                            <option value="Error">Chyba</option>
                            <option value="Task">Úloha</option>
                            <option value="Permission">Povolenie</option>
                            <option value="Need">Nákup</option>
                            <option value="Emergency">Núdza</option>
                        </select>
                    </div>
                    <div>
                        <ReactTabulator
                        data={state.support_tickets} 
                        className='h-[calc(100vh_-_313px)]' //176px
                        onRef={(r) => (ref.current = r.current)}
                        columns={columns}
                        events={{
                            rowClick: rowClick,
                        }}
                        options={{
                            renderHorizontal:"virtual",movableColumns: true,rowHeight:30,
                          printAsHtml:true,printHeader:"<h1>Zoznam tiketov<h1>",printRowRange:"active",
                          initialFilter:[
                            {field:"status", type:"in", value:["Vytvorený","V riešení","Čaká sa"]},
                          ],
                          }}
                        />
                    </div>
                </div>}
                {showTicket && <div className='w-auto bg-white rounded-md shadow-md px-10 py-6'>
                    <div className='flex font-bold text-sm text-blue-800'><button className='mr-4' onClick={() => {setShowTicket(false);setShowTable(true);setFormSupportTicket(null);setImages(null);setCommentClick(false);}}><i title='Zoznam žiadostí' className="pi pi-arrow-left" style={{ fontSize: '1.25rem', marginRight:5 }}></i></button>ISG-SK-Support / {formSupportTicket.defined_id}</div>
                    <div className='text-2xl font-light text-black py-6'>{formSupportTicket.theme}</div>
                    <div className='flex flex-row gap-11'>
                        <div className='basis-2/3'>
                          <div className='flex flex-row justify-between items-center w-full p-4 border border-zinc-600 rounded-sm mb-2'>
                            <div><Avatar className='p-overlay-badge' title={formSupportTicket.contractor?.employee?.name || formSupportTicket.contractor?.username} label={getUserInitials(formSupportTicket.contractor?.employee?.name || formSupportTicket.contractor?.username)} shape="circle" style={{ backgroundColor: getUserColor(formSupportTicket.contractor.username), color: '#ffffff',marginRight: '5px'}}/>
                            {formSupportTicket.contractor?.employee?.name || formSupportTicket.contractor?.username} založil tiket {DateTime.fromISO(formSupportTicket.created_at).toFormat("dd.MM.yyyy")} o {DateTime.fromISO(formSupportTicket.created_at).toFormat("HH:mm")}</div>
                             <button onClick={() => setShowDetail(true)} className='font-semibold'>Detail</button>
                          </div>
                          {images==null && <Skeleton height="4rem" className="mb-2"></Skeleton>}
                          {images?.length>0 && <div>
                            <h2 className='text-xl'>Prílohy</h2>
                            <div className='flex flex-row flex-wrap w-full h-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                            {images.map((img) => {
                                let extension = img.filename.split('.').pop();
                                if(extension=="msg"){
                                    return <>
                                    <div className='text-black h-auto w-52'><div className='flex flex-row p-2'>
                                        <div onClick={() => {setViewedImage(img);renderFile(img);setVisibleFiles(true);}}>
                                            <OutlookMsgIcon/>
                                        </div>
                                            <div className='flex flex-col ml-4'>
                                            <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {renderFile(img);setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                            {user.role=="admin" && <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/></button>}
                                            </div>
                                        </div>
                                    </div></>;
                                }                                  
                                return <>
                                <div className='text-black h-auto w-52'><div className='flex flex-row p-2'>
                                    <div onClick={() => {setViewedImage(img);renderFile(img);setVisibleFiles(true);}}>
                                        <embed src={img.url+'#page=1&view=FitH&scrollbar=0'} width='100px' height='auto'></embed>
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {renderFile(img);setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        {user.role=="admin" && <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/></button>}
                                        </div>
                                    </div>
                                </div></>;
                            })}
                        </div>
                          </div>}
                          <div>
                            <h1 className='text-xl font-semibold text-black mb-4'>Aktivita</h1>
                            {formSupportTicket.comments.map((comment, index, array) => 
                            <div className='flex flex-row w-full justify-between mb-3'>
                                <div className='basis-1/10'>
                                    <Avatar className='p-overlay-badge' title={comment.contractor?.employee?.name || comment.contractor?.username} label={getUserInitials(comment.contractor?.employee?.name || comment.contractor?.username)} shape="circle" style={{ backgroundColor: getUserColor(comment.contractor.username), color: '#ffffff',marginRight: '5px'}}/>
                                </div>
                                <div className='flex flex-col grow basis-8/10'>
                                    <div className='mb-1'><span className='font-semibold'>{comment.contractor?.employee?.name || comment.contractor?.username}</span> {DateTime.fromISO(comment.updated_at).toFormat("dd.MM.yyyy")} o {DateTime.fromISO(comment.updated_at).toFormat("HH:mm")}</div>
                                    <div>{(array.length - 1 === index && updatableComment) ? 
                                        <form onSubmit={formikUpdate.handleSubmit}>
                                        <MyEditor editorValue={formikUpdate.description} autofocus={true} formik={formikUpdate}/>
                                        <div className='flex flex-row gap-1'>
                                        <button typye="button" onClick={() => setUpdatableComment(false)} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-gray-600 rounded-md hover:bg-gray-400 focus:outline-none disabled:opacity-25'>Zrušiť</button>
                                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Upraviť</button>
                                        </div>
                                    </form> : <MyEditor editorValue={comment.description} hideToolbar={true} hideFileUpload={true} formik={null}/>}{/*parse(comment.description || "")*/}
                                    </div>
                                </div>
                                {(array.length - 1 === index && comment.contractor_id == user.id && formSupportTicket.status!="Vyriešený") && 
                                <div className='flex basis-1/10'>
                                    {!updatableComment && <button  onClick={(e) => op.current.toggle(e)}><i className='pi pi-ellipsis-v'></i></button>}
                                    <OverlayPanel ref={op}>
                                        <div className='flex flex-col'>
                                            <div className='flex flex-row items-center hover:bg-gray-200'>
                                                <button className='px-4 py-3' onClick={(e) => {formikUpdate.setFieldValue('id',comment.id);formikUpdate.setFieldValue('description',comment.description);setUpdatableComment(true);op.current.toggle();}}><i className='pi pi-pencil mr-2'></i><span className='font-semibold'>Upraviť</span></button>
                                            </div>
                                            {/*<div className='flex flex-row items-center p-4 pt-2 hover:bg-gray-200'>
                                                <button><i className='pi pi-trash mr-2'></i><span className='font-semibold'>Vymazať</span></button>
                                            </div>*/}
                                        </div>
                                    </OverlayPanel>
                                </div>}
                            </div>)}
                            {formSupportTicket.status!="Vyriešený" && <div className='flex flex-row mt-3'>
                                <div className='basis-1/10'>
                                    <Avatar className='p-overlay-badge' title={user?.employee?.name || user?.username} label={getUserInitials(user?.employee?.name || user?.username)} shape="circle" style={{ backgroundColor: getUserColor(user?.username), color: '#ffffff',marginRight: '5px'}}/>
                                </div>
                                {!commentClick ? <div className='flex flex-col basis-9/10'>
                                    <input 
                                    type='text' onClick={() => {setCommentClick(true);}}
                                    placeholder='Pridať komentár...'
                                    className='px-1 py-1 border focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                                    />
                                </div>:
                                <form onSubmit={formik.handleSubmit}>
                                    <MyEditor editorValue={formik.values.description} autofocus={true} formik={formik} />
                                    <div className='flex flex-row gap-1'>
                                        <button typye="button" onClick={() => setCommentClick(false)} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-gray-600 rounded-md hover:bg-gray-400 focus:outline-none disabled:opacity-25'>Zrušiť</button>
                                        <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
                                    </div>
                                    </form>}
                            </div>}
                          </div>
                        </div>
                        <div className='basis-1/3'>
                          <div className='flex flex-col mb-5'><label className='font-bold mb-1'>Status</label>
                          <div className='text-gray-800'>
                            {!(user.role=="admin") ? renderTicketStatus(formSupportTicket.status) : 
                                <select value={formSupportTicket.status} onChange={(e) => changeStatus(e.target.value)} className='block w-60  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                                    <option value="Vytvorený">Vytvorený</option>
                                    <option value="Čaká sa">Čaká sa</option>
                                    <option value="V riešení">V riešení</option>
                                    <option value="Vyriešený">Vyriešený</option>
                                </select>
                            }
                          </div>
                          </div>
                          <div className='mb-2'>
                            <button className="flex flex-row gap-2 disabled:opacity-25" onClick={() => changeNotification()} disabled={formSupportTicket.status=="Vyriešený"}>
                                {formSupportTicket.mentions?.filter((mentioner) => mentioner.id==user.id)[0]?.pivot?.notification ? <BellSlashIcon/> : <i title='Notifikácie' className="pi pi-bell" style={{ fontSize: '1.4rem', marginRight:5 }}></i>}
                                <span className='text-gray-800'>{formSupportTicket.mentions?.filter((mentioner) => mentioner.id==user.id)[0]?.pivot?.notification ? "Vypnúť" : "Zapnúť" } notifikácie</span>
                            </button>
                          </div>
                          <div className='mb-5'>
                            <button type="button" className='flex flex-row gap-2 disabled:opacity-25' disabled={user.id !== formSupportTicket.contractor_id} onClick={() => changeStatus("Vyriešený")}>
                                <i title='Notifikácie' className="pi pi-arrow-right-arrow-left" style={{ fontSize: '1.4rem', marginRight:5 }}></i>
                                <span className='text-gray-800'>Vyriešené samostatne</span>
                            </button>
                          </div>
                          <div className='flex flex-col mb-5'><label className='font-bold mb-1'>Typ tiketu</label>
                          {renderTicketType(formSupportTicket.type)}
                          </div>
                          <div className='flex flex-col mb-5'><label className='font-bold mb-1'>Zainteresovaní používatelia</label>
                          {formSupportTicket.mentions.map(user => 
                                <div className='flex items-center gap-2 text-gray-800 mb-2'>
                                    <Avatar className='p-overlay-badge' title={user?.employee?.name || user?.username} label={getUserInitials(user?.employee?.name || user?.username)} shape="circle" style={{ backgroundColor: getUserColor(user.username), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex flex-col'>
                                        <span>{user?.employee?.name || user?.username}</span>
                                        {formSupportTicket.contractor_id == user.id && <span className='text-sm text-gray-500'>Zakladateľ</span>}
                                    </div>
                                </div>
                          )}
                          <div className='text-gray-800'>
                            <button type="button" onClick={() => setShowAddUser(true)} className='flex items-center gap-2 disabled:opacity-25' disabled={formSupportTicket.status=="Vyriešený"}>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať používateľa
                            </button>
                          </div>
                          </div>
                        </div>
                    </div>
                </div>}
            </div>
            <Dialog header="Detail tiketu" visible={showDetail} style={{ width: '70vw' }} onHide={() => setShowDetail(false)}>
                <div className='w-auto flex flex-col'>
                        <div className='mb-2'>
                            <label className='font-semibold text-sm'>Téma:</label>
                            <input name='theme' disabled value={formSupportTicket?.theme} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Popis:</label>
                            <MyEditor editorValue={formSupportTicket?.description} hideToolbar={true} hideFileUpload={true} formik={null} />
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Číslo masky:</label>
                            <input name='mask_number' disabled value={formSupportTicket?.mask_number} className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div className='mb-2'>
                            <label className='font-semibold  text-sm'>Priorita:</label>
                            <select 
                            disabled
                            name='priority' value={formSupportTicket?.priority}
                             className="block w-full text-sm px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                <option value="low">Nízka</option>
                                <option value="normal">Normálna</option>
                                <option value="high">Vysoká</option>
                                <option value="blocker">Bloker - nedá sa pracovať</option>
                            </select>
                        </div>
                </div>
            </Dialog>
            <Dialog header="Pridanie používateľa" visible={showAddUser} style={{ width: '70vw' }} onHide={() => {setShowAddUser(false);setAddedUser("");}}>
                <div className='w-auto flex flex-col'>
                <Select showSearch={true} optionFilterProp="children" value={addedUser} onChange={(value) => setAddedUser(value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber používateľa</Select.Option>
                    {state.users?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>{user?.employee?.name || user?.username}</Select.Option>
                  ))}
                </Select>
                </div>
                <div>
                <button type="submit" disabled={addedUser==""} onClick={() => addUser()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Uložiť</button>
                </div>
            </Dialog>
            <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
              {preview}
            </Dialog>
        </>);
} 
