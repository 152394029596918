import React,{useContext,useEffect, useState,useRef} from 'react';
import {Link} from 'react-router-dom';
import { useFormik } from 'formik'; 
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { SaveAndCloseIcon,SaveIcon,AddIcon, EditIcon, CopyIcon, DeleteIcon,SearchIcon,ProblemIcon,PaperClipIcon, ActiveCopyIcon,LoadPrioIcon, PrioBack,RefreshIcon,PrintIcon,ExportIcon,VerticalLineIcon,BackToFirstIcon,FavoriteIcon,PriceIcon} from "../svg/svgIcons";
//import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from '../../components/AuthContext';
import { Dropdown } from 'primereact/dropdown';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import UtilContext from '../../components/UtilContext';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Checkbox } from 'primereact/checkbox';
import { PanelMenu } from 'primereact/panelmenu';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import { Select } from "antd";
window.DateTime = DateTime

export default function PriorityListMobile({columns_visible}){

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible,openFavoriteDialog}= useContext(UtilContext);

    const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("edit priorities"));
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [searchIconColor,setSearchIconColor] = useState("");

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [searchValue,setSearchValue] = useState('');

    const [selectedDepartment,setSelectedDepartment] = useState("");
    const [selectedPriorityState,setSelectedPriorityState] = useState("Otvorené");

    const [visibleAddProblem,setVisibleAddProblem] = useState(false);
    const [visibleUpdateProblem,setVisibleUpdateProblem] = useState(false);

    const [priorityProblem, setPriorityProblem] = useState(null);
    const [priorityForm, setPriorityForm] = useState(null);
    const [formikProblemState, setFormikProblemState] = useState(false);
    const [selectedPriority,setSelectedPriority] = useState(null);

    const [columnsVisible, setColumnsVisible] = useState({custom_department: true,custom_article_date:true,problem:true});
    const [maskVisible, setMaskVisible] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);
    const [printData,setPrintData] = useState([]);

    const [employeeDepartment, setEmployeeDepartment] = useState();
    const [employee, setEmployee] = useState([]);

    const [contractorDepartment,setContractorDepartment] = useState("");
    const [solverDepartment, setSolverDepartment] = useState();
    const [solver, setSolver] = useState([]);
    const [sortPrio, setSortPrio] = useState("own_priority");


    const [showTable, setShowTable] = useState(true);
    const [formType, setFormType] = useState("add");

    const ref = useRef(null);
    const ref2 = useRef(null);
    const printRef = useRef(null);
    const priorityFromDepartmentRef = useRef(null);
    const prioritiesRef = useRef(null);
    const problemShowHideRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const {
      transcript,
      listening
    } = useSpeechRecognition();
    const startListening = () => SpeechRecognition.startListening({ language: 'sk-SK'});

    useEffect(() => {      
      //setMask(null,null);
      if(columns_visible){
        waitForTables().then(() => {
          setMask(null,null,columns_visible);
        });
      }
    },[columns_visible]);

    const firstUpdate = useRef(true);
    useEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      showPriorities(null);
  },[selectedDepartment,selectedPriorityState]);

    function customFilter(data, filterParams){
      //data - the data for the row being filtered
      //filterParams - params object passed to the filter
      if(filterParams.department_id!=""){
        let problems = data?.priority_problems;
        if(filterParams.state=="Ručne pridané"){
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == filterParams.department_id){
              if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.assignment_type!="automatic")return true;
              else return false;
            }
          }
          return false;
        }
        else if(filterParams.state=="Duplikáty"){
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == filterParams.department_id){
              if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.duplicate==1)return true;
              else return false;
            }
          }
          return false;
        }
        else if(filterParams.state=="Uzavreté"){
          return false;
        }
        else{
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == filterParams.department_id){
              if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return true;
              else return false;
            }
          }
          return false;
        }
      }
      else{
        let problems = data?.priority_problems;
        if(filterParams.state=="Ručne pridané"){
          for(let i = 0;i<problems.length;i++){
            if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.assignment_type!="automatic")return true;
          }
          return false;
        }
        else if(filterParams.state=="Duplikáty"){
          for(let i = 0;i<problems.length;i++){
            if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.duplicate==1)return true;
          }
          return false;
        }
        else if(filterParams.state=="Uzavreté"){
          return false;
        }
        else{
          for(let i = 0;i<problems.length;i++){
            if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return true;
          }
          return false;
        }
      }
    }

    function openedFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==1;
      }
      else{
        let problems = data?.priority_problems;
        for(let i = 0;i<problems.length;i++){
          if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return false;
        }
        return data.validity==1;
      }
    }

    function closedFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==0;
      }
      else{
        return data.validity==0;
      }
    }

    function manuallyFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==1 && data.assignment_type!="automatic";
      }
      else{
        let problems = data?.priority_problems;
        for(let i = 0;i<problems.length;i++){
          if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return false;
        }
        return data.validity==1 && data.assignment_type!="automatic";
      }
    }

    function duplicateFilter(data, filterParams){
      if(filterParams.department_id!=""){
        return data.priority_department_id == filterParams.department_id && data.validity==1 && data.duplicate==1;
      }
      else{
        let problems = data?.priority_problems;
        for(let i = 0;i<problems.length;i++){
          if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return false;
        }
        return data.validity==1 && data.duplicate==1;
      }
    }

    function problemFilter(data, filterParams){
      let problems = data?.priority_problems;
      if(filterParams.department_id!=""){
        for(let i = 0;i<problems.length;i++){
          if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.priority_department_id == filterParams.department_id && data.validity==1)return true;
        }
        return false;
      }
      else{
        for(let i = 0;i<problems.length;i++){
          if((problems[i].state=="Problém" || problems[i].state=="Riešim problém") && data.validity==1)return true;
        }
        return false;
      }
    }

    async function waitForTables(){
      while(ref.current==null || ref2.current==null){
        await new Promise(resolve => setTimeout(resolve, 200));
      }
    }

  async function showPriorities(data){
    //JEDNO ODDELENIE
      waitForTables().then(() => {
        prioritiesRef.current.style.display = "block";
        ref.current?.clearFilter();
        ref2.current?.clearFilter();
        console.log(ref.current.getFilters(),ref2.current.getFilters());
        //ref.current?.rowManager?.table?.replaceData(data!=null ? data : state.priorities).then(function(rows){
            switch(selectedPriorityState){
                case "Otvorené": {
                    ref.current?.setFilter(openedFilter, {department_id:selectedDepartment});
                    break;
                }
                case "Uzavreté": {
                    ref.current?.setFilter(closedFilter, {department_id:selectedDepartment});
                    break;
                }
                case "Problémové":{
                    ref.current?.setFilter(problemFilter, {department_id:selectedDepartment});
                    if(selectedDepartment=="")prioritiesRef.current.style.display = "none";
                    break;
                }
                case "Ručne pridané":{
                  ref.current?.setFilter(manuallyFilter, {department_id:selectedDepartment});
                  break;
                }
                case "Duplikáty": {
                  ref.current?.setFilter(duplicateFilter, {department_id:selectedDepartment});
                  break;
                }
                default: {
                    ref.current?.setFilter(openedFilter, {department_id:selectedDepartment});
                    break;
                }
            }
            //ref.current?.redraw("true");
        //});
        //ref2.current?.replaceData(data!=null ? data : state.priorities).then(function(rows){
            ref2.current?.setFilter(customFilter, {department_id:selectedDepartment,state:selectedPriorityState});
            //ref2.current?.redraw("true");
        //});
        sortByOwnPriority();
        setSearchIconColor("");
        });
        if(data!=null)dispatch({type:'load_priorities',newPriorities:data});
  }

  function setMask(item,value,columns){
    const columnVisible = columns==null?{...columnsVisible}:columns;
    if(item!=null || value!=null)columnVisible[item] = value;
    console.log(columnVisible);
    for(const val in columnVisible){
      if(columnVisible[val]==false){
        ref.current.hideColumn(`${val}`);
        ref2.current.hideColumn(`${val}`);
      }
      else{
        ref.current.showColumn(`${val}`);
        ref2.current.showColumn(`${val}`);
      }
    }
    setColumnsVisible(columnVisible);
  }
  
    function filterEmployees(departmentId){
      setEmployeeDepartment(departmentId);
      console.log(state.priority_departments_employees);
      setEmployee(state.priority_departments_employees?.filter((item => item.id == departmentId))[0]?.employees
      .filter((employee) => employee.date_of_departure == null || (employee.date_of_departure && DateTime.fromISO(employee.date_of_departure) < DateTime.now().toISO())));
    }
    
    function filterSolvers(departmentId){
      setSolverDepartment(departmentId);
      console.log(state.priority_departments_employees);
      setSolver(state.priority_departments_employees?.filter((item => item.id == departmentId))[0]?.employees
      .filter((solver) => solver.date_of_departure == null || (solver.date_of_departure && DateTime.fromISO(solver.date_of_departure) < DateTime.now().toISO())));
    }
  
    function addNewPriority(){
      dispatch({type:'create_new_tab',name:'Nová priorita',page:'PriorityAdd',props:{priority: null,type:'add'}});
    }
    function copyPriority(){
      dispatch({type:'create_new_tab',name:'Nová priorita',page:'PriorityAdd',props:{priority: selectedPriority,type:'add',not_last_used:true}});
    }
    function editPriority(){
      dispatch({type:'create_new_tab',name:'Upraviť prioritu',page:'PriorityAdd',props:{priority: selectedPriority,type:'edit',not_last_used:true}});
    }

  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    //RegExp - modifier "i" - case insensitve

    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function searchTable(){
    if (searchValue !== "") {
      setSearchIconColor("active");
      ref.current.addFilter(matchAny, { value: searchValue});
      ref2.current.addFilter(matchAny, { value: searchValue});
    }
    setVisibleSearch(false);
  }

  const accept = () => {
    setDialogText("Vymazávam")
    setVisible(true);
    let delete_priority = ref.current.getSelectedData().length ? ref.current.getSelectedData()[0] : ref2.current.getSelectedData()[0];
    deleteRequest(`/api/priorities/${delete_priority.id}`)
    .then((response) => {
      if(response.status == 200){
        let rows = ref.current.searchRows("id", "=", delete_priority.id);
          rows[0].update(response.data);
          rows[0].reformat();
          rows[0].deselect();
          rows = ref2.current.searchRows("id", "=", delete_priority.id);
          rows[0].update(response.data);
          rows[0].reformat();
          rows[0].deselect();
        ref.current.refreshFilter();
        ref2.current.refreshFilter();
        setVisible(false);
        let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'success', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
      }
    }).catch((reason) => {
        setVisible(false);
        alert(reason);
        let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
    })
  }

  const reject = () => {
    let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
  }
      

    const confirm = () => {
        confirmDialog({
          message: 'Prajete si vymazať tento záznam/y?',
          header: 'Potvrdenie vymazania',
          icon: 'pi pi-info-circle',
          rejectClassName: 'p-button-danger',
          acceptLabel: 'Nie',
          rejectLabel: 'Áno',
          defaultFocus: 'accept',
          reject: accept,
          accept: reject
        });
    };

    const acceptProblemDelete = () => {
      setDialogText("Vymazávam")
      setVisible(true);
      deleteRequest(`/api/priority_problems/${formikUpdate.values.id}`)
      .then((response) => {
        if(response.status == 200){
          let rows = ref.current.searchRows("id", "=", formikUpdate.values.priority_id);
          rows[0]?.update(response.data);
          rows[0]?.reformat();
          rows = ref2.current.searchRows("id", "=", formikUpdate.values.priority_id);
          rows[0]?.update(response.data);
          rows[0]?.reformat();
          ref.current.refreshFilter();
          ref2.current.refreshFilter();
          setVisible(false);
          hideUpdateProblem();
          formikUpdate.resetForm();
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
        }
        else{
          setVisible(false);
          hideUpdateProblem();
          formikUpdate.resetForm();
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
        }
      }).catch((reason) => {
          setVisible(false);
          hideUpdateProblem();
          formikUpdate.resetForm();
          alert(reason);
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
      })
  }

    const confirmProblemDelete = () => {
      confirmDialog({
        message: 'Prajete si vymazať tento problém?',
        header: 'Potvrdenie vymazania',
        icon: 'pi pi-info-circle',
        rejectClassName: 'p-button-danger',
        acceptLabel: 'Nie',
        rejectLabel: 'Áno',
        defaultFocus: 'accept',
        reject: acceptProblemDelete,
        accept: reject
      });
  };

  const rowClick = (e, row) => {
    //console.log(e, row);
 };

 const onSelectRow = (data, rows) => {
  setPriorityForm(data);
  setEditIcon(true);
  setCopyIcon(true);
  setDeleteIcon(true);
  if(ref.current.getSelectedData().length + ref2.current.getSelectedData().length === 1){
      //TODO dorobit aj contractorov
      if(ref.current.getSelectedData().length){
        if(ref.current.getSelectedData()[0].assignment_type!=="automatic"){
          if(user.permissions.includes("edit priorities"))setEditIcon(false);
          if(user.permissions.includes("delete priorities") && ref.current.getSelectedData()[0].validity===1)setDeleteIcon(false);
        }
        setSelectedPriority(ref.current.getSelectedData()[0]);
      }
      else if(ref2.current.getSelectedData().length){
        if(ref2.current.getSelectedData()[0].assignment_type!=="automatic"){
          if(user.permissions.includes("edit priorities"))setEditIcon(false);
          if(user.permissions.includes("delete priorities") && ref2.current.getSelectedData()[0].validity===1)setDeleteIcon(false);
        }
        setSelectedPriority(ref2.current.getSelectedData()[0]);
      }
      if(user.permissions.includes("edit priorities"))setCopyIcon(false);
  }
};

    function rowMovePriorityEvent(row){
      setDialogText("Ukladám");
      setVisible(true);
      let rows = ref.current.getData("active");
      //rows = rows.filter((get_row) => get_row.priority_department_id == row.getData().priority_department_id);
      rows.sort((a,b) => ref.current.searchRows("id", "=", a.id)[0]?.getPosition() > ref.current.searchRows("id", "=", b.id)[0]?.getPosition() ? 1:-1);
      let formData = new FormData();
      let i = 1;
      rows.reverse().forEach(my_row => {
        let row_data = {id: my_row.id,son: my_row.sales_order_number,own_priority: i};
        my_row.own_priority = i;
        i = i+1;
        formData.append("priorities[]",JSON.stringify(row_data))
      });
      postRequest('/api/priorities/updatePriority', formData,true)
      .then((response) => {
        if(response.status == 200){
          for(let i = 0;i<rows.length;i++){
            let db_rows = ref.current.searchRows("id", "=", rows[i].id);
            db_rows[0]?.update(rows[i]);
            db_rows[0]?.reformat();
            db_rows = ref2.current.searchRows("id", "=", rows[i].id);
            db_rows[0]?.update(rows[i]);
            db_rows[0]?.reformat();
          }
          sortByOwnPriority();
          console.log(ref.current.getSorters());
          setVisible(false);
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Priority boli nastavené!" });
        }
      }).catch((reason) => {
          setVisible(false);
          alert("Daný úkon sa nepodaril!");
      })
    };

    function rowMoveProblemEvent(row){
      setDialogText("Ukladám");
      setVisible(true);
      let rows = ref2.current.getData("active");
      //rows = rows.filter((get_row) => get_row.priority_department_id == row.getData().priority_department_id);
      rows.sort((a,b) => ref2.current.searchRows("id", "=", a.id)[0]?.getPosition() > ref2.current.searchRows("id", "=", b.id)[0]?.getPosition() ? 1:-1);
      let formData = new FormData();
      let i = 1;
      rows.reverse().forEach(my_row => {
        let row_data = {id: my_row.id,son: my_row.sales_order_number,own_priority: i};
        my_row.own_priority = i;
        i = i+1;
        formData.append("priorities[]",JSON.stringify(row_data))
      });
      postRequest('/api/priorities/updatePriority', formData,true)
      .then((response) => {
        if(response.status == 200){
          for(let i = 0;i<rows.length;i++){
            let db_rows = ref.current.searchRows("id", "=", rows[i].id);
            db_rows[0]?.update(rows[i]);
            db_rows[0]?.reformat();
            db_rows = ref2.current.searchRows("id", "=", rows[i].id);
            db_rows[0]?.update(rows[i]);
            db_rows[0]?.reformat();
          }
          sortByOwnPriority();
          setVisible(false);
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Priority boli nastavené!" });
        }
      }).catch((reason) => {
          setVisible(false);
          alert("Daný úkon sa nepodaril!");
      })
    };

    function setEmployeeToPriority(set_employee_id,priority,row){
      setDialogText("Ukladám");
      setVisible(true);
      let formData = new FormData();
      formData.append("employee_id", set_employee_id);
      formData.append("chosen_by_manager", user.permissions.includes("edit priorities") ? 1 : 0);
      postRequest(`/api/priorities/updateEmployee/${priority.id}`, formData,true)
      .then((response) => {
          if(response.status == 200){
            let rows = ref.current.searchRows("id", "=", priority.id);
            rows[0]?.update({employee_id:set_employee_id,employee: response.data?.employee});
            rows[0]?.reformat();
            rows = ref2.current.searchRows("id", "=", priority.id);
            rows[0]?.update({employee_id:set_employee_id,employee: response.data?.employee});
            rows[0]?.reformat();
            setVisible(false);
            let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Zamestnanec bol priradený!" });
          }
      }).catch((reason) => {
        console.log(reason);
        setVisible(false);
        alert("Daný úkon sa nepodaril!");
      });
    }

    function DepartmentFormatter(props) {
      const rowData = props.cell.getRow().getData();
      return (
        <div className={rowData.assignment_type!=="automatic" ? 'manualPriority flex flex-col' :'flex flex-col'}>
        <div className='flex flex-row'>{rowData.priority_department?.name}</div>
            {(rowData.production_order_number!="" && rowData.production_order_number!=null) ?
            <div  className='flex flex-row'>
              {rowData.production_order_number}
            </div>:<br/>}
            {rowData.sales_order_number!="" && rowData.sales_order_number!=null &&
            <div  className='flex flex-row'>
              {rowData.sales_order_number}
            </div>}
          </div>
      )
    }

    function setEmployeePermission(rowData){
      if(user.permissions.includes("edit priorities")){
        return false;
      }
      else{
        if((rowData.state === "Pracuje" || rowData.state === "Hotovo" || rowData.state === "Problém" || rowData.state === "Riešim problém")){//(rowData.employee_id!=null && rowData.employee_id!="")
          return true;
        }
        if(!user.permissions.includes("add priorities")){
          return true;
        }
        if(rowData.chosen_by_manager==1){
          return true;
        }
        if(user.priority_departments?.length > 0){
          let user_departments = user.priority_departments.map(department => department.id);
          if(!user_departments.includes(rowData.priority_department_id)){
            return true;
          }
        }
        else{
          let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == user.employee.id));
          if(result.length==0 || (result.length > 0 && rowData.priority_department_id !=result[0].id)){
            return true
          }
        }
        return false;
      }    
    }

    function ArticleDateFormatter(props) {
      const rowData = props.cell.getRow().getData();
      const row = props.cell.getRow();
        const [isActive, setIsActive] = useState(false);
        //D J
        return (
      <div className={rowData.duplicate ? 'duplicate flex flex-col w-[115px]' : 'flex flex-col w-[115px]'}>
          {(rowData.article_number!="" && rowData.article_number!=null) ?
            <div className= 'flex flex-row text-blue-600'>
              <a href={rowData.drawing_url ? rowData.drawing_url : '#'} target='_blank' className='underline italic'>
                {rowData.article_number}
              </a>
          </div>:<br/>}
          {rowData.delivery_date!="" && rowData.delivery_date!=null &&
            <div className='flex flex-row'>
              {DateTime.fromISO(rowData.delivery_date).toFormat("dd.MM.yyyy")}
          </div>}
          <div className="flex flex-row">
            {<select value={rowData.employee_id}
              disabled={setEmployeePermission(rowData) ? true : false}            
              onChange={(e) => setEmployeeToPriority(e.target.value,rowData,row)} name="employee_id" className={`bg-white block w-24 h-6 whitespace-pre-wrap px-1 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
              <option value="">Vyber zamestnanca</option>
              {state.priority_departments_employees?.filter((item => item.abbreviation == rowData.priority_department?.abbreviation))[0]?.employees
              .filter((employee) => employee.date_of_departure == null || (employee.date_of_departure && DateTime.fromISO(employee.date_of_departure) < DateTime.now().toISO()))
              .map((employee) => (
                <option key={employee.id} value={employee.id}>{employee.name}</option>
                ))}
            </select>}
          </div>
      </div>
        ); 
    }

    function setProblemPermission(rowData,problem){
      if(user.permissions.includes("edit priorities")){
        return true;
      }
      if(rowData.state!="Hotovo"){
        if(user.permissions.includes("add priorities")){
          if(user.priority_departments?.length > 0){
            let user_departments = user.priority_departments.map(department => department.id);
            if(user_departments.includes(problem.offender_department_id) || user_departments.includes(rowData.priority_department_id)){
              return true;
            }
          }
          else{
            let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == user.employee.id));
            if(result.length > 0 && (problem.offender_department_id==result[0].id || rowData.priority_department_id == result[0].id)){//rowData.priority_department_id == result[0].id || 
              return true;
            } 
          }
        }
      }
      return false;
    }

    function setStatePermission(rowData){
      if(user.permissions.includes("edit priorities")){
        return true;
      }
      if(rowData.state!="Hotovo"){
        if(user.permissions.includes("add priorities")){
          if(user.priority_departments?.length > 0){
            let user_departments = user.priority_departments.map(department => department.id);
            if(user_departments.includes(rowData.priority_department_id) && rowData.employee_id!=null && rowData.employee_id!=""){
              return true;
            }
          }
          else{
            let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == user.employee.id));
            if(result.length > 0 && (rowData.priority_department_id == result[0].id && rowData.employee_id!=null && rowData.employee_id!="")){
              return true;
            }
          }
        }
      }
      return false;
    }

    function ProblemFormatter(props) {
      const rowData = props.cell.getRow().getData();
        return <div className='h-[115px] overflow-y-auto'>
          <div className='flex flex-col flex-wrap'>
        {(rowData.total_amount!="" && rowData.total_amount!=null) ?
            <div className='flex flex-row'>
            Ks: {rowData.total_amount}
          </div>:<br/>}
          <div className='flex flex-row'>
        {rowData.priority_problems.map((problem) => {
          let color = states.filter(state => state.name == problem.state)[0]?.color;
          return <button type='button' onClick={setProblemPermission(rowData,problem) ? () => editPriorityProblem(rowData,problem) : null}><ProblemIcon color={color}/></button>;
        })}
        {(rowData.state=="Pracuje" || rowData.state=="Hotovo") ?
          <button type='button' onClick={rowData.state=="Hotovo" && !user.permissions.includes("edit priorities")  ? null : () => addPriorityProblem(rowData)}><ProblemIcon color={add_states.filter(state => state.name == rowData.state)[0]?.color}/></button>:
          <button type='button' onClick={setStatePermission(rowData) ? () => addPriorityProblem(rowData) : null}><ProblemIcon/></button>
        }
        </div>
        </div>
        <div  className='flex flex-row'>{DateTime.fromISO(rowData.updated_at).toFormat("dd.MM.yyyy")}</div>
        </div>;
    }


  const priority_columns = [
    { rowHandle:true, formatter:"handle", headerSort:false, frozen:true, minWidth:22, width:22,resizable:false,print:false},  
    { formatter:"rowSelection",vertAlign:"middle",id:"checkbox", titleFormatter:"rowSelection",headerHozAlign:"center", hozAlign:"center",minWidth:20, width:20,headerSort:false,frozen:true,resizable:false,print:false},
    { title: 'ODD/VZ/PZ',field: 'custom_department',headerSort:false,widthGrow:1.5,formatter: reactFormatter(<DepartmentFormatter/>),visible:true},
    { title: 'ART/DT/M', field: 'custom_article_date',headerSort:false,widthGrow:1.3,formatter: reactFormatter(<ArticleDateFormatter/>),resizable:false},
    { title: 'KS/STAV', field: 'problem',headerSort:false,widthGrow:1,formatter: reactFormatter(<ProblemFormatter/>),resizable:false},
    { field: 'state',visible:false},
    { field: 'own_priority',visible:false,sorter:"number"},
    { field: 'validity',visible:false},
    { field: 'priority_department.defined_id',visible:false,sorter:"number"},
    { field: 'assignment_type',visible:false},
    { field: 'delivery_date',visible:false,sorter: "date", sorterParams: { format: "yyyy-MM-dd",alignEmptyValues:"bottom",}},
  ];

  const problem_columns = [
    { rowHandle:true, formatter:"handle", headerSort:false, frozen:true, minWidth:22,width:22,resizable:false,print:false},  
    { formatter:"rowSelection",vertAlign:"middle", titleFormatter:"rowSelection",headerHozAlign:"center", hozAlign:"center",minWidth:20, width:20,headerSort:false,frozen:true,resizable:false,print:false},
    { title: 'ODD/VZ/PZ',field: 'custom_department',headerSort:false,widthGrow:1.5,formatter: reactFormatter(<DepartmentFormatter/>),visible:true},
    { title: 'ART/DT/M', field: 'custom_article_date',headerSort:false,widthGrow:1.3,formatter: reactFormatter(<ArticleDateFormatter/>),resizable:false},
    { title: 'KS/STAV', field: 'problem',headerSort:false,widthGrow:1,formatter: reactFormatter(<ProblemFormatter/>),resizable:false},
    { field: 'state',visible:false},
    { field: 'own_priority',visible:false,sorter:"number"},
    { field: 'validity',visible:false},
    { field: 'priority_department.defined_id',visible:false,sorter:"number"},
    { field: 'assignment_type',visible:false},
    { field: 'delivery_date',visible:false,sorter: "date", sorterParams: { format: "yyyy-MM-dd",alignEmptyValues:"bottom",}},
  ];

  const add_manager_states = user.permissions.includes("edit priorities") ? 
  [
    { name: 'Pracuje', color: 'orange', title: 'Pracuje' },
    { name: 'Hotovo', color: 'green', title: 'Hotovo' },
    { name: 'Problém', color: 'red', title: 'Problém' },
    { name: 'Nová', color: '',title: 'Nová' },
  ] :[
    { name: 'Pracuje', color: 'orange', title: 'Pracuje' },
    { name: 'Hotovo', color: 'green', title: 'Hotovo' },
    { name: 'Problém', color: 'red', title: 'Problém' },
  ];

  const add_states = [
    { name: 'Pracuje', color: 'orange', title: 'Pracuje' },
    { name: 'Hotovo', color: 'green', title: 'Hotovo' },
    { name: 'Problém', color: 'red', title: 'Problém' },
  ];

  const problem_states = [
    { name: 'Problém', color: 'red', title: 'Problém' },
  ];

  const states = [
    { name: 'Problém', color: 'red', title: 'Problém' },
    { name: 'Riešim problém', color: 'blue', title: 'Riešim problém' },
    { name: 'Vyriešený problém', color: 'magenta', title: 'Vyriešený problém' },
  ];

  const selectedStateTemplate = (option, props) => {
    if (option) {
        return (
          <div className="flex items-center	">
            <ProblemIcon color={option.color}></ProblemIcon>
            <div className='ml-2 text-lg font-semibold'>{option.title}</div>
        </div>
        );
    }

    return <span>{props.placeholder}</span>;
};

  const stateTemplate = (option) => {
    return (
        <div className="flex items-center	">
            <ProblemIcon color={option.color}></ProblemIcon>
            <div className='ml-3 text-xl font-semibold'>{option.title}</div>
        </div>
    );
  };

  function addPriorityProblem(priority) {
    setFormikProblemState((priority.state=="Problém" || priority.state=="Riešim problém") ? true : false );
    setPriorityForm(priority);
    //console.log(priority);
    filterEmployees(priority.priority_department_id);
    if(priority.employee_id!=null)formik.setFieldValue("contractor_id",priority.employee_id);
    if(priority.state=="Pracuje" || priority.state=="Hotovo")formik.setFieldValue("state",add_states.filter(state => state.name == priority.state)[0]);
    setVisibleAddProblem(true);
  }

  function editPriorityProblem(priority,priority_problem) {
    for (let value in priority_problem){
      if(value=="state"){
        formikUpdate.setFieldValue(value, states.filter(state => state.name == priority_problem[value])[0]);
      }
      else{
        formikUpdate.setFieldValue(value,priority_problem[value]!="null"?priority_problem[value]:"");
      }
    }
    let result = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == priority_problem.contractor_id));
    if(result.length > 0)setContractorDepartment(result[0].id);
    let result2;
    if(priority_problem.solver_id != null){
      result2 = state.priority_departments_employees.filter(department => department.employees.some(employee => employee.id == priority_problem.solver_id));
      if(result2.length > 0)filterSolvers(result2[0].id);
      formikUpdate.setFieldValue("solver_id",priority_problem.solver_id);
    }
    else{
      filterSolvers(priority_problem.offender_department_id);
    }
    setPriorityForm(priority);
    setPriorityProblem(priority_problem);
    //console.log(priority_problem);
    setVisibleUpdateProblem(true);
  }

  function hideAddProblem(){
    setVisibleAddProblem(false);
    setPriorityForm(null);
    formik.resetForm();
  }

  function hideUpdateProblem(){
    setPriorityForm(null);
    setPriorityProblem(null);
    setVisibleUpdateProblem(false);
    formikUpdate.resetForm();
  }

  const validate = values => {
    const errors = {};
    /*if (!values.type) {
        errors.type = '*Povinné';
    }*/
    if(values.state?.name=="Problém"){
      if (values.contractor_id==null || values.contractor_id=="") {
        errors.contractor_id = '*Povinné';
      }
    }
    return errors;
};


const formik = useFormik({
  initialValues: {
      problem:'',offender_department_id:'',contractor_id:'',state:''
  },
  enableReinitialize:true,
  validate,
  onSubmit: values => {
      let formData = new FormData();
      for(let value in values){
        if(value=="solver_id"){
          formData.append("solver_id", values[value].id);
        }
        else if(value=="state"){
          formData.append(value, values[value].name);
        }
        else{
          formData.append(value,values[value])
        }
      }
      formData.append("priority_id", priorityForm.id);
      postRequest('/api/priority_problems', formData,true)
      .then((response) => {
        console.log(response);
        if(response.status == 201){
          let rows = ref.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          rows = ref2.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          ref.current.refreshFilter();
          ref2.current.refreshFilter();
          if(priorityFromDepartmentRef.current.style.display != "block"){
            priorityFromDepartmentRef.current.style.display = "block";
            ref2.current.redraw("true");
          }
          //dispatch({type:'load_priorities',newPriorities:ref.current.getData()});
          hideAddProblem();
          formik.resetForm();
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
        }
      }).catch((reason) => {
              alert("Daný úkon sa nepodaril!");
      })
  },
});

    const formikPrio = useFormik({
      initialValues: {
          priority_department_id: '',production_order_number:'',sales_order_number:'',article_number:'',delivery_date:'',
          description_1:'',description_2:'',drawing_name:'',drawing_url:'',total_amount:'', assignment_type:'manually'
      },
      enableReinitialize:true,
      validate,
      onSubmit: values => {
        setDialogText("Ukladám");
        setVisible(true);
        if(formType=="add"){
          if(values.delivery_date=="" && values.article_number==""){
              values["assignment_type"] = "manually_partially";
          }
          else{
              values["assignment_type"] = "manually";
          }
        }
          let formData = new FormData();
          for (let value in values) {
              formData.append(value, values[value]);
          }
          if(formType=="add"){
              postRequest('/api/priorities', formData,true)
              .then((response) => {
                  console.log(response);
                      if(response.status == 201){
                        if(response.data.length>0)formik.setValues(response.element);
                        setFormType("edit");                  
                        let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        console.log(response.element);
                        showPriorities(response.data);
                        dispatch({type:'load_priorities',newPriorities:response.data});
                        setVisible(false);
                      }

              }).catch((reason) => {
                  if(!navigator.online){
                      alert("Ste offline, dáta budu odoslané po znovupripojení!");
                  }
                  else{
                      alert("Daný úkon sa nepodaril!");
                  }
                  setVisible(false);
              })
          }
          else{
              postRequest(`/api/priorities/update/${formikPrio.values.id}`, formData,true)
              .then((response) => {
                  getRequest("/api/priorities").then((response) => {
                      showPriorities(response.data);
                      dispatch({type:'load_priorities',newPriorities:response.data});
                      formikPrio.setValues(values);
                      let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                      setVisible(false);
                  });
              }).catch((reason) => {
                  if(!navigator.online){
                      alert("Ste offline, dáta budu odoslané po znovupripojení!");
                  }
                  else{
                      alert("Daný úkon sa nepodaril!");
                  }
                  setVisible(false);
              })
          }
      },
    });

    const updateValidate = values => {
      const errors = {};
      if (values.solver_id==null || values.solver_id==""){
        errors.solver_id = '*Povinné';
      }
      return errors;
    };

    const formikUpdate = useFormik({
      initialValues: {
          id:'',problem:'',offender_department_id:'',contractor_id:'',solution:'',solver_id:'',state:'Problém',priority_id:'',problem_created_at:'',solution_created_at:''
      },
      enableReinitialize:true,
      validate: updateValidate,
      onSubmit: values => {
      let formData = new FormData();
      for(let value in values){
        if(value=="state"){
          formData.append(value, values[value].name);
        }
        else{
          formData.append(value,values[value])
        }
      }
      formData.append("priority_id", priorityForm.id);
      postRequest(`/api/priority_problems/update/${formikUpdate.values.id}`, formData,true)
      .then((response) => {
        console.log(response);
        //TODO SKRATIT TO NA 1 request
        if(response.status == 200){
          let rows = ref.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          rows = ref2.current.searchRows("id", "=", priorityForm.id);
          rows[0].update(response.data);
          rows[0].reformat();
          ref.current.refreshFilter();
          ref2.current.refreshFilter();
          hideUpdateProblem();
          formikUpdate.resetForm();
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
        }
      }).catch((reason) => {
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    },
  });

  function refreshPriorities(){
    setDialogText("Aktualizujem");
    setVisible(true);
    getRequest("/api/priorities/refresh").then((response) => {
      showPriorities(response.data);
      setVisible(false);
    })
    .catch((reason) => {
      alert(reason);
      console.log(reason);
      setVisible(false);
  });
  }

  function printPriorities(){
    let problems = ref2.current.getData('active');
    let priorities = ref.current.getData('active');
    let merge_data = problems.concat(priorities.
      filter((item) => !problems.some(el => el.id === item.id)));
    printRef.current.setData(merge_data);
    printRef.current.print(false,true);
  }

  const exportToExcel = () => {
    const excel_state = {};
    excel_state["Nová"] = "Nova";
    excel_state["Pracuje"] = "Pracuje";
    excel_state["Hotovo"] = "Hotovo";
    excel_state["Problém"] = "Problem";
    excel_state["Riešim problém"] = "RProblem";
    excel_state["Vyriešený problém"] = "VProblem";
    let priorities = ref.current.getData().filter((priority) => priority.validity == 1).map((x) => {
      let id = x.id;
      let priority_department = x.priority_department.name;
      let production_order_number = x.production_order_number;
      let sales_order_number = x.sales_order_number;
      let article_number = x.article_number;
      let delivery_date = x.delivery_date;
      let description_1 = x.description_1;
      let description_2 = x.description_2;
      let drawing_name = x.drawing_name;
      let drawing_url = x.drawing_url;
      let total_amount = x.total_amount;
      let employee = x?.employee?.name;
      let skratka = x?.priority_department?.abbreviation;
      let state = excel_state[x.state];
      let assignment_type = x.assignment_type;
      return {
        id,priority_department,production_order_number,sales_order_number,article_number,delivery_date,description_1,description_2,
        drawing_name,drawing_url,total_amount,employee,skratka,state,assignment_type
      };
    }).sort((a, b) => (a.own_priority > b.own_priority) ? -1 : 1);
    const worksheet = XLSX.utils.json_to_sheet(priorities);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Priority");

    for(let i = 0;i<state.priority_departments.length;i++){
      let my_priorities = ref.current.getData().filter((priority) => priority.priority_department_id == state.priority_departments[i].id && priority.validity == 1).map((x) => {
        let id = x.id;
        let priority_department = x.priority_department.name;
        let production_order_number = x.production_order_number;
        let sales_order_number = x.sales_order_number;
        let article_number = x.article_number;
        let delivery_date = x.delivery_date;
        let description_1 = x.description_1;
        let description_2 = x.description_2;
        let drawing_name = x.drawing_name;
        let drawing_url = x.drawing_url;
        let total_amount = x.total_amount;
        let employee = x?.employee?.name;
        let skratka = x?.priority_department?.abbreviation;
        let state = excel_state[x.state];
        let assignment_type = x.assignment_type;
        return {
          id,priority_department,production_order_number,sales_order_number,article_number,delivery_date,description_1,description_2,
          drawing_name,drawing_url,total_amount,employee,skratka,state,assignment_type
        };
      });
      console.log(my_priorities);
      let custom_worksheet = XLSX.utils.json_to_sheet(my_priorities);
      XLSX.utils.book_append_sheet(workbook, custom_worksheet, state.priority_departments[i].abbreviation);
    }
    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "priority.xlsx");
  }

  function setFastView(){
    setDialogText("Nastavujem");
    setVisible(true);
    let formData = new FormData();
    formData.append("page", "PriorityListMobile");
    formData.append("columns", JSON.stringify(columnsVisible));
    formData.append("attributes", JSON.stringify(priority_columns));
    postRequest(`/api/fast_views`, formData,true)
    .then((response) => {
        if(response.status == 201){
          dispatch({type:'load_fast_view',newFastView:response.data});
          setVisible(false);
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Rýchly náhľad uložený!" });
        }
    }).catch((reason) => {
      console.log(reason);
      setVisible(false);
      alert("Daný úkon sa nepodaril!");
    });
  }

  function sortByDeliveryDate(){
    ref.current?.clearSort();
    ref2.current?.clearSort();
    ref.current.setSort([
      
      {column:"delivery_date", dir:"asc"}, //sort by this first
      {column:"priority_department.defined_id", dir:"asc"},
    ]);
    ref2.current.setSort([
      {column:"delivery_date", dir:"asc"}, //sort by this first
      {column:"priority_department.defined_id", dir:"asc"},
    ]);
    setSortPrio("delivery_date");
  }
  function sortByOwnPriority(){
    ref.current?.clearSort();
    ref2.current?.clearSort();
    ref.current.setSort([
      
      {column:"own_priority", dir:"desc"}, //sort by this first
      {column:"priority_department.defined_id", dir:"asc"},
    ]);
    ref2.current.setSort([
      {column:"own_priority", dir:"desc"}, //sort by this first
      {column:"priority_department.defined_id", dir:"asc"},
    ]);
    setSortPrio("own_priority");
  }

    const itemRenderer = (item, options) => (
      <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
          <span className={`${item.icon} text-primary`} />
          <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
          {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
    );

    const [expandedKeys, setExpandedKeys] = useState({});

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setExpandedKeys({});
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  const items = [
    {
      key: '0',
      label: 'Akcie',
      icon: 'pi pi-sliders-v',
      template: itemRenderer,
      items: [
          {
              key: '0_1',
              label: 'Zrušiť filter',
              icon: 'pi pi-filter-slash',
              command: () => {setSelectedDepartment("");setSelectedPriorityState("Otvorené");OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
              template: itemRenderer,
          },
          {
              key: '0_2',
              label: 'Nastaviť masku',
              icon: 'pi pi-server',
              command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
              template: itemRenderer,
          },
          {   key: '0_3',
              label: 'Radenie',
              icon: 'pi pi-sort-alt',
              template: itemRenderer,
              items: [
                  {
                      key: '0_3_1',
                      label: sortPrio== "own_priority" ? 'Vlastné☑️' : 'Vlastné',
                      icon: 'pi pi-sort-numeric-down',
                      command: () => {sortByOwnPriority();OverflowMenuProvider.setVisible(null);},
                      template: itemRenderer,
                  },
                  {
                    key: '0_3_2',
                    label: sortPrio== "delivery_date" ? 'Dodací termín☑️' : 'Dodací termín',
                    icon: 'pi pi-sort-amount-down-alt',
                    command: () => {sortByDeliveryDate();OverflowMenuProvider.setVisible(null);},
                    template: itemRenderer,
                }
              ]
          },
          {
            key: '0_4',
            label: 'Notifikácie',
            icon: 'pi pi-envelope',
            command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
            template: itemRenderer,
        },
      ]
    }
  ];

  function showChosenNotifications(){
    let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "PriorityList"));
    if(db_chosen_notifications?.length>0){
      setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
    }
    else{
      setChosenNotifications([]);
    }
    setNotificationVisible(true);
  }

  function saveChosenNotifications(){
    let formData = new FormData();
    formData.append("type","PriorityList");
    formData.append("data", JSON.stringify(chosenNotifications));
    postRequest('/api/chosen_notifications', formData,true)
    .then((response) => {
        if(response.status == 201){
          dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
        }else{
          let mytoast = toast.current ? toast : ownToast; 
        mytoast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
        }
    }).catch((reason) => {
          console.log(reason);
          alert("Daný úkon sa nepodaril!");
    });
    setNotificationVisible(false);
}

    return(
      <>
      <Toast ref={ownToast}/>
      <div className='w-full h-10 flex flex-row bg-[#333]'>
    <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewPriority()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editPriority()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyPriority()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => printPriorities()}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="search"><button onClick={searchIconColor!="active"? () => setVisibleSearch(true):() => {setSearchIconColor("");setSearchValue("");showPriorities();}}><SearchIcon color={searchIconColor}/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="export"><button onClick={() => exportToExcel()}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="refresh"><button onClick={() => refreshPriorities()}><RefreshIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Zoznam Priorít malý","PriorityListMobile")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("PriorityListMobile",null,null)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className="card flex justify-content-center z-[100] " data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem" />
        </div>
    </IntersectionObserverWrapper>
      </div>
<div className={showTable ? 'block px-1 bg-white shadow-md': 'hidden px-1 bg-white shadow-md'}>
  <div className='sticky w-full top-14 left-0 bg-white py-1'>
    <div className='grid grid-cols-2 gap-1 mt-0'>
  <select 
            name="abbreviation_id"
            value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)}
            className={`bg-white block w-full mb-1 px-1 py-2 text-grey-700 border  focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
              <option value="">Všetky</option>
              {state.priority_departments.map((department) => (
                <option key={department.id} value={department.id}>{department.name}</option>
                ))}
              
          </select>
          <select 
            name="priority_state"
            value={selectedPriorityState} onChange={(e) => setSelectedPriorityState(e.target.value)}
            className={`bg-white block w-full mb-1 px-1 py-2 text-grey-700 border  focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
              <option value="Otvorené">Otvorené</option>
              <option value="Uzavreté">Uzavreté</option>
              <option value="Problémové">Problémové</option>
              <option value="Ručne pridané">Ručne pridané</option>
              <option value="Duplikáty">Duplikáty</option>
          </select>
          </div>
  </div>
<div className="flex flex-col overflow-x-auto no-scrollbar">
<div className="overflow-hidden">
  <div className={columns_visible ? 'max-h-[calc(100vh-287px)] overflow-y-auto pt-2.5' : 'max-h-[calc(100vh-227px)] overflow-y-auto pt-2'}>
        <div ref={priorityFromDepartmentRef}>
        <div onClick={() => {if(problemShowHideRef.current.style.display == "none")problemShowHideRef.current.style.display = "block";else problemShowHideRef.current.style.display = "none"}} className='flex flex-row bg-red-400 w-full'><span className='flex justify-start ml-2 text-lg font-semibold'>Problémy</span></div>
        <div ref={problemShowHideRef}>
        <ReactTabulator
        data={state.priorities} 
        className={columns_visible ? 'max-h-[calc(100vh_-_325px)] whitespace-pre-wrap' :'max-h-[calc(100vh_-_255px)] whitespace-pre-wrap'} //176px h-[calc(100vh_-_176px)]
        onRef={(r) => (ref2.current = r.current)}
        columns={problem_columns}
        events={{
          rowClick: rowClick,
          rowSelectionChanged: onSelectRow,
          rowMoveEvent: rowMoveProblemEvent,
          tableBuilt: ()=>{
            ref2.current?.rowManager?.table?.setFilter(customFilter, {department_id:selectedDepartment,state:selectedPriorityState});
          },
        }}
        options={{layout:"fitColumns",movableRows:user.permissions.includes("edit priorities"),rowHeight: 90, 
        initialSort:[
          {column:"own_priority", dir:"desc"},
          {column:"priority_department.defined_id", dir:"asc"},
        ],
        printAsHtml:true,printHeader:"<h1>Zoznam problémových priorít<h1>",printRowRange:"active"}}/> 
        </div>
      </div>
      <div ref={prioritiesRef} className=''>
      <div className='bg-green-400 w-full'><span className='ml-2 text-lg font-semibold'>Priority</span></div>
      <ReactTabulator
      data={state.priorities} 
      className={columns_visible ? 'max-h-[calc(100vh_-_325px)] whitespace-pre-wrap' : 'max-h-[calc(100vh_-_255px)] whitespace-pre-wrap'} //176px h-[calc(100vh_-_176px)]
      onRef={(r) => (ref.current = r.current)}
      columns={priority_columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        rowMoved: rowMovePriorityEvent,
        tableBuilt: () => {
          ref.current?.rowManager?.table?.setFilter(openedFilter, {department_id:selectedDepartment});
        },
      }}
      options={{layout:"fitColumns",movableRows:user.permissions.includes("edit priorities"),rowHeight: 90, 
      initialSort:[
        {column:"own_priority", dir:"desc"},
        {column:"priority_department.defined_id", dir:"asc"},
      ],
      printAsHtml:true,printHeader:"<h1>Zoznam priorít<h1>",printRowRange:"active"}}/> 
      
    </div>
    <div className='hidden'>
    <ReactTabulator
      data={printData} 
      className='whitespace-pre-wrap' //176px h-[calc(100vh_-_176px)]
      onRef={(r) => (printRef.current = r.current)}
      columns={priority_columns}
      options={{layout:"fitColumns",rowHeight: 90,printStyled:true,
      groupBy:function(data){
        if(ref.current.getFilters()[0]?.type?.department_id!=""){
          let problems = data?.priority_problems;
          for(let i = 0;i<problems.length;i++){
            if(problems[i].offender_department_id == ref.current.getFilters()[0]?.type?.department_id){
              if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return true;
              else return false;
            }
          }
          return false;
        }
        else{
          let problems = data?.priority_problems;
          for(let i = 0;i<problems.length;i++){
            if(problems[i].state=="Problém" || problems[i].state=="Riešim problém")return true;
          }
          return false;
        }
      }, 
      groupHeader:function(value, count, data, group){
        //value - the value all members of this group share
        //count - the number of rows in this group
        //data - an array of all the row data objects in this group
        //group - the group component for the group
        if(value==true)return "Problémy<span style='color:#d00; margin-left:10px;'>(" + count + ")</span>";
        else return "Priority<span style='color:#d00; margin-left:10px;'>(" + count + ")</span>";
    },
      printAsHtml:true,printHeader:"<h1>Zoznam priorít<h1>",printRowRange:"active",
      }}/>
    </div> 
  </div> 
</div> 
</div>
<Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  autoFocus={visibleSearch ? true : false}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
      <Dialog header="Stav" visible={visibleAddProblem} style={{ width: '40vw' }} onHide={() => hideAddProblem()}>
          <div className="card flex justify-content-center">
          <Dropdown inputId='state' name="state" value={formik.values.state} onChange={formik.handleChange} options={formikProblemState ? problem_states : (priorityForm?.state=="Nová" || priorityForm?.state=="Vyriešený problém") ? add_states : add_manager_states} optionLabel="name" placeholder="Vyber stav" 
              valueTemplate={selectedStateTemplate} itemTemplate={stateTemplate} className="w-full md:w-14rem border-2 border-black rounded-md"/>
          </div> 
          <form onSubmit={formik.handleSubmit}>
          {formik.values.state?.name=="Problém" && <>
            <div className='flex flex-col justify-center p-4'>
              <div>
              <label 
              htmlFor='problem'
              className='text-xl font-semibold mb-2'>
              Popis:
              </label>
                <textarea rows={2} cols={30} required
                name='problem'
                onChange={formik.handleChange} value={formik.values.problem}
                className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                </textarea>
                <button type='button'
                  onTouchStart={startListening}
                  onMouseDown={startListening}
                  onTouchEnd={SpeechRecognition.stopListening}
                  onMouseUp={SpeechRecognition.stopListening}
                >🎤</button>
                {listening && <div>Počúvam...</div>}
              </div>
              <div>
              <label 
              htmlFor="employee_department_id"
              className='text-xl font-semibold mb-2'>
              Oddelenie zadávateľa:
              </label>
                <select 
                disabled={!user.permissions.includes("edit priorities") ? true : false}
                onChange={(e) => filterEmployees(e.target.value)} value={employeeDepartment}
                name='employee_department_id'
                className='block w-full mb-2  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
              </div>
              <div>
              <label 
              htmlFor='contractor_id'
              className='text-xl font-semibold mb-2'>
              Meno zadávateľa:
              </label>
              <Select showSearch={true} optionFilterProp="children" value={formik.values.contractor_id || ""} onChange={(value) => formik.setFieldValue("contractor_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
              <Select.Option value="">Vyber zadávateľa</Select.Option>
              {employee?.map((employee) => (
                  <Select.Option key={employee.id} value={employee.id}>{employee.name}</Select.Option>
                  ))}
              </Select>
              {formik.errors.contractor_id ? <div style={{color:'red'}}>{formik.errors.contractor_id}</div> : null}
              </div>
              <div>
              <label className='text-xl font-semibold mb-2'>
                Dátum:
                </label>
                <input className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`" type='date' onChange={(e) => console.log(e.target.value)} value={DateTime.now().toFormat("yyyy-MM-dd").toString()} readOnly/>{/*value={DateTime.now().toFormat("d.M.y").toString()}*/}
              </div>
              <div>
              <label 
              htmlFor="offender_department_id"
              className='text-xl font-semibold mb-2'>
              Oddelenie vinníka:
              </label>
                <select required
                onChange={formik.handleChange} value={formik.values.offender_department_id}
                name='offender_department_id'
                className='block w-full mb-2  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
              </div>
            </div>
          </>}
          <button type="submit" disabled={formik.values.state==""?true:false} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť stav</button>
          </form>
          </Dialog>
          <Dialog header="Upraviť problém" visible={visibleUpdateProblem} style={{ width: '60vw' }} onHide={() => hideUpdateProblem()}>
          <div className='grid grid-cols-2 gap-1 px-2'>
            <span className='w-full mb-2 py-1 font-semibold text-xl'>Výrobná zákazka: {priorityForm?.production_order_number}</span>
            <span className='w-full mb-2 py-1 font-semibold text-xl px-2'>Predajná zákazka: {priorityForm?.sales_order_number}</span>
          </div>
          <div className="card flex justify-content-center p-2">
              <Dropdown inputId='state' name="state" value={formikUpdate.values.state} onChange={formikUpdate.handleChange} options={states} optionLabel="name" placeholder="Vyber stav" 
              valueTemplate={selectedStateTemplate} itemTemplate={stateTemplate} className="w-full md:w-14rem border-2 border-black rounded-md"/>
          </div> 
          <form onSubmit={formikUpdate.handleSubmit}>
          {priorityProblem && <>
            <div className='p-2'>
                <label 
                htmlFor="offender_department_id"
                className='text-xl font-semibold mb-2'>
                Oddelenie vinníka:
                </label>
                  <select
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.offender_department_id}
                  disabled={!user.permissions.includes("edit priorities") ? true : false}
                  name='offender_department_id'
                  className='block w-full mb-2  px-4 py-2 mt-4 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                  <option value="">Vyber oddelenie</option>
                  {state.priority_departments
                  //.filter(department => department.id !== priorityForm?.priority_department_id)
                    .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                  </select>
                </div>
            <div className='grid grid-cols-2 gap-2'>
              <div className='flex flex-col p-2'>
                <div className='mb-6'>
                <label 
                htmlFor='problem'
                className='text-xl font-semibold mb-2'>
                Popis:
                </label>
                  <textarea rows={2} cols={30} 
                  name='problem' readOnly
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.problem}
                  className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                  </textarea>
                  {listening && <div>Počúvam...</div>}
                </div>
                <div>
              <label 
              htmlFor="contractor_department_id"
              className='text-xl font-semibold mb-2'>
              Oddelenie zadávateľa:
              </label>
              <select required
                //value={priorityProblem?.contractor_department_id} 
                value={contractorDepartment}
                disabled
                name='contractor_department_id'
                className='block w-full mb-2  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
              </div>
                <div>
                <label 
                htmlFor='contractor_id'
                className='text-xl font-semibold mb-2'>
                Meno zadávateľa:
                </label>
                  <select disabled
                  value={formikUpdate.values.contractor_id}
                  name='contractor_id'
                  className='block w-full mb-2 px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                  <option value="">Vyber zadávateľa</option>
                  {state.priority_departments_employees?.filter((item => item.abbreviation == priorityForm?.priority_department?.abbreviation))[0]?.employees
                  .filter((employee) => employee.date_of_departure == null || (employee.date_of_departure && DateTime.fromISO(employee.date_of_departure) < DateTime.now().toISO()))
                  .map((employee) => (
              <option key={employee.id} value={employee.id}>{employee.name}</option>
              ))}
                  </select>
                </div>
                <div>
                <label className='text-xl font-semibold mb-2'>
                  Dátum:
                  </label>
                  <input name="problem_created_at" value={formikUpdate.values.problem_created_at} readOnly className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" type='date'/>
                </div>
              </div>
              <div className='flex flex-col p-2'>
                <div>
                <label 
                htmlFor='solution'
                className='text-xl font-semibold mb-2'>
                Riešenie:
                </label>
                  <textarea rows={2} cols={30} required
                  name='solution'
                  disabled={!user.permissions.includes("edit priorities") ? true : false}
                  onChange={formikUpdate.handleChange} value={formikUpdate.values.solution}
                  className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                  </textarea>
                  <button type='button'
                    onTouchStart={startListening}
                    onMouseDown={startListening}
                    onTouchEnd={SpeechRecognition.stopListening}
                    onMouseUp={SpeechRecognition.stopListening}
                  >🎤</button>
                  {listening && <div>Počúvam...</div>}
                </div>
                <div>
                <label 
                htmlFor='solver_department_id'
                className='text-xl font-semibold mb-2'>
                Oddelenie riešiteľa:
                </label >
                <select 
                disabled={!user.permissions.includes("edit priorities") ? true : false}
                onChange={(e) => filterSolvers(e.target.value)} value={solverDepartment}
                name='solver_department_id'
                required={(formikUpdate.values.solution!="" && formikUpdate.values.solution!=null) ? true : false}
                className='block w-full mb-2  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`'>
                <option value="">Vyber oddelenie</option>
                {state.priority_departments
                  .map((department) => <option key={department.id} value={department.id}>{department.name}</option>)}
                </select>
                </div>
                <div>
                <label 
                htmlFor='solver_id'
                className='text-xl font-semibold mb-2'>
                Meno riešiteľa:
                </label >
                <Select disabled={!user.permissions.includes("edit priorities") ? true : false} showSearch={true} optionFilterProp="children" value={formikUpdate.values.solver_id || ""} onChange={(value) => formikUpdate.setFieldValue("solver_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                  <Select.Option value="">Vyber riešiteľa</Select.Option>
                  {solver?.map((employee) => (
                      <Select.Option key={employee.id} value={employee.id}>{employee.name}</Select.Option>
                      ))}
                  </Select>
                  {formikUpdate.errors.solver_id ? <div style={{color:'red'}}>{formikUpdate.errors.solver_id}</div> : null}
                  </div>
                <div>
                <label className='text-xl font-semibold mb-2'>
                  Dátum:
                  </label>
                  <input className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`" type='date' onChange={(e) => console.log(e.target.value)} value={formikUpdate.values.solution_created_at} readOnly/>
                  {/*DateTime.now().toFormat("yyyy-MM-dd").toString()*/}
                </div>
              </div>
            </div>
          </>}
          <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť stav</button>
          {user.permissions.includes("edit priorities") && <button type="button" onClick={confirmProblemDelete} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-rose-900 rounded-md hover:bg-rose-700 focus:outline-none focus:bg-rose-600 disabled:opacity-25'>Zmazať problém</button>}
          </form>
          </Dialog>
          <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        {problem_columns.map(function(item, index){ 
          if(index<2 || index >4) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1" 
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked,null)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title?.replace("<br>","/")}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
            {state.priority_departments.map(function(item, index){ 
                        return <div 
                            key={index} 
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox inputId={item.name} name={item.name} value={item.id} checked={chosenNotifications.includes(item.id)}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor={item.name} className="ml-2">{item.name}</label>
                        </div> 
                    })}
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
      </div>
 </>
    )

}