import React, { useContext,useState, useEffect, useRef  } from "react";
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DatabaseContext } from "../../redux/DatabaseStore";
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, CalendarIcon,EditRowIcon} from "../svg/svgIcons";
import { deleteRequest, getRequest,postRequest } from '../../api/ApiController';
import {useReactToPrint} from "react-to-print";
import EightDreport from "../print/EightDreport";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../components/AuthContext";
import moment from "moment";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';


export default function PreviewOTK() {

  const [state,dispatch] = useContext(DatabaseContext);

  const [timePeriod,setTimePeriod] = useState('Všetko');
  const [date,setDate] = useState(null);
  const [dateProps,setDateProps] = useState({hidden:true});

  const [graphData,setGraphData] = useState({
    int_open:0,int_closed:0,ext_open:0,ext_closed:0,rek_open:0,rek_closed:0
  });

  addLocale('sk', {
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dayNames: ['Nedela','Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    dayNamesShort: ['Ne','Pon','Ut','Str','Štv','Pia','So'],
    dayNamesMin: ['Ne','Po', 'Ut', 'Str', 'Štv', 'Pi', 'So'],
    monthNames: ['Január', 'Február', 'Marec', 'Apríl', 'Máj ', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj ', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    today: 'Dnes',
    clear: 'Vymazať'
});

  const componentRef = useRef(null);
  const intRef = useRef(null);
  

    const [intData,setIntData] = useState({
        labels: ['Otvorené', 'Zatvorené'],
        datasets: [{
                data: [state.error_records_int.filter(record => record.state == "Otvorená").length, state.error_records_int.filter(record => record.state == "Zatvorená").length,],
                backgroundColor: ["#b91c1c","#1d4ed8"],
                hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
            }]
    });

    const [extData,setExtData] = useState({
        labels: ['Otvorené', 'Zatvorené'],
        datasets: [{
            data: [state.error_records_ext.filter(record => record.state == "Otvorená").length, state.error_records_ext.filter(record => record.state == "Zatvorená").length,],
            backgroundColor: ["#b91c1c","#1d4ed8"],
            hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
        }]
    })

    const [rekData,setRekData] = useState({
        labels: ['Otvorené', 'Zatvorené'],
        datasets:  [{
            data: [state.error_records_rek.filter(record => record.state == "Otvorená").length, state.error_records_rek.filter(record => record.state == "Zatvorená").length,],
            backgroundColor: ["#b91c1c","#1d4ed8"],
            hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
        }]
    })

    const intOptions = {
        plugins: {
             legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Interná chyba',page:'ZoznamInt',props:{preview: 'open',timePeriod: timePeriod,date: date}});
            }
            else{
                dispatch({type:'create_new_tab',name:'Interná chyba',page:'ZoznamInt',props:{preview: 'closed',timePeriod: timePeriod,date: date}});
            }
        }
    };

    const extOptions = {
        plugins: {
            legend: {
                display:true,
                labels: {
                    font:{size:20},
                    color: '#495057'
                }
            },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Externá chyba',page:'ZoznamExt',props:{preview: 'open',timePeriod: timePeriod,date: date}});
            }
            else{
                dispatch({type:'create_new_tab',name:'Externá chyba',page:'ZoznamExt',props:{preview: 'closed',timePeriod: timePeriod,date: date}});
            }
        }
    };

    const rekOptions = {
        plugins: {
             legend: {
            display:true,
            labels: {
                font:{size:20},
                color: '#495057'
            }
        },
        },
        onClick: function (evt, item) {
            if(item[0].index==0){
                dispatch({type:'create_new_tab',name:'Reklamácia',page:'ZoznamRek',props:{preview: 'open',timePeriod: timePeriod,date: date}});
            }
            else{
                dispatch({type:'create_new_tab',name:'Reklamácia',page:'ZoznamRek',props:{preview: 'closed',timePeriod: timePeriod,date: date}});
            }
        }
    };


    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
            console.log();
            if (chart.data.datasets[0].data[0] + chart.data.datasets[0].data[1] < 1) {
                let ctx = chart.ctx;
                let width = chart.width;
                let height = chart.height;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = "26px Arial";
                ctx.color = "red";
                ctx.fillStyle = "darkgrey";
                ctx.fillText("Bez záznamov", width / 2, height / 2);
                ctx.restore();
              }
        }
      };

    function changeTimePeriod(time_period){
        setTimePeriod(time_period);
        setDate(null);
        if(time_period=="Za deň"){
            setDateProps({dateFormat:"dd.mm.yy"});
        }
        else if(time_period=="Za mesiac"){
            setDateProps({view:"month",dateFormat:"mm.yy"});
        }
        else if(time_period=="Za rok"){
            setDateProps({view:"year",dateFormat:"yy"});
        }
        else if(time_period=="Vlastné"){
            setDateProps({dateFormat:"dd.mm.yy",selectionMode:"range"});
        }
        else{
            setDateProps({hidden:true});
            setIntData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_int.filter(record => record.state == "Otvorená").length, state.error_records_int.filter(record => record.state == "Zatvorená").length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            }); 
            setExtData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                    data: [state.error_records_ext.filter(record => record.state == "Otvorená").length, state.error_records_ext.filter(record => record.state == "Zatvorená").length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });    
            setRekData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_rek.filter(record => record.state == "Otvorená").length, state.error_records_rek.filter(record => record.state == "Zatvorená").length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });
        }
    }

    function showResult(date){
        setDate(date);
        
        if(timePeriod=="Za deň"){
            setIntData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_int.filter(record => record.state == "Otvorená" && new Date(record.created_at).toDateString() == new Date(date).toDateString()).length, state.error_records_int.filter(record => record.state == "Zatvorená" && new Date(record.created_at).toDateString() == new Date(date).toDateString()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            }); 
            setExtData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_ext.filter(record => record.state == "Otvorená" && new Date(record.created_at).toDateString() == new Date(date).toDateString()).length, state.error_records_ext.filter(record => record.state == "Zatvorená" && new Date(record.created_at).toDateString() == new Date(date).toDateString()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });    
            setRekData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_rek.filter(record => record.state == "Otvorená" && new Date(record.created_at).toDateString() == new Date(date).toDateString()).length, state.error_records_rek.filter(record => record.state == "Zatvorená" && new Date(record.created_at).toDateString() == new Date(date).toDateString()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });           
        }
        else if(timePeriod=="Za mesiac"){
            setIntData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_int.filter(record => record.state == "Otvorená" && new Date(record.created_at).getFullYear() == new Date(date).getFullYear() && new Date(record.created_at).getMonth() == new Date(date).getMonth()).length, state.error_records_int.filter(record => record.state == "Zatvorená" && new Date(record.created_at).getFullYear() == new Date(date).getFullYear() && new Date(record.created_at).getMonth() == new Date(date).getMonth()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            }); 
            setExtData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                    data: [state.error_records_ext.filter(record => record.state == "Otvorená" && new Date(record.created_at).getFullYear() == new Date(date).getFullYear() && new Date(record.created_at).getMonth() == new Date(date).getMonth()).length, state.error_records_ext.filter(record => record.state == "Zatvorená" && new Date(record.created_at).getFullYear() == new Date(date).getFullYear() && new Date(record.created_at).getMonth() == new Date(date).getMonth()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });    
            setRekData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_rek.filter(record => record.state == "Otvorená" && new Date(record.created_at).getFullYear() == new Date(date).getFullYear() && new Date(record.created_at).getMonth() == new Date(date).getMonth()).length, state.error_records_rek.filter(record => record.state == "Zatvorená" && new Date(record.created_at).getFullYear() == new Date(date).getFullYear() && new Date(record.created_at).getMonth() == new Date(date).getMonth()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });           
        }
        else if(timePeriod=="Za rok"){
            setIntData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_int.filter(record => record.state == "Otvorená" && new Date(record.created_at).getYear() == new Date(date).getYear()).length, state.error_records_int.filter(record => record.state == "Zatvorená" && new Date(record.created_at).getYear() == new Date(date).getYear()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            }); 
            setExtData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                    data: [state.error_records_ext.filter(record => record.state == "Otvorená" && new Date(record.created_at).getYear() == new Date(date).getYear()).length, state.error_records_ext.filter(record => record.state == "Zatvorená" && new Date(record.created_at).getYear() == new Date(date).getYear()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });    
            setRekData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_rek.filter(record => record.state == "Otvorená" && new Date(record.created_at).getYear() == new Date(date).getYear()).length, state.error_records_rek.filter(record => record.state == "Zatvorená" && new Date(record.created_at).getYear() == new Date(date).getYear()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });           
        }
        else if(timePeriod=="Vlastné"){
            var end_date = new Date(date[1]);
            end_date.setDate(end_date.getDate() + 1);
            setIntData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                        data: [state.error_records_int.filter(record => record.state == "Otvorená" && new Date(record.created_at).valueOf() >= new Date(date[0]).valueOf() && new Date(record.created_at).valueOf() <= new Date(end_date).valueOf()).length, state.error_records_int.filter(record => record.state == "Zatvorená" && new Date(record.created_at).valueOf() >= new Date(date[0]).valueOf() && new Date(record.created_at).valueOf() <= new Date(end_date).valueOf()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            }); 
            setExtData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                    data: [state.error_records_ext.filter(record => record.state == "Otvorená" && new Date(record.created_at).valueOf() >= new Date(date[0]).valueOf() && new Date(record.created_at).valueOf() <= new Date(end_date).valueOf()).length, state.error_records_ext.filter(record => record.state == "Zatvorená" && new Date(record.created_at).valueOf() >= new Date(date[0]).valueOf() && new Date(record.created_at).valueOf() <= new Date(end_date).valueOf()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });    
            setRekData({
                labels: ['Otvorené', 'Zatvorené'],
                datasets: [{
                    data: [state.error_records_rek.filter(record => record.state == "Otvorená" && new Date(record.created_at).valueOf() >= new Date(date[0]).valueOf() && new Date(record.created_at).valueOf() <= new Date(end_date).valueOf()).length, state.error_records_rek.filter(record => record.state == "Zatvorená" && new Date(record.created_at).valueOf() >= new Date(date[0]).valueOf() && new Date(record.created_at).valueOf() <= new Date(end_date).valueOf()).length,],
                        backgroundColor: ["#b91c1c","#1d4ed8"],
                        hoverBackgroundColor: ["#b91c1c","#1d4ed8"]
                    }]
            });           
        }
    }

    return (
        <div className="min-h-[calc(100vh-137px)]">
        <div className="lg:grid grid-cols-3 gap-1">
            <div></div>
            <div>
                <label
                    htmlFor="time_period"
                    className="block text-xl font-semibold text-gray-800 mt-4"
                >
                    Obdobie:
                </label>
                <select 
                name='time_period'
                onChange={(e) => changeTimePeriod(e.target.value)} value={timePeriod}
                className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                    <option value="Všetko">Všetko</option>
                    <option value="Za deň">Za deň</option>
                    <option value="Za mesiac">Za mesiac</option>
                    <option value="Za rok">Za rok</option>
                    <option value="Vlastné">Vlastné</option>
                </select>
            </div>
            <div></div>
            <div></div>
            <div className="flex-auto">
                <Calendar id="buttondisplay" style={{border: '1px solid #e5e7eb',transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',borderRadius: '6px'}} 
                value={date} onChange={(e) => showResult(e.target.value)} showIcon className="w-full" visible={true} locale="sk" {...dateProps} hideOnRangeSelection />
            </div>
            <div></div>
        </div>
      <div className="lg:grid grid-cols-3 gap-1">
        <div>
            <h1 className="flex items-center justify-center text-3xl font-semibold mt-8">Interné chyby</h1>
            <Chart type="doughnut" data={intData} options={intOptions} className="flex items-center justify-center w-full md:w-30rem" ref={intRef} plugins={[plugin]} />
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {intData.datasets[0].data[0] + intData.datasets[0].data[1]}</span>
        </div>
        <div>
            <h1 className="flex items-center justify-center text-3xl font-semibold mt-8">Externé chyby</h1>
            <Chart type="doughnut" data={extData} options={extOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]}/>
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {extData.datasets[0].data[0] + extData.datasets[0].data[1]}</span>
        </div>
        <div>
            <h1 className="flex items-center justify-center text-3xl font-semibold mt-8">Reklamácie</h1>
            <Chart type="doughnut" data={rekData} options={rekOptions} className="flex items-center justify-center w-full md:w-30rem" plugins={[plugin]} />
            <span className="flex flex-wrap justify-center font-semibold content-end">Celkový počet: {rekData.datasets[0].data[0] + rekData.datasets[0].data[1]}</span>
        </div>
      </div>
</div>
    );
}
