import React,{useState} from 'react';
import { useFormik } from 'formik';
import {axiosToken,authPostRequest,getRequest, google2FARequest} from './../../api/ApiController';


function Register() {

    const [showRegister, setShowRegister] = useState(true);
    const [showQrCode, setShowQrCode] = useState(false);
    const [showAfterRegistration, setShowAfterRegistration] = useState(false);

    const [qrCode, setQrCode] = useState('');
    const [secretCode, setSecretCode] = useState('');
    
    const handleSave = (values) => {
        console.log(values);
        values["google2fa_secret"] = secretCode;
        authPostRequest('/api/auth/completeRegistration', JSON.stringify(values))
            .then((response) => {
                console.log("DOTAZ SA PODARIL");
                console.log(response);
                setShowRegister(false);
                setShowQrCode(false);
                setShowAfterRegistration(true);
                //funShowTable();
            }).catch((reason) => {
                alert("Daný úkon sa nepodaril!");
                //funShowTable();
            })

        formik.resetForm();
    }


    /* validate form */
    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = '*Povinné';
        }else if(values.name.length < 5){
            errors.name = '*Aspoň 5 znakov';
        }
        return errors;
    };

    /* form for add customer */
    const formik = useFormik({
        initialValues: {
            name: '',email: '',password:'',role:'user',password_confirmation:''
        },
        validate,
        onSubmit: values => {

            authPostRequest('/api/auth/register', JSON.stringify(values))
            .then((response) => {
                //formik.resetForm();
                // break the textblock into an array of lines
                var lines = response.data.QR_Code.split('\n');
                // remove one line, starting at the first position
                lines.splice(0,1);
                // join the array back into a single string
                var newtext = lines.join('\n');
                setQrCode(newtext);
                setSecretCode(response.data.google2fa_secret);
                setShowRegister(false);
                setShowQrCode(true);
                //funShowTable();
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
                //funShowTable();
            })
        },
    });

return (
<>
<div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
    <div className='w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl'>
    {showRegister &&
    <form onSubmit={formik.handleSubmit} className='mt-6'>
        <h2 className='text-3xl font-semibold text-center text-zinc-900 '>Registrácia:</h2>
        <div className='mb-2'>
            <label htmlFor="name" className="block text-sm font-semibold text-gray-800">Meno</label>
            <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="name" name="name" type="text" onChange={formik.handleChange} value={formik.values.name}/>
            {formik.errors.name ? <div style={{color:'red'}}>{formik.errors.name}</div> : null}
        </div>
        <div className='mb-2'>
            <label htmlFor="email" className="block text-sm font-semibold text-gray-800">Email</label>
            <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="email" name="email" type="email" onChange={formik.handleChange} value={formik.values.email}/>
            {formik.errors.email ? <div style={{color:'red'}}>{formik.errors.email}</div> : null}
        </div>
        <div className='mb-2'>
            <label htmlFor="password" className="block text-sm font-semibold text-gray-800">Heslo</label>
            <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="password" name="password" type="password" onChange={formik.handleChange} value={formik.values.password}/>
            {formik.errors.password ? <div style={{color:'red'}}>{formik.errors.password}</div> : null}
        </div>
        <div className='mb-2'>
            <label htmlFor="password_confirmation" className="block text-sm font-semibold text-gray-800">Potvrdenie hesla</label>
            <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="password_confirmation" name="password_confirmation" type="password" onChange={formik.handleChange} value={formik.values.password_confirmation}/>
            {formik.errors.password_confirmation ? <div style={{color:'red'}}>{formik.errors.password_confirmation}</div> : null}
        </div>
        <div className='mb-2'>
            <label htmlFor="role" className="block text-sm font-semibold text-gray-800">Používateľská rola</label>
            <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="role" name="role" type="text" onChange={formik.handleChange} value={formik.values.role}/>
            {formik.errors.role ? <div style={{color:'red'}}>{formik.errors.role}</div> : null}
        </div>
        <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600" type="submit">Registrovať</button>
    </form>}
                {showQrCode && <>
                <div className="relative flex flex-col items-center min-h-min overflow-hidden">
                    <h1 className='text-center font-semibold text-xl'>Nastavenie Google Autentifikátora</h1>
                    <p>Nastavte vašu dvojfaktorovú autentifikáciu oskenovaním čiarového kódu.</p>
                    <div>
                        <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} />
                    </div>
                    <p>Alebo, môžte využiť kľúč nastavenia: </p>
                    <p className='font-bold mb-4'>{secretCode}</p>
                    <p>Je potrebné nastaviť Google Autentifikátor pre dokončenie registrácie.</p>
                    <p className='mb-2'>Inak nebude možné sa prihlásiť!</p>
                    <div>
                        <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600" onClick={() => handleSave(formik.values)}>Dokončiť registráciu!</button>
                    </div>
                </div></>}
                {showAfterRegistration && <>
                <div className="relative flex flex-col items-center min-h-min overflow-hidden">
                    <h1 className='text-center font-semibold text-xl'>Používateľ úspešné zaregistrovaný!</h1>
                </div></>}
    </div>
</div>
                </>
)
}

export default Register;