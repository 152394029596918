import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, DeleteIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon} from "../../svg/svgIcons";
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Chips } from 'primereact/chips';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';

export default function TrainingTemplateAdd({training_template, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [formTrainingTemplate,setformTrainingTemplate] = useState(training_template);
    const [formType, setFormType] = useState(type);


    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);

    function addNewTrainingType(){
        dispatch({type:'create_new_tab',name:'Nová šablóna školenia',page:'TrainingTemplateAdd',props:{training_template: null,type:'add'},mask:'skol008'})
    }
    function copyTrainingType(){
        dispatch({type:'create_new_tab',name:'Nová šablóna školenia',page:'TrainingTemplateAdd',props:{training_template: formik.values,type:'copy'},mask:'skol008'})
    }

    useEffect(() => {
        // Update the document title using the browser API
        if(formTrainingTemplate!=null){
            if(user.permissions.includes("edit training"))setScrollIcons(false);
        }
    },[formTrainingTemplate]);

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formTrainingTemplate==null?{
            name:'',department_id:'',description:''
        }:formTrainingTemplate,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            if(formType=="add"){
                postRequest('/api/training_templates', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/training_templates").then((response) => {
                        dispatch({type:'load_training_templates',newTrainingTemplates:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/training_templates/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/training_templates").then((response) => {
                        dispatch({type:'load_training_templates',newTrainingTemplates:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(training_company_id) {
        return state.training_templates.findIndex(obj => obj.id === training_company_id);
    }

    async function scrollToFirst(){
        let my_training_type = state.training_templates[0];
        setformTrainingTemplate(my_training_type);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setformTrainingTemplate(state.training_templates[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.training_templates.length){
            setformTrainingTemplate(state.training_templates[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.training_templates.length-1;
        let my_training_type = state.training_templates[last];
        setformTrainingTemplate(my_training_type);
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTrainingType()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTrainingType()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová typ školenia","TrainingTypeAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingTypeAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÁ ŠABLÓNA ŠKOLENIA
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Názov šablóny
                            </label>
                            <input 
                                name='name'
                                type="text" required
                                onChange={formik.handleChange} value={formik.values.name}
                                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="department_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Oddelenie šablóny
                            </label>
                            <select required
                            name='department_id'
                            onChange={formik.handleChange} value={formik.values.department_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber oddelenie</option>
                            {state.departments.map((department) => (
                                <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                            </select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>     
                        <button className='hidden' type='submit' ref={submitRef}></button>
            </div>
            </div>
            </div> 
             </form>
            </div>
            </> 
            );
} 
