import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, InviteDate, InviteTime, InviteLocation, TrainingTheme} from "../../svg/svgIcons";
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { InputTextarea } from 'primereact/inputtextarea';;

export default function TrainingInvitationTemplateAdd({template,type}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const ref = useRef(null);
    const contentRef = useRef(null);
    const [formType,setFormType] = useState(type ? type : "add");

    const [trainingsMarked, setTrainingsMarked] = useState({});
    const [markUnmark, setMarkUnmarked] = useState(true);

    const [formTemplate,setFormTemplate] = useState(template);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState([]);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);

    const [fileList, setFileList] = useState([]);

    function addNewTemplate(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationTemplateAdd',props:{training: null,type:'add'},mask:'skol003'})
    }
    function copyTemplate(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationTemplateAdd',props:{training: formik.values,type:'add'},mask:'skol003'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formTemplate==null?{
            name: '',description: '',content:'',}
            :formTemplate,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
            formData.append(value, values[value] != null ? values[value] : "");
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/
           if(formType=="add"){
                postRequest('/api/training_invitation_templates', formData,true)
                .then((response) => {
                    setFormType("edit");
                    getRequest("/api/training_invitation_templates").then((response) => {
                        dispatch({type:'load_training_invitation_templates',newTrainingInvitationTemplates:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/training_invitation_templates/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/training_invitation_templates").then((response) => {
                        dispatch({type:'load_training_invitation_templates',newTrainingInvitationTemplates:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function insertMyText(textToInsert){
        let cursorPosition = contentRef.current.selectionStart;
        console.log(contentRef.current.selectionStart);
        let textBeforeCursorPosition = contentRef.current.value.substring(0, cursorPosition);
        let textAfterCursorPosition = contentRef.current.value.substring(cursorPosition, contentRef.current.value.length);
        contentRef.current.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
        contentRef.current.selectionStart = contentRef.current.selectionStart + textToInsert.length;
        contentRef.current.focus();
        console.log(contentRef.current.selectionStart,textToInsert.length);
      }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTemplate()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTemplate()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová pozvánka","TrainingInvitationTemplateAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingInvitationTemplateAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÝ VZOR POZVÁNKY
                </h1>
                        <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov
                        </label>
                        <input
                            name='name'
                            type="text" required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis 
                        </label>
                        <textarea
                            cols="30"
                            rows="2"
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>
                        <div>
                        <label
                            htmlFor="content"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Obsah pozvánky 
                        </label>
                        <div className='w-full h-12 flex flex-row justify-start gap-1 px-1 py-1 text-white'>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Dátum]")}><InviteDate/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Čas]")}><InviteTime/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Miesto]")}><InviteLocation/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("[Názov školenia]")}><TrainingTheme/></button></div>
                        </div>
                        <InputTextarea autoResize name='content' required onChange={formik.handleChange} value={formik.values.content} rows={5} cols={30} className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <button type='submit' ref={submitRef}></button>
            </div>
            </div>
            </div>
             </form>
            </div>
            </> 
            );

}