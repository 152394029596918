import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from "../../../components/UtilContext";

export default function PaintingTimeConditionAdd({painting_time_condition, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {toast} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);

    const [formTimeCondition,setFormTimeCondition] = useState(painting_time_condition);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add painting"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit painting"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);

    function addNewPaintingCondition(){
        dispatch({type:'create_new_tab',name:'Nové časové podmienky',page:'PaintingTimeConditionAdd',props:{painting_time_condition: null,type:'add'},mask:'lak005'})
    }

    function copyPaintingCondition(){
        dispatch({type:'create_new_tab',name:'Nové časové podmienky',page:'PaintingTimeConditionAdd',props:{painting_time_condition: formik.values,type:'copy'},mask:'lak005'})
    }

    const validate = values => {
        const errors = {};
        if(values.name.length<5)errors.name = "Príliš krátky názov!";
        return errors;
    };

    const formik = useFormik({
        initialValues: formTimeCondition==null?{
            name: '',from:'',to:'',
        }:formTimeCondition,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            if(formType=="add"){
                postRequest('/api/painting_time_conditions', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/painting_time_conditions").then((response) => {
                        dispatch({type:'load_painting_time_conditions',newPaintingTimeConditions:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/painting_time_conditions/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/painting_time_conditions").then((response) => {
                        dispatch({type:'load_painting_time_conditions',newPaintingTimeConditions:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });


    function getIndex(painting_time_condition_id) {
        return state.painting_conditions.findIndex(obj => obj.id === painting_time_condition_id);
    }

    async function scrollToFirst(){
        let my_painting_condition = state.painting_time_conditions[0];
        setFormTimeCondition(my_painting_condition);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormTimeCondition(state.painting_time_conditions[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.painting_intervals.length){
            setFormTimeCondition(state.painting_time_conditions[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.painting_intervals.length-1;
        let my_painting_condition = state.painting_time_conditions[last];
        setFormTimeCondition(my_painting_condition);
    }

    return (

        <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewPaintingCondition()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyPaintingCondition()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col justify-top overflow-x min-h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-3 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-lg">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ČASOVÉ PODMIENKY
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            for="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV PODMIENKY
                        </label>
                        <input
                            name='name'
                            type="text"
                            required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        {formik.errors.name ? <div style={{color:'red'}}>{formik.errors.name}</div> : null}
                        </div>
                        <div>
                        <label
                            for="from"
                            className="block text-md font-semibold text-gray-800"
                        >
                            OD
                        </label>
                        <input
                            name='from'
                            type="time"
                            min="05:00" max="22:00"
                            required
                            onChange={formik.handleChange} value={formik.values.from}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            for="to"
                            className="block text-md font-semibold text-gray-800"
                        >
                            DO
                        </label>
                        <input
                            name='to'
                            type="time"
                            min="05:00" max="22:00"
                            required
                            onChange={formik.handleChange} value={formik.values.to}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                </div>              
            </div>
            </div>
            <button type="submit" ref={submitRef} className='hidden'></button>          
            </form>
            </div>
            );
} 
