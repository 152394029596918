import React,{ useContext } from "react";
import { Navigate,useLocation } from "react-router-dom";
import AuthContext from "./AuthContext";

/* checking whether the user is logged in and his access to the application */
const ProtectedRoute = ({ children, accessBy }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  /* unlogged user */
  if (accessBy === "non-authenticated") {
    if (!user) {
      return children;
    }
    if (user) {
      if(location)return <Navigate to={`/openTab/${location.search}`}></Navigate>;
      return <Navigate to="/"></Navigate>;
    }
  } 
  /* only logged user */
  else if (accessBy === "authenticated") {
    if (user) {
      return children;
    }
    /* otherwise he must log in */
    if (!user) {
      if(location)return <Navigate to={`/login/${location.search}`}></Navigate>;
      return <Navigate to="/login"></Navigate>;
    }
  }
};
export default ProtectedRoute;
