import React,{useContext,useEffect, useState} from 'react';
import { useFormik } from 'formik';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { DateTime } from "luxon";
import { Input } from 'antd';

const { TextArea } = Input;

export default function EightDreport({error_record}) {

    const [state,dispatch] = useContext(DatabaseContext);

    const [formErrorRecord,setFormErrorRecord] = useState(error_record==null?{
        type: 'Interná chyba',article_number:'',article_name:'',order_number:'',customer_id:'',offender_id:'',repair_costs:'',total_amount:'',
        defective_pieces:'',group_id:'',ack_error:'',claim_counterparty_number:'',error_type_id:'',contractor_note:'',record_processor_id:'',
        problem_description:'',main_reason:'',immediate_measure:'',long_term_measure:'',prevention_id:'',auditor_id:'',auditor_note:'',
        state:'Otvorená',created_at:'',id:'',specific_id:''
    }:error_record);

    return(
        <>
        <div className="relative flex flex-col justify-center items-center overflow-x">
            <div className="w-fit p-6 bg-gray-100 rounded-md shadow-md">
                <div className="grid grid-cols-2 gap-1">
                  <div className="mt-6">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   8D - Report
                </h1>
                </div>
                <div className="flex justify-end items-right">
                  <img className="h-28 w-auto" src={require('./../../assets/img/logo-legro.png')} alt="Legro Logo"/>
                  </div>
                  </div>
                <div className="mt-4">
                    <div className="grid grid-cols-2 gap-1 mb-2">
                      <div>
                        <label
                            htmlFor="article_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poradové číslo chyby / Fehlerfolgenummer:
                        </label>
                        <input
                            name='article_number'
                            type="text" 
                            value={formErrorRecord.specific_id} readOnly={true}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="customer_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zákazník / Kunde:
                        </label>
                        <input
                            name='customer_id'
                            type="text"
                            value={state.customers.filter(customer => customer.id === formErrorRecord.customer_id).map(item => item.name)} readOnly={true}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="claim_counterparty_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Č. reklamácie / Reklamation Nr.:
                        </label>
                        <input
                            name='claim_counterparty_number'
                            type="text"
                            value={formErrorRecord.claim_counterparty_number} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="article_name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov výkresu / Art Nr.:
                        </label>
                        <input
                            name='article_name'
                            type="text"
                            value={formErrorRecord.article_name} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="total_amount"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Počet dodaných dielov / Anzahl gelieferte Teilen:
                        </label>
                        <input
                            name='total_amount'
                            type="number"
                            value={formErrorRecord.total_amount} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="defective_pieces"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Počet zlých dielov / Anzahl schlechte Teilen:
                        </label>
                        <input
                            name='defective_pieces'
                            type="text"
                            value={formErrorRecord.defective_pieces} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-2">
                      <div>
                        <label
                            htmlFor="error_type_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                             Druh chyby / Art von Fehler:
                        </label>
                        <input
                            name='error_type_id'
                            type="text"
                            value={state.error_types.filter(error_type => error_type.id === formErrorRecord.error_type_id).map(item => item.name)} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="created_at"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum / Datum:
                        </label>
                        <input
                            name='created_at'
                            type="text"
                            value={DateTime.fromISO(formErrorRecord.created_at).toFormat("dd.MM.yyyy HH:mm")} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="record_processor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            1. Spracovateľ reklamácie / Reklamationsbearbeitungteam:
                        </label>
                        <input
                            name='record_processor_id'
                            type="text"
                            value={state.employees.filter(employee => employee.id === formErrorRecord.record_processor_id).map(item => item.name)} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="state"
                            className="block text-md font-semibold text-gray-800"
                        >
                            6. Stav reklamácie / Reklamationsstand:
                        </label>
                        <input
                            name='state'
                            type="text"
                            value={formErrorRecord.state} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div> 
                    <div className="grid grid-cols-2 gap-4 mb-2">
                    <div>
                        <label
                            htmlFor="problem_description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            2. Popis problému / Problembeschreibung:
                        </label>
                        <textarea
                            name='problem_description'
                            value={formErrorRecord.problem_description} readOnly={true}
                            style={{fieldSizing: 'content'}}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="main_reason"
                            className="block text-md font-semibold text-gray-800"
                        >
                            3. Hlavná príčina / Hauptursache:
                        </label>
                        <textarea
                            name="main_reason" type="text" 
                            value={formErrorRecord.main_reason} readOnly={true}
                            style={{fieldSizing: 'content'}}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="immediate_measure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            4. Okamžité opatrenie / Sofortmaßnahme:
                        </label>
                        <textarea
                            name='immediate_measure'
                            value={formErrorRecord.immediate_measure} readOnly={true}
                            style={{fieldSizing: 'content'}}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="long_term_measure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            5. Dlhodobé opatrenie / Dauerhafte Abstellmaßnahme:
                        </label>
                        <textarea
                            name='long_term_measure'
                            value={formErrorRecord.long_term_measure} readOnly={true}
                            style={{fieldSizing: 'content'}}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="prevention_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            7. Zabránenie opakovania skrz / Verhinderung von Wiederholungsfälle durch: 
                        </label>
                        <input
                            name='prevention_id'
                            type="text"
                            value={state.preventions.filter(prevention => prevention.id === formErrorRecord.prevention_id).map(item => item.name)} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="auditor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            8. Konečná kontrola skrz QM / Abschlussprüfung der eingeleiteten Maßnahmen durch QM:
                        </label>
                        <input
                            name='auditor_id'
                            type="text"
                            value={state.employees.filter(employee => employee.id === formErrorRecord.auditor_id).map(item => item.name)} readOnly={true}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        </div>
                        <div>
                        <label
                            htmlFor="auditor_note"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Poznámka / Bemerkung:
                        </label>
                        <textarea
                            name='auditor_note'
                            value={formErrorRecord.auditor_note} readOnly={true}
                            style={{fieldSizing: 'content'}}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                </div>           
            </div>
    </div>
        </>
    )
}