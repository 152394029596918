import React,{useContext,useState,useEffect} from 'react';
import AuthContext from '../components/AuthContext';
import { postRequest } from '../api/ApiController';

function ProfilePage() {

  const { user } = useContext(AuthContext);
  const [showQrCode, setShowQrCode] = useState(false);

  const [qrCode, setQrCode] = useState('');
  const [secretCode, setSecretCode] = useState('');

  const [OTP, setOTP] = useState(user.otp_enabled);

  const disableOTP = () => {
    postRequest('/api/auth/disable2FA', [])
    .then((response) => {
        if(response.status < 400){
            console.log("OTP DISABLE");
            setShowQrCode(false);
            setOTP("disabled");
            user.otp_enabled = "disabled";
        }
        else{
            console.log("OTP UNABLE TO DISABLE");
        }
    }).catch((reason) => {
        console.log(reason);
    })
  } 

  const enableOTP = () => {
    postRequest('/api/auth/enable2FA', [])
    .then((response) => {
        if(response.status < 400){
            console.log("OTP ENABLE");
            var lines = response.data.QR_Code.split('\n');
            lines.splice(0,1);
            var newtext = lines.join('\n');
            setQrCode(newtext);
            setSecretCode(response.data.google2fa_secret);
            setShowQrCode(true);
            setOTP("enabled");
            user.otp_enabled = "enabled";
        }
        else{
            console.log("OTP UNABLE TO ENABLE");
        }
    }).catch((reason) => {
        console.log(reason);
    })
  } 

return (
<div className="flex flex-col bg-gray-100 rounded-md shadow-md px-4 py-4 h-[calc(100vh_-_137px)] relative">
        <div>
            <h2 className="text-xl font-bold px-1 py-1 mb-2">MÔJ PROFIL</h2>
        </div>
  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
      <div className="overflow-hidden">
      <div className="md:grid grid-cols-1 gap-1 mb-2">
                      <div>
                        <label
                            htmlFor="otp"
                            className="block text-md font-semibold text-gray-800"
                        >
                            2-faktorová autorizácia: {OTP!==undefined? OTP.toUpperCase() : null}
                        </label>{
                          OTP=="enabled" ? 
                        
                        (<button onClick={() => disableOTP()} className='px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>DISABLE</button>)
                        :
                        (<button onClick={() => enableOTP()} className='px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ENABLE</button>)
                        }
                        </div>
                        {showQrCode && <>
                <div className="relative flex flex-col items-center min-h-min overflow-hidden">
                    <h1 className='text-center font-semibold text-xl'>Nastavenie Google Autentifikátora</h1>
                    <p>Nastavte vašu dvojfaktorovú autentifikáciu oskenovaním čiarového kódu.</p>
                    <div>
                        <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} />
                    </div>
                    <p>Alebo, môžte využiť kľúč nastavenia: </p>
                    <p className='font-bold mb-4'>{secretCode}</p>
                    <p>Je potrebné nastaviť Google Autentifikátor pre ďalšie prihlasovanie</p>
                </div></>}
                    </div>
      </div>
    </div>
  </div>
</div>
    )
}

export default ProfilePage;