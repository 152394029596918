import React,{useState} from 'react';

import DatabaseStore from './redux/DatabaseStore';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Login from './pages/auth/Login';
import Register from './pages/google2fa/Register';
import { AuthContextProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';     
import 'react-tabulator/lib/styles.css';                 
import 'react-tabulator/css/tabulator_bootstrap5.css';
import { UtilContextProvider } from './components/UtilContext';



function App() {
  return (
    <AuthContextProvider>
      <PrimeReactProvider>
      <DatabaseStore>
      <UtilContextProvider>
          <Routes>
            <Route path='/' element={<ProtectedRoute accessBy="authenticated"><MainPage/></ProtectedRoute>}/>
            <Route path='/openTab/' element={<ProtectedRoute accessBy="authenticated"><MainPage/></ProtectedRoute>}/>
            <Route path='/login/' element={<ProtectedRoute accessBy="non-authenticated"><Login/></ProtectedRoute>}/>
            {/*<Route path='/register' element={<ProtectedRoute accessBy="non-authenticated"><Register/></ProtectedRoute>}/>*/}
          </Routes>
      </UtilContextProvider>
      </DatabaseStore>
      </PrimeReactProvider>
    </AuthContextProvider>
  )

}

export default App;
