import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../../api/ApiController';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../../assets/css/style.css';
import '../../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from '../../../components/AuthContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../../components/UtilContext";
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';


export default function PaintingIntervalList(){

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible,setUserMaskFilter} = useContext(UtilContext);

    const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("edit painting"));
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [columnsVisible, setColumnsVisible] = useState({type: true,name: true,name:true,state:true,
    });
    const [maskVisible, setMaskVisible] = useState(false);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [editFormPaintingInterval, setEditFormPaintingInterval] = useState([]);

    const ref = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });


    useEffect(() => {
      // Update the document title using the browser API
      const isFound = state.favorites.some(favorite => {
        if (favorite.page === 'PaintingIntervalList') {
          return true;
        }
        return false;
      });
      
      if (isFound) {
        setFavoriteIcon(true);
      }
    });

  
  
  function matchAny(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    //RegExp - modifier "i" - case insensitve

    var match = false;
    const regex = RegExp(filterParams.value, 'i');

    for (var key in data) {
        if (regex.test(data[key]) === true) {
            match = true;
        }
    }
    return match;
  }

  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

    const accept = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/painting_intervals/${element.id}`)
              .then((response) => {
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/painting_intervals").then((response) => {
                  dispatch({type:'load_painting_intervals',newPaintingIntervals:response.data});
              });
              }).catch((reason) => {
                  alert(reason);
                  toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
        //toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
    }

    const reject = () => {
        toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Áno',
            rejectLabel: 'Nie',
            accept,
            reject
        });
    };

  const rowClick = (e, row) => {
    console.log(e, row);
 };

  const onSelectRow = (data, rows) => {
    setEditFormPaintingInterval(data);
    setEditIcon(true);
    setCopyIcon(true);
    setDeleteIcon(true);
    if(data.length !== 0){
      if(user.permissions.includes("edit painting"))setCopyIcon(false);
      if(user.permissions.includes("edit painting"))setEditIcon(false);
      if(user.permissions.includes("delete painting"))setDeleteIcon(false);
    }
  };

  function onEditRow(painting_interval){
    dispatch({type:'create_new_tab',name:'Upraviť interval lakovne',page:'PaintingIntervalAdd',props:{painting_interval: painting_interval,type:'edit'},mask:'lak009'})

  };
  
  function EditRowFormatter(props) {
    const rowData = props.cell._cell.row.data;
    return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
  }

  const columns = [
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
    { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Typ intervalu', field: 'type',editor: "input",headerFilter:"input"},
    { title: 'Názov intervalu', field: 'name',headerFilter:"input" },
    { title: 'Stav intervalu', field: 'state',headerFilter:"input" },
  ];

  async function scrollToFirst(){
    let res = state.painting_intervals[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }

  async function scrollUp(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    if((scrollValue-visible_rows+1) >= 0){
      let res = state.painting_intervals[scrollValue-visible_rows+1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue-visible_rows);
    }
    else if(scrollValue > 0 && scrollValue <= visible_rows){
      let res = state.painting_intervals[0].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(1);
    }
  }

  async function scrollDown(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    if(scrollValue===1){
      let res = state.painting_intervals[scrollValue+visible_rows-1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue+visible_rows);
    }
    else if((scrollValue+visible_rows) <= rows){
      let res = state.painting_intervals[scrollValue+visible_rows-1].id;
      let rows = ref.current.getRows(true).length;
      setScrollValue(scrollValue+visible_rows);
      if(res > rows-visible_rows){
        res = state.painting_intervals[rows-visible_rows].id;
        setScrollValue(rows-visible_rows);
      }
      ref.current.scrollToRow(res, "top", true);
    }
  }

  async function scrollToLast(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    let res = state.painting_intervals[rows-visible_rows].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(rows-visible_rows);
  }
  function addNewPaintingInterval(){
    dispatch({type:'create_new_tab',name:'Nový interval lakovne',page:'PaintingIntervalAdd',props:{painting_interval: null,type:'add'},mask:'lak009'})
  }
  function copyPaintingInterval(){
    dispatch({type:'create_new_tab',name:'Nový interval lakovne',page:'PaintingIntervalAdd',props:{painting_interval: ref.current.getSelectedData()[0],type:'copy'},mask:'lak009'})
  }
  function editPaintingInterval(){
    dispatch({type:'create_new_tab',name:'Upraviť interval lakovne',page:'PaintingIntervalAdd',props:{painting_interval: ref.current.getSelectedData()[0],type:'edit'},mask:'lak009'})
  }

  function confirmFavoriteDialog(){
    if(favoriteGroupValue==""){
      alert("Vyberte skupinu!");
      return;
    }
    else if(favoriteGroupValue=="nova"){
      if(favoriteValue==""){
        alert("Napíšte názov novez skupiny!");
        return;
      }
      else{
        //VYTVORIT SKUPINU AJ FAVORITA
        setFavoriteVisible(false);
        setVisibleLoading(true);
        let formData = new FormData();
        let new_group;
        formData.append("name", favoriteValue);
        postRequest(`/api/favorite_groups`, formData,true)
        .then((response) => {
          let formData2 = new FormData();
          formData2.append("name", "Intervaly lakovne");
          formData2.append("page", "PaintingIntervalList");
          formData2.append("favorite_group_id", response.data.id);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
              if(!navigator.online){
                  alert("Ste offline, dáta budu odoslané po znovupripojení!");
              }
              else{
                  alert("Daný úkon sa nepodaril!");
              }
          })
        }).catch((reason) => {
            setVisibleLoading(false);
            if(!navigator.online){
                alert("Ste offline, dáta budu odoslané po znovupripojení!");
            }
            else{
                alert("Daný úkon sa nepodaril!");
            }
        })
      }
    }
    else{
      //VYTVORIT FAVORITA a pridat do skupiny
      setFavoriteVisible(false);
      setVisibleLoading(true);
      let formData2 = new FormData();
      formData2.append("name", "Intervaly lakovne");
      formData2.append("page", "PaintingIntervalList");
      formData2.append("favorite_group_id", favoriteGroupValue);
      postRequest(`/api/favorites`, formData2,true)
      .then((response) => {
          getRequest("/api/my_favorite_groups").then((response) => {
            dispatch({type:'load_favorites',newFavorites:response.data});
            setVisibleLoading(false);
        });
      }).catch((reason) => {
          setVisibleLoading(false);
          if(!navigator.online){
              alert("Ste offline, dáta budu odoslané po znovupripojení!");
          }
          else{
              alert("Daný úkon sa nepodaril!");
          }
      })
    }
  }

  function setMask(item,value){
    const columnVisible = {...columnsVisible};
   columnVisible[item] = value;
   console.log(columnVisible);
    for(const val in columnVisible) {
      if(columnVisible[val]==false){
        ref.current.hideColumn(`${val}`);
      }
      else{
        ref.current.showColumn(`${val}`);
      }
    }
    setColumnsVisible(columnVisible);
  }

  function changeMarkings(value){
    if(value){
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=true;
          ref.current.showColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
    else{
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=false;
          ref.current.hideColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
  }

  const itemRenderer = (item, options) => (
    <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
        <span className={`${item.icon} text-primary`} />
        <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
  );

  const [expandedKeys, setExpandedKeys] = useState({});

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setExpandedKeys({});
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

    const items = [
    {
      key: '0',
      label: 'Akcie',
      icon: 'pi pi-sliders-v',
      template: itemRenderer,
      items: [
        {
          key: '0_1',
          label: 'Nastaviť filter',
          icon: 'pi pi-filter',
          command: () => {setUserMaskFilter("PaintingIntervalList","set_filters",null,ref.current.getHeaderFilters(),null);},
          template: itemRenderer,
      },
        {
          key: '0_2',
          label: 'Zrušiť filter',
          icon: 'pi pi-filter-slash',
          command: () => {setUserMaskFilter("PaintingIntervalList","set_filters",null,[],null);ref.current.clearHeaderFilter();},
          template: itemRenderer,
      },
      {
          key: '0_3',
          label: 'Nastaviť masku',
          icon: 'pi pi-server',
          command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
          template: itemRenderer,
      },
      {
        key: '0_4',
        label: 'Notifikácie',
        icon: 'pi pi-envelope',
        command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
        template: itemRenderer,
    },
      ]
    }
  ];

  function showChosenNotifications(){
    let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
    if(db_chosen_notifications?.length>0){
      setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
    }
    else{
      setChosenNotifications([]);
    }
    setNotificationVisible(true);
  }

  function saveChosenNotifications(){
      let formData = new FormData();
      formData.append("type","otk_int");
      formData.append("data", JSON.stringify(chosenNotifications));
      postRequest('/api/chosen_notifications', formData,true)
      .then((response) => {
        if(response.status == 201){
          dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
          if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
          else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });  
        }else{
          if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
          else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });  
        }
      }).catch((reason) => {
            console.log(reason);
            alert("Daný úkon sa nepodaril!");
      });
      setNotificationVisible(false);
  }

    return(
      <>
      <div className='w-full h-10 flex flex-row bg-[#333]'>
      <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewPaintingInterval()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editPaintingInterval()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyPaintingInterval()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="search"><button onClick={() => setVisibleSearch(true)}><SearchIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="export"><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Intervaly","PaintingIntervalList")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="price"><button onClick={() => setFastView("PaintingIntervalList",columnsVisible,columns)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className="card flex justify-content-center z-[100] " data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
        </IntersectionObserverWrapper>
      </div>
<div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    <div ref={elementRef}>
      <ReactTabulator
      data={state.painting_intervals} 
      className='h-[calc(100vh_-_19,px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
          let mask = state.user_mask_filters.filter(mask => mask.page=="PaintingIntervalList");
          if(mask.length>0){
            let attributes = [];
            try {
              attributes = JSON.parse(mask[0].attributes)
            } catch (e) {
              attributes = []
            }
            if(attributes?.length>1)ref.current.setColumnLayout(attributes);
            let filters = [];
            try {
              filters = JSON.parse(mask[0].filters);
            } catch (e) {
              filters = []
            }
            filters.forEach(filter => {
              ref.current.setHeaderFilterValue(filter.field,filter.value);
            });
            let json_columns;
            try {
              json_columns = JSON.parse(mask[0].columns);
              setColumnsVisible(json_columns);
            } catch (e) {
              json_columns = []
            }
            for(let column in json_columns){
              if(json_columns[column]==false)ref.current.hideColumn(column);
              else ref.current.showColumn(column);
            }
          }
        },
        columnMoved:() => {
          setUserMaskFilter("PaintingIntervalList","column_move",null,null,ref.current.getColumnLayout());
        },
      }}
      options={{layout:"fitColumns",rowHeight:30,
      printAsHtml:true,printHeader:"<h1>Zoznam intervalov lakovne<h1>",printRowRange:"active",
      autoResize:true}}/> 
    </div> 
  </div> 
</div> 
</div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
    <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
      <div className="flex flex-columns">
        <div className="flex-row">
        <select 
        value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
          <option  value="">Vyberte možnosť</option> 
          {state.favorites.map((item) => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}  
          <option  value="nova">Vytvoriť novú</option>               
        </select>
        {favoriteGroupValue=="nova" && <input
                  value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                  type="text"
                  placeholder="Názov skupiny..."
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />}
              </div>
        <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
      </div>
    </Dialog>
    <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })}
                 <button onClick={() => {setUserMaskFilter("ZoznamInt","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
      </div> 
 </>
    )

}

/* 
<table
          class="min-w-full border text-left text-sm font-light bg-white dark:border-neutral-500">
          <thead class="font-medium border-b bg-gray-400 dark:border-neutral-500">
            <tr>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Meno 
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Oddelenie
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                ID
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Email
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Dátum nástupu
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
                Dátum odchodu
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
              </th>
              <th
                scope="col"
                class=" px-6 py-4 dark:border-neutral-500">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b dark:border-neutral-500">
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                Mark
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                Otto
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                123456
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                mark@otto.com
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                01.01.2023
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
                </a>
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                </svg>
                </a>
              </td>
            </tr>
            <tr class="border-b dark:border-neutral-500">
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                Jacob
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 dark:border-neutral-500">
                Thornton
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                123456
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                jacob@thorton.com
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                01.01.2023
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 dark:border-neutral-500">
                05.04.2023
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
                </a>
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                </svg>
                </a>
              </td>
            </tr>
            <tr class="border-b dark:border-neutral-500">
              <form action="Oddelenie.js" method="post"></form>
              
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                  <input
                            placeholder="Zadaj oddelenie"
                            type="text"
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                  <input
                            placeholder="Hodinová sadzba"
                            type="text"
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                
              </td>
              <td
                class="whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">
                  <input
                            placeholder="Číslo oddelenia"
                            type="text"
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                
              </td>
              <td><a class="whitespace-nowrap px-6 py-4 text-blue-500 hover:text-blue-700" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M12 18v-6M9 15h6"/>
                </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
*/