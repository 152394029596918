import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest,postFileRequest,deleteRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon,DeleteIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Document, Page, pdfjs} from 'react-pdf'
import { DateTime } from "luxon";
import "../../assets/css/style.css";
import UtilContext from '../../components/UtilContext';
import AuthContext from '../../components/AuthContext';
import { Toast } from 'primereact/toast';


// right after your imports
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


export default function DocumentationDetail({measure,name,type}) {

    const {user} = useContext(AuthContext);
    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible} = useContext(UtilContext);
    const [users, setUsers] = useState([]);
    const submitRef = useRef(null);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [formDocumentation,setFormDocumentation] = useState(measure);
    const [dbDocumentation,setDbDocumentation] = useState(null);
    const [recordProcessorDepartment, setRecordProcessorDepartment] = useState();
    const [recordProcessors, setRecordProcessors] = useState([]);
    const [documentationRecordType, setDocumentationRecordType] = useState(type);
    const [images,setImages] = useState('');
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [viewedImage,setViewedImage] = useState('');


    const [viewURL,setViewURL] = useState(null);
    const [zoom,setZoom] = useState(10);
    const [rotate,setRotate] = useState(0);

    const [fileList, setFileList] = useState([]);
    const fileListRef = useRef(null);


    const [closeTab,setCloseTab] = useState(false);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const ownToast = useRef(null);
    
      function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
      }

      function loadFileFromURL(url,type){
        setDialogText("Načítavam");
        setVisibleLoading(true);
        let formData = new FormData();
        formData.append('path',url);
        postRequest('/api/measure/getFile',formData,true)
        .then((response) => {
          var binaryString = atob(response.data);
          var bytes = new Uint8Array(binaryString.length);
          for (var i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
          }
          const file = new Blob(
            [bytes.buffer], 
            {type: 'application/pdf'});
      //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          setVisibleLoading(false);
          if(type=='open')window.open(fileURL);    
          if(type=='view')setViewURL(fileURL);
        }).catch((reason) => {
          alert("Nepodarilo sa načítať údaje!");
          setVisibleLoading(false);
        })
      }

      function getImages(id){
        getRequest(`/api/measures/images/${id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

     function loadDocumentation(){
        setDialogText("Načítavam");
        setVisibleLoading(true);
        let formData = new FormData();
        formData.append("order_number",formDocumentation.order_number);
        let url = "";
        if(name=="DOKUMENTÁCIA"){
            url = '/api/measure/getDocumentation';
        }
        else if(name=="SEBAKONTROLA"){
            url = '/api/measure/getSelfControl';
        }
        else if(name=="MERACÍ PROTOKOL"){
            url = '/api/measure/getMeasurementProtocol';
        }
        else if(name=="NDT PROTOKOL"){
            url = '/api/measure/getNDTProtocol';
        }
        else if(name=="ŽÍHACÍ PROTOKOL"){
            url = '/api/measure/getAnnealingProtocol';
        }
        postRequest(url, formData,true)
        .then((response) => {
            setDbDocumentation(response.data);
            formik.resetForm();
            setInsertCopyIcon(true);
            setDeleteIcon(true);
            if(response.data.length<1){
                dispatch({type:'close_tab',id:state.selectedTab});
            }
            setVisibleLoading(false);
        }).catch((reason) => {
            alert("Nepodarilo sa načítať údaje!");
            setVisibleLoading(false);
        });
     }

    useEffect(() => {
        loadDocumentation();
    },[formDocumentation]);

    function getIndex(calibration_companies_id) {
        return state.calibration_companies.findIndex(obj => obj.id === calibration_companies_id);
    }

    async function scrollToFirst(){
        /*let my_calibration_company = state.calibration_companies[0];
        setformCalibrationCompany(my_calibration_company);*/
    }
    
    async function scrollUp(){
       /* let id = getIndex(formik.values.id);
        if(id-1>=0){
            setformCalibrationCompany(state.calibration_companies[id-1]);
        }*/
    }
    
    async function scrollDown(){
       /* let id = getIndex(formik.values.id);
        if(id+1<state.calibration_companies.length){
            setformCalibrationCompany(state.calibration_companies[id+1]);
        }*/
    }
    
    async function scrollToLast(){
        /*let last = state.calibration_companies.length-1;
        let my_calibration_company = state.calibration_companies[last];
        setformCalibrationCompany(my_calibration_company);*/
    }

    /*const handleFileChange = (event) => {
        setFileList(event.target.files);
    };*/

    const handleFileChange = (event) => {
      setFileList(event.target.files);
    };

    function filterRecordProcessors(departmentId){
      setRecordProcessorDepartment(departmentId);
      setRecordProcessors(state.employees
      .filter(employee => employee.department.id === parseInt(departmentId)));
  }

    const validate = values => {
      const errors = {};
      return errors;
    };

    const formik = useFormik({
      initialValues: {
          article_number: '',order_number:'',contractor_id:'',type:name,
          total_amount:'',measure_complexity_id:'',
      },
      enableReinitialize:true,
      validate,
      onSubmit: values => {
          let formData = new FormData();
          for (let value in values) {
              formData.append(value, values[value]);
          }
          for(let i = 0;i<fileList.length;i++){
              formData.append('files[]',fileList[i]);
          }
        setDialogText("Ukladám");
        setVisible(true);
        postRequest('/api/measures', formData,true)
        .then((response) => {
            filterRecordProcessors();
            formik.setValues(response.data);
            setDocumentationRecordType("edit");
            if(fileList.length){
                getImages(response.data.id);
                setFileList([]);
                fileListRef.current.value = null;
            }
            let url = "";
            if(name=="DOKUMENTÁCIA"){
                url = "/api/measure/documentation";
            }
            else if(name=="SEBAKONTROLA"){
                url = "/api/measure/selfControl";
            }
            else if(name=="MERACÍ PROTOKOL"){
                url = "/api/measure/measurementProtocol";
            }
            else if(name=="NDT PROTOKOL"){
                url = "/api/measure/NDTProtocol";
            }
            else if(name=="ŽÍHACÍ PROTOKOL"){
                url = "/api/measure/annealingProtocol";
            }
            setDialogText("Načítavam");
            getRequest(url).then((response) => {
                if(name=="DOKUMENTÁCIA"){
                    dispatch({type:'load_documentation',newDocumentation:response.data});
                }
                else if(name=="SEBAKONTROLA"){
                    dispatch({type:'load_self_control',newSelfControl: response.data});
                }
                else if(name=="MERACÍ PROTOKOL"){
                    dispatch({type:'load_measurement_protocol',newMeasurementProtocol: response.data});
                }            
                else if(name=="NDT PROTOKOL"){
                    dispatch({type:'load_ndt_protocol',newNDTProtocol: response.data});
                }
                else if(name=="ŽÍHACÍ PROTOKOL"){
                    dispatch({type:'load_annealing_protocol',newAnnealingProtocol: response.data});
                }
                setVisible(false);
                if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
            }).catch((reason) => {
                setVisible(false);
                alert("Daný úkon sa nepodaril!");
                if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
            });
        }).catch((reason) => {
            setVisible(false);
            alert("Daný úkon sa nepodaril!");
        });
      },
  });

  const accept = (id) => {
    getRequest(`/api/measures/deleteImage/${id}`)
    .then((response) => {
        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
        getImages(formik.values.id);
    }).catch((reason) => {
        alert(reason);
        if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
        else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
    });
  };

  const reject = () => {
    if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
  }

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(img.id)
        });
    }

    function addNewDocRecord(){
        dispatch({type:'create_new_tab',name:'Pridať dokumentáciu',page:'DocumentationAdd',props:{measure: null,name:name,type:'add',not_last_used: true},mask:'otk009'})
    }

    function copyDocRecord(){
        dispatch({type:'create_new_tab',name:'Pridať dokumentáciu',page:'DocumentationAdd',props:{measure: formik.values,name:name,type:'add',not_last_used: true},mask:'otk009'})
    }

    const acceptRecord = () => {
        deleteRequest(`/api/measures/${formik.values.id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
            else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
            let url = "";
            if(name=="DOKUMENTÁCIA"){
                url = "/api/measure/documentation";
            }
            else if(name=="SEBAKONTROLA"){
                url = "/api/measure/selfControl";
            }
            else if(name=="MERACÍ PROTOKOL"){
                url = "/api/measure/measurementProtocol";
            }
            else if(name=="NDT PROTOKOL"){
                url = "/api/measure/NDTProtocol";
            }
            else if(name=="ŽÍHACÍ PROTOKOL"){
                url = "/api/measure/annealingProtocol";
            }
            getRequest(url).then((response) => {
                if(name=="DOKUMENTÁCIA"){
                    dispatch({type:'load_documentation',newDocumentation:response.data});
                }
                else if(name=="SEBAKONTROLA"){
                    dispatch({type:'load_self_control',newSelfControl: response.data});
                }
                else if(name=="MERACÍ PROTOKOL"){
                    dispatch({type:'load_measurement_protocol',newMeasurementProtocol: response.data});                        }
                else if(name=="NDT PROTOKOL"){
                    dispatch({type:'load_ndt_protocol',newNDTProtocol: response.data});
                }
                else if(name=="ŽÍHACÍ PROTOKOL"){
                    dispatch({type:'load_annealing_protocol',newAnnealingProtocol: response.data});
                }
            });
            loadDocumentation();
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
            else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
        });
      }
    
    const rejectRecord = () => {
        if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }
    
      const confirm = () => {
          confirmDialog({
              message: 'Prajete si vymazať tento záznam?',
              header: 'Potvrdenie vymazania',
              icon: 'pi pi-info-circle',
              rejectClassName: 'p-button-danger',
              acceptLabel: 'Nie',
              rejectLabel: 'Áno',
              defaultFocus: 'accept',
              reject: acceptRecord,
              accept: rejectRecord
          });
      };

      function handleSelectedMeasure(measure){
        getImages(measure.id);
        filterRecordProcessors(measure.contractor?.department_id);
        formik.setValues(measure);
        setInsertCopyIcon(true);
        setDeleteIcon(true);
        if(user.permissions.includes("edit otk-section2"))setInsertCopyIcon(false);
        if(user.permissions.includes("edit otk-section2"))setDeleteIcon(false);
      }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewDocRecord()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyDocRecord()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <div className='overflow-y-auto min-h-[calc(100vh-177px)] bg-gray-100'>
        <div className="relative flex flex-col">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   {name}
                </h1>
                      <div>
                        <label
                            htmlFor="order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zákazka
                        </label>
                        <input
                            name='order_number'
                            type="text" readOnly
                            value={formDocumentation.order_number}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <hr
                          style={{
                          background: "#27272A",
                          height: "2px",
                          border: "none",
                          }}
                          className='my-8'
                        />
                        {dbDocumentation?.map((item,index) => {
                          return <><div className="md:grid grid-cols-3 gap-2 mb-2">
                          <div>
                            <label
                                htmlFor="order_number"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Zamestnanec
                            </label>
                            <input
                                value={item.contractor?.name} readOnly
                                className="bg-whiteblock w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                            </div>
                            <div>
                            <label
                                htmlFor="customer_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Dátum
                            </label>
                            <input readOnly
                                value={DateTime.fromISO(item.created_at).toFormat("dd.MM.yyyy HH:mm")}
                                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                            </div>
                            <div>
                            <label
                                htmlFor="order_number"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Záznam:
                            </label>
                            <button type="button" onClick={() => handleSelectedMeasure(dbDocumentation[index])} className='py-3'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#009BCE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg></button>
                            </div>  
                        </div>
                        <hr
                        style={{
                        background: "#27272A",
                        height: "2px",
                        border: "none",
                        }}
                        className='my-8'
                      /></>
                        
                        })}     
                        <div className="md:grid grid-cols-2 gap-4 mb-2">
                        </div>
            </div>
          <div className={`w-auto p-6 m-4 bg-white rounded-md shadow-lg`}>
          <form onSubmit={formik.handleSubmit}>
                <h1 className="text-2xl font-semibold text-left text-zinc-900">
                   {name}
                </h1>
                {documentationRecordType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <div className="mt-6">
                    <div>
                      <div>
                        <label
                            htmlFor="article_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Artikel
                        </label>
                        <input
                            name='article_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.article_number}
                            className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zákazka
                        </label>
                        <input
                            name='order_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.order_number}
                            className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                    </div>
                    <div>
                    <div className='grid grid-cols-2 gap-1'>
                      <div>
                        <label
                            htmlFor="recordProcessorDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie zadávateľa
                        </label>
                        <select 
                        name='recordProcessorDepartment'
                        onChange={(e) => filterRecordProcessors(e.target.value)} value={recordProcessorDepartment}
                        className={`bg-whiteblock w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="contractor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zadávateľa
                        </label>
                        <select 
                        name="contractor_id"
                        onChange={formik.handleChange} value={formik.values.contractor_id}
                        className={`bg-whiteblock w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber zamestnanca</option>
                          {recordProcessors.map((recordProcessor) => (
                            <option key={recordProcessor.id} value={recordProcessor.id}>{recordProcessor.name}</option>
                            ))}
                        </select>
                        </div>
                      </div>
                    </div>
                {name=="DOKUMENTÁCIA" && <>
                <div className='grid grid-cols-2 gap-1'>
                    <div className='w-full mb-2'>
                    <label htmlFor='total_amount' className='font-semibold'>
                        Počet kusov:
                        <input 
                        onChange={formik.handleChange} value={formik.values.total_amount}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='number'
                        name='total_amount'></input>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='measure_complexity_id' className='font-semibold'>
                        Náročnosť dielu:
                        </label>
                        <select required
                        onChange={formik.handleChange} value={formik.values.measure_complexity_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        name="measure_complexity_id">
                            <option value="">Vyber náročnosť</option>
                            {state.measure_complexities.map((complexity) => (
                            <option key={complexity.id} value={complexity.id}>{complexity.category + " - " + complexity.description}</option>
                            ))}
                        </select>
                </div>
                </div>
                </>}
                <div>
                    <label
                        htmlFor="files"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Súbory:
                    </label>
                    <input
                    name='files' 
                    ref={fileListRef}
                    onChange={handleFileChange}
                    className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    type='file'
                    multiple="multiple"
                    id='file'/>
                </div>
                <div>
                        <label
                            htmlFor="images"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Prílohy:
                        </label>
                        {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                            <tr>
                                <td className='text-black h-10'><div className='flex flex-row p-2'>
                                    <div onClick={() => setVisibleFiles(true)}>
                                        <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                        </div>
                                    </div></td>
                            </tr>
                        </table>))}
                        </div>
                        
                </div>  
                <input
                    name='type'
                    type="hidden"
                    value={formik.values.type}
                />
                <button type="submit" ref={submitRef} className='hidden'></button>
                </form>
            </div>                   
            </div>
            </div> 
            <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button  className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
        <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
            </div>
            </div>
            </> 
            );
} 
