import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon,DeleteIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function GaugeAdd({gauge, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const [trusteeDepartment, setTrusteeDepartment] = useState();
    const [trustees, setTrustees] = useState([]);
    const [userDepartment, setUserDepartment] = useState();
    const [users, setUsers] = useState([]);

    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [templateList,setTemplateList] = useState([]);
    const templateListRef = useRef(null);

    const [formGauge,setFormGauge] = useState(gauge);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add calibration"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit calibration"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [favoriteVisible,setFavoriteVisible] = useState(false);
    const [favoriteGroupValue,setFavoriteGroupValue] = useState("");
    const [favoriteValue,setFavoriteValue] = useState("");

    const [showGaugeAdd, setShowGaugeAdd] = useState(true);
    const [showIntervalCalibration, setShowIntervalCalibration] = useState(false);

    const [valueX,setValueX] = useState(gauge==null ? "X" : gauge.daily_deployment.toString());
    const [valueY,setValueY] = useState(gauge==null ? "Y" : gauge.abrasion.toString());
    const [valueU,setValueU] = useState(gauge==null ? "U" : gauge.environment_of_use.toString());
    const [valueV,setValueV] = useState(gauge==null ? "V" : gauge.sensitivity.toString());
    const [valueK,setValueK] = useState(gauge==null ? "K" : gauge.calibration_factor.toString());

    const [images,setImages] = useState('');
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [viewedImage,setViewedImage] = useState('');

    useEffect(() => {
        if(!isNaN(valueX) && !isNaN(valueY) && !isNaN(valueU) && !isNaN(valueV)){
            let result = valueX*valueY + valueU*valueV;
            setValueK(result);
        }
    },[valueX,valueY,valueU,valueV]);

    useEffect(() => {
        // Update the document title using the browser API
        if(formGauge!=null){
            if(formGauge.trustee_id){
                const trustee_id = state.employees.filter(employee => employee.id === formGauge.trustee_id);
                if(trustee_id.length > 0)filterTrustees(trustee_id[0].department.id);
            }
            if(formGauge.user_id){
                const user_id = state.employees.filter(employee => employee.id === formGauge.user_id);
                if(user_id.length > 0)filterUsers(user_id[0].department.id);
            }
            getImages();
        }
    },[formGauge]);

    function getImages(id=null){
        getRequest(`/api/gauges/protocols/${id ? id : formGauge.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    function showForm(){
        if(valueK!=="K"){
            if(valueK == 2)formik.setFieldValue('max_calibration_interval',"60");
            else if(valueK >= 3 && valueK <= 4)formik.setFieldValue('max_calibration_interval',"48");
            else if(valueK >= 5 && valueK <= 7)formik.setFieldValue('max_calibration_interval',"24");
            else if(valueK >= 8 && valueK <= 10)formik.setFieldValue('max_calibration_interval',"12");
            else if(valueK >= 11 && valueK <= 15)formik.setFieldValue('max_calibration_interval',"6");
            else if(valueK >= 16 && valueK <= 18)formik.setFieldValue('max_calibration_interval',"3");
            setShowGaugeAdd(true);
            setShowIntervalCalibration(false);
        }
        else{
            alert('Vypočítaj rovnicu!');
        }
    }

    function showTable(){
        setShowGaugeAdd(false);
        setShowIntervalCalibration(true);
    }

    function addNewGauge(){
        dispatch({type:'create_new_tab',name:'Nové meradlo',page:'GaugeAdd',props:{gauge: null,type:'add'},mask:'kal003'})
    }

    function copyGauge(){
        dispatch({type:'create_new_tab',name:'Nové meradlo',page:'GaugeAdd',props:{gauge: formik.values,type:'copy'},mask:'kal003'})
    }

    function filterTrustees(departmentId){
        setTrusteeDepartment(departmentId);
        setTrustees(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    function filterUsers(departmentId){
        setUserDepartment(departmentId);
        setUsers(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formGauge==null?{
            gauge_name: '',inventory_number: '',gauge_status:'',max_calibration_interval:'',serial_number:'',purchase_date:'',location:'',state:'',manufacturer:'',gauge_type:'',gauge_category:'',calibration_company_id:'',gauge_description:'',trustee_id:'',user_id:'',
        }:formGauge,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]!=null ? values[value] : "");
            }
            formData.append("daily_deployment", valueX);
            formData.append("abrasion", valueY);
            formData.append("environment_of_use", valueU);
            formData.append("sensitivity", valueV);
            formData.append("calibration_factor", valueK);
            formData.append("contractor_id", user?.employee?.id ? user?.employee?.id : "" );
            for(let i = 0;i<templateList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',templateList[i]);
            }
            if(formType=="add"){
                postRequest('/api/gauges', formData,true)
                .then((response_first) => {
                    console.log(response_first);
                    getRequest("/api/gauges").then((response) => {
                        dispatch({type:'load_gauges',newGauges:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        if(templateList.length){
                            getImages(response_first.data.id);
                            setTemplateList([]);
                            templateListRef.current.value = null;
                        }
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/gauges/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/gauges").then((response) => {
                        dispatch({type:'load_gauges',newGauges:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(gauges_id) {
        return state.gauges.findIndex(obj => obj.id === gauges_id);
    }

    async function scrollToFirst(){
        let my_gauge = state.gauges[0];
        setFormGauge(my_gauge);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormGauge(state.gauges[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.gauges.length){
            setFormGauge(state.gauges[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.gauges.length-1;
        let my_gauge = state.gauges[last];
        setFormGauge(my_gauge);
    }

    function confirmFavoriteDialog(){
        if(favoriteGroupValue==""){
          alert("Vyberte skupinu!");
          return;
        }
        else if(favoriteGroupValue=="nova"){
          if(favoriteValue==""){
            alert("Napíšte názov novej skupiny!");
            return;
          }
          else{
            //VYTVORIT SKUPINU AJ FAVORITA
            setFavoriteVisible(false);
            setVisibleLoading(true);
            let formData = new FormData();
            formData.append("name", favoriteValue);
            postRequest(`/api/favorite_groups`, formData,true)
            .then((response) => {
              let formData2 = new FormData();
              formData2.append("name", "Nové meradlo");
              formData2.append("page", "GaugeAdd");
              formData2.append("favorite_group_id", response.data.id);
              postRequest(`/api/favorites`, formData2,true)
              .then((response) => {
                  getRequest("/api/my_favorite_groups").then((response) => {
                    dispatch({type:'load_favorites',newFavorites:response.data});
                    setVisibleLoading(false);
                });
              }).catch((reason) => {
                  setVisibleLoading(false);
              })
            }).catch((reason) => {
                setVisibleLoading(false);
            })
          }
        }
        else{
          //VYTVORIT FAVORITA a pridat do skupiny
          setFavoriteVisible(false);
          setVisibleLoading(true);
          let formData2 = new FormData();
          formData2.append("name", "Nové meradlo");
          formData2.append("page", "GaugeAdd");
          formData2.append("favorite_group_id", favoriteGroupValue);
          postRequest(`/api/favorites`, formData2,true)
          .then((response) => {
              getRequest("/api/my_favorite_groups").then((response) => {
                dispatch({type:'load_favorites',newFavorites:response.data});
                setVisibleLoading(false);
            });
          }).catch((reason) => {
              setVisibleLoading(false);
          })
        }
    }

    function onlyOne(checkbox) {
        var checkboxes = document.getElementsByName('check')
        checkboxes.forEach((item) => {
            if (item !== checkbox) item.checked = false
        })
    }

    const handleTemplateChange = (event) => {
        setTemplateList(event.target.files);
    };

    const accept = (id) => {
        getRequest(`/api/gauges/deleteProtocol/${id}`)
        .then((response) => {
              if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
              else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
              getImages();
          }).catch((reason) => {
              alert(reason);
              if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
              else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
          })
      };

      const reject = () => {
          if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
          else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }
  

  function deleteFiles(img){
      confirmDialog({
          message: 'Prajete si vymazať tento súbor?',
          header: 'Potvrdenie vymazania',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Nie',
          rejectLabel: 'Áno',
          accept:reject,
          reject:() => accept(img.id)
      });
  }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewGauge()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyGauge()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => setFavoriteVisible(true)}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        { showGaugeAdd &&
        
        <div className="relative flex flex-col min-h-[calc(100vh-177px)] overflow-x">
          <div className='xl:grid grid-cols-3 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   EVIDENČNÁ KARTA MERADIEL
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            htmlFor="gauge_name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV MERADLA
                        </label>
                        <input
                            name='gauge_name' required
                            type="text"
                            onChange={formik.handleChange} value={formik.values.gauge_name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="inventory_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            INVENTÁRNE ČÍSLO
                        </label>
                        <input
                            name='inventory_number' readOnly
                            type="text"
                            onChange={formik.handleChange} value={formik.values.inventory_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="gauge_status"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ŠTATÚT MERADLA
                        </label>
                        <select 
                        name='gauge_status'  required
                        onChange={formik.handleChange} value={formik.values.gauge_status}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber štatút</option>
                          <option value="Kalibrované">Pracovné kalibrované meradlo</option>
                          <option value="Informatívne">Informatívne meradlo</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="max_calibration_interval"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            MAX. KALIBRAČNÝ INTERVAL
                        </label>
                        <button onClick={() => showTable()} type='submit' className='w-fit font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>Vyrátaj!</button>
                        <select  required
                        name='max_calibration_interval'
                        onChange={formik.handleChange} value={formik.values.max_calibration_interval}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="0">0</option>
                          <option value="3">3 mesiace</option>
                          <option value="6">6 mesiacov</option>
                          <option value="12">12 mesiacov</option>
                          <option value="24">24 mesiacov</option>
                          <option value="48">48 mesiacov</option>
                          <option value="60">60 mesiacov</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="serial_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            VÝROBNÉ ČÍSLO
                        </label>
                        <input
                            name='serial_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.serial_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="purchase_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                             DÁTUM NÁKUPU
                        </label>
                        <input  required
                            name='purchase_date'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.purchase_date}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>         
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                    <div>
                        <label
                            htmlFor="location"
                            className="block text-md font-semibold text-gray-800"
                        >
                            LOKALITA
                        </label>
                        <select 
                        name='location'
                        onChange={formik.handleChange} value={formik.values.location}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber lokalitu</option>
                          <option value="Na pracovisku">Na pracovisku</option>
                          <option value="Na kalibrácií"> Na kalibrácií</option>
                          <option value="Na oprave">Na oprave</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="state"
                            className="block text-md font-semibold text-gray-800"
                        >
                            STAV
                        </label>
                        <select 
                        name='state'  required
                        onChange={formik.handleChange} value={formik.values.state}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber stav</option>
                          <option value="Aktívne">Aktívne</option>
                          <option value="Vyradené">Vyradené</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="manufacturer"
                            className="block text-md font-semibold text-gray-800"
                        >
                             VÝROBCA MERADLA
                        </label>
                        <input
                            name='manufacturer'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.manufacturer}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TYP MERADLA
                        </label>
                        <select  required
                        name='gauge_type'
                        onChange={formik.handleChange} value={formik.values.gauge_type}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber typ</option>
                          <option value="Digitálne">Digitálne</option>
                          <option value="Analógové"> Analógové</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_category"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KATEGÓRIA MERADLA
                        </label>
                        <select  required
                        name='gauge_category'
                        onChange={formik.handleChange} value={formik.values.gauge_category}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber kategóriu</option>
                          <option value="PMU-Pracovné meradlo">PMU-Pracovné meradlo</option>
                          <option value="SM-Skúšovné meradlo"> SM-Skúšovné meradlo</option>
                          <option value="ZM-Zálohové meradlo"> ZM-Zálohové meradlo</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="calibration_company_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KALIBRAČNÁ FIRMA
                        </label>
                        <select 
                        name="calibration_company_id" onChange={formik.handleChange} value={formik.values.calibration_company_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber kal. firmu</option>
                          {state.calibration_companies.map((calibration_company) => (
                            <option key={calibration_company.id} value={calibration_company.id}>{calibration_company.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="gauge_description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POPIS MERADLA
                        </label>
                        <textarea
                        name='gauge_description'
                        onChange={formik.handleChange} value={formik.values.gauge_description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
            <div>
                        <label
                            htmlFor="trustee_department"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ODDELENIE SPRÁVCU
                        </label>
                        <select 
                        name="trustee_department" onChange={(e) => filterTrustees(e.target.value)} value={trusteeDepartment}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="trustee_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            MENO SPRÁVCU
                        </label>
                        <select 
                        name="trustee_id" onChange={formik.handleChange} value={formik.values.trustee_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber zamestnanca</option>
                          {trustees.map((trustee) => (
                            <option key={trustee.id} value={trustee.id}>{trustee.name}</option>
                            ))}
                        </select>
                        <div>
                        <label
                            htmlFor="trustee_department"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ODDELENIE UŽÍVATEĽA
                        </label>
                        <select 
                        name="user_department" onChange={(e) => filterUsers(e.target.value)} value={userDepartment}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="user_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            UŽÍVATEĽ MENO
                        </label>
                        <select 
                        name="user_id" onChange={formik.handleChange} value={formik.values.user_id}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber zamestnanca</option>
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="template"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PREDLOHA
                        </label>
                        <input
                            name='files' 
                            ref={templateListRef}
                            onChange={handleTemplateChange}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            type='file'
                            multiple="multiple"
                            id='template'/>
                        </div>
                        <div>
                        <label
                            htmlFor="images"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Galéria:
                        </label>
                        {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                            <tr>
                                <td className='text-black h-10'><div className='flex flex-row p-2'>
                                    <div onClick={() => setVisibleFiles(true)}>
                                        <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                        </div>
                                    </div></td>
                            </tr>
                        </table>))}
                        </div>
                        <button type="submit" ref={submitRef} className='hidden'></button>
                        </div>         
            </div>
            </div>
            </div>}
             { showIntervalCalibration && <div className='flex flex-col justify-center overflow-x'>
                <div className='w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md'>
                <h1 className='text-2xl font-semibold text-zinc-900 text-center px-1 py-1 m-auto mb-2'>
                    Odporúčaný výpočet kalibračného intervalu
                </h1>
                <h1 className='text-3xl font-semibold text-zinc-900 text-center px-1 py-1 m-auto mb-2'>
                    K = {valueX} * {valueY} + {valueU} * {valueV} {valueK!=="K" && ` = ${valueK}`}
                </h1>
                <div className='flex flex-col w-full justify-center items-center'>
                 <table className='w-full h-4 mb-2'>
                    <tbody className=' border-2 border-gray-400 rounded-md'>
                    <tr className='border-2 border-gray-400 px-1 bg-white font-semibold'>
                            <td className=' border-black'>Denné nasadenie</td>
                            <td className=' border-black'>X</td>
                            <td className=' border-black'>Opotrebovanie</td>
                            <td className=' border-black'>Y</td>
                            <td className=' border-black'>Prostredie využitia</td>
                            <td className=' border-black'>U</td>
                            <td className=' border-black'>Citlivosť</td>
                            <td className=' border-black'>V</td>
                        </tr>
                        <tr className='border-2 border-gray-400 bg-gray-200'>
                            <td className=' border-black'>1 krát</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="1" 
                                                checked={valueX === "1"} 
                                                onChange={(e) => setValueX(e.target.value)} />
                                    1
                                </label>
                            </td>
                            <td className=' border-black'>Minimálné</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="1" 
                                                checked={valueY === "1"} 
                                                onChange={(e) => setValueY(e.target.value)} />
                                    1
                                </label>
                            </td>
                            <td className=' border-black'>Vnútorné použitie</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="1" 
                                                checked={valueU === "1"} 
                                                onChange={(e) => setValueU(e.target.value)} />
                                    1
                                </label>
                            </td>
                            <td className=' border-black'>Robustná</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="1" 
                                                checked={valueV === "1"} 
                                                onChange={(e) => setValueV(e.target.value)} />
                                    1
                                </label>
                            </td>
                        </tr>
                        <tr className='border-2 border-gray-400 bg-white'>
                            <td className=' border-black'>1 až 20 krát</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="2" 
                                                checked={valueX === "2"} 
                                                onChange={(e) => setValueX(e.target.value)} />
                                    2
                                </label>
                            </td>
                            <td className=' border-black'>Normálne</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="2" 
                                                checked={valueY === "2"} 
                                                onChange={(e) => setValueY(e.target.value)} />
                                    2
                                </label>
                            </td>
                            <td className=' border-black'>Výroba</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="2" 
                                                checked={valueU === "2"} 
                                                onChange={(e) => setValueU(e.target.value)} />
                                    2
                                </label>
                            </td>
                            <td className=' border-black'>Normálna</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="2" 
                                                checked={valueV === "2"} 
                                                onChange={(e) => setValueV(e.target.value)} />
                                    2
                                </label>
                            </td>
                        </tr>
                        <tr className='border-2 border-gray-400 bg-gray-200'>
                            <td className=' border-black'>Viac ako 20 krát</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="3" 
                                                checked={valueX === "3"} 
                                                onChange={(e) => setValueX(e.target.value)} />
                                    3
                                </label>
                            </td>
                            <td className=' border-black'>Vysoké</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="3" 
                                                checked={valueY === "3"} 
                                                onChange={(e) => setValueY(e.target.value)} />
                                    3
                                </label>
                            </td>
                            <td className=' border-black'>Vonkajšie prostredie</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="3" 
                                                checked={valueU === "3"} 
                                                onChange={(e) => setValueU(e.target.value)} />
                                    3
                                </label>
                            </td>
                            <td className=' border-black'>Vysoká</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="3" 
                                                checked={valueV === "3"} 
                                                onChange={(e) => setValueV(e.target.value)} />
                                    3
                                </label>
                            </td>
                        </tr>
                    </tbody>
                 </table>
                 
                 <table className='w-full h-4 text-center'>
                    <tbody className=' border-2 border-gray-400 rounded-md'>
                        <tr className='border-2 border-gray-400 px-1 bg-white font-semibold'>
                            <td className=' border-black'>Kalibračný faktor K</td>
                            <td className=' border-black'>Interval kalibrácie (mesiace)</td>
                            <td className=' border-black'>Kalibračný faktor K</td>
                            <td className=' border-black'>Interval kalibrácie (mesiace)</td>
                        </tr>
                        <tr className='border-2 border-gray-400 bg-gray-200'>
                            <td className=' border-black'>2</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="60" 
                                                checked={valueK == 2} readOnly />
                                    60
                                </label>
                            </td>
                            <td className=' border-black'>8-10</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="12" 
                                                checked={valueK >= 8 && valueK <=10} readOnly />
                                    12
                                </label>
                            </td>
                        </tr>
                        <tr className='border-2 border-gray-400 bg-white'>
                            <td className=' border-black'>3-4</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="48" 
                                                checked={valueK >= 3 && valueK <=4} readOnly />
                                    48
                                </label>
                            </td>
                            <td className=' border-black'>11-15</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="6" 
                                                checked={valueK >= 11 && valueK <=15} readOnly />
                                    6
                                </label>
                            </td>
                        </tr>
                        <tr className='border-2 border-gray-400 bg-gray-200'>
                            <td className=' border-black'>5-7</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="24" 
                                                checked={valueK >= 5 && valueK <=7} readOnly />
                                    24
                                </label>
                            </td>
                            <td className=' border-black'>16-18</td>
                            <td className=' border-black'>
                                <label>
                                    <input type="radio" value="3" 
                                                checked={valueK >= 16 && valueK <=18} readOnly />
                                    3
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={() => showForm()} className='w-fit font-medium mb-8 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>Uložiť!</button>
                 </div>
                </div>
            </div> 
             }
             </form>            <Dialog header="Pridať do skupiny:" visible={favoriteVisible} style={{ width: '40vw' }} onHide={() => setFavoriteVisible(false)}>
        <div className="flex flex-columns">
          <div className="flex-row">
          <select 
          value={favoriteGroupValue} onChange={e => setFavoriteGroupValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option  value="">Vyberte možnosť</option> 
            {state.favorites.map((item) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}  
            <option  value="nova">Vytvoriť novú</option>               
          </select>
          {favoriteGroupValue=="nova" && <input
                    value={favoriteValue} onChange={e => setFavoriteValue(e.target.value)}
                    type="text"
                    placeholder="Názov skupiny..."
                    className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />}
                </div>
          <button onClick={() => confirmFavoriteDialog()} className='w-22 h-10 ml-8 mt-2 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
        </Dialog>
        <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
            </div>
            </> 
            );
} 
