import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, VerticalLineIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../../svg/svgIcons";
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';

export default function ErrorTypeAdd({error_type, type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,setVisible,setDialogText}= useContext(UtilContext);

    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);

    const [formErrorType,setFormErrorType] = useState(error_type);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState(!user.permissions.includes("edit otk-section2"));
    const [visibleLoading,setVisibleLoading] = useState(false);

    const [visibleDuplicate, setVisibleDuplicate] = useState(false);

    function addNewErrorType(){
      dispatch({type:'create_new_tab',name:'Nový druh chyby',page:'ErrorTypeAdd',props:{error_type: null,type:'add'},mask:'otk017'})
    }
    function copyErrorType(){
      dispatch({type:'create_new_tab',name:'Nový druh chyby',page:'ErrorTypeAdd',props:{error_type: null, type:'add'},mask:'otk017'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formErrorType==null?{
            name: '',
        }:formErrorType,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            setDialogText("Ukladám...");
            setVisible(true);
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            if(formType==="add"){
                postRequest('/api/error_types', formData,true)
                .then((response) => {
                    getRequest("/api/error_types").then((response) => {
                        dispatch({type:'load_error_types',newErrorTypes:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
            else{
                postRequest(`/api/error_types/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/error_types").then((response) => {
                        dispatch({type:'load_error_types',newErrorTypes:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
      },
    });

    return (
        <>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
      <IntersectionObserverWrapper>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewErrorType()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyErrorType()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button onClick={() => openFavoriteDialog("Nový druh chyby","ErrorTypeAdd")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("ErrorTypeAdd",null,null)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        </IntersectionObserverWrapper>
      </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   Druh chyby
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                        <div>
                        <label
                            for="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov chyby
                        </label>
                        <input
                            name='name'
                            type="text"
                            required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <button type="submit" ref={submitRef} className='hidden'></button>          
            </div>
            </div>
            </div>
            </div>
            </form>
            </>
            );
} 
